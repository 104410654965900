import React from "react";
import {Button, Table} from "react-bootstrap";

import {MembershipDomain} from "../../services/ApiDomain";

import './MembershipTable.scss';

interface EmptyMembershipStateRow {
    text: string
}

interface Props {
    title: string,
    buttonText: string
    onClickAddMembership: () => void,
    navigateToMembership: (id?: string) => void,
    memberships: MembershipDomain[]
    loading: boolean,
    navigateToPurchasePass?: (id?: string) => void,
    emptyState?: EmptyMembershipStateRow
}

const MembershipsTable = ({
                              title,
                              buttonText,
                              onClickAddMembership,
                              navigateToMembership,
                              memberships,
                              loading,
                              navigateToPurchasePass,
                              emptyState
                          }: Props) => {

    const shouldDisplayPurchaseState = (membership: MembershipDomain) => {
        return membership && membership.purchaseState && membership.purchaseState !== 'PAID'
    }

    const paymentStatus: (membership: MembershipDomain) => string = (membership: MembershipDomain) => {
        if (membership.purchaseState === 'PROCESSING') {
            return 'Payment processing.';
        } else if (membership.purchaseState === 'FAILED') {
            return 'Payment failed.';
        }

        return 'Awaiting payment.';
    }

    return (
        <div className={"membershipTable"}>
            <Table borderless>
                <thead className={"membershipTable_headingContainer"}>
                <td className={"membershipTable_colOne"}>
                    <h3 className={"membershipTable_heading"}>{title}</h3>
                </td>
                <td className="membershipTable_colTwo" />
                <td className={"membershipTable_actions"}>
                    {/* You can't add yourself as a caddie master so makes no sense to have this button */}
                    {/*<Button onClick={onClickAddMembership} className="membershipTable_actions_addBtn">*/}
                    {/*    <AddCircleRounded className="membershipTable_actions_addBtn_icon" />*/}
                    {/*    {buttonText}*/}
                    {/*</Button>*/}
                </td>
                </thead>
                <tbody className={"membershipTable_bodyContainer"}>
                    {!loading && memberships && memberships.map(membership => {
                        return <tr>
                            <td className="membershipTable_colOne">{membership.name}</td>
                            {(shouldDisplayPurchaseState(membership)) &&
                            <td className="membershipTable_colTwo">
                                <span>{paymentStatus(membership)}</span>
                            </td>
                            }
                            {(!shouldDisplayPurchaseState(membership)) && <td className="membershipTable_colTwo" />}
                            {(membership.type === 'TOUR' && membership.status === 'AWAITING_PAYMENT') &&
                                <td>
                                    <Button
                                        variant="info"
                                        disabled={membership.purchaseState === 'PROCESSING'}
                                        onClick={() => {
                                        if (navigateToPurchasePass) {
                                            navigateToPurchasePass(membership.typeId)
                                        }
                                    }}>PURCHASE</Button>
                                </td>
                            }
                            {membership.status !== 'AWAITING_PAYMENT' &&
                                <td className={`membershipTable_status_${membership.status.toLowerCase()}`}>
                                    {membership.status.replace("_", " ")}
                                </td>
                            }
                        </tr>
                    })}
                    {(!loading && (memberships === undefined || memberships.length === 0)) &&
                        <tr className="membershipTable_emptyStateRow">
                            <td className={"membershipTable_colOne "}>{emptyState?.text}</td>
                            <td />
                            <td />
                        </tr>
                    }
                    {loading && <tr>
                        <td className={"membershipTable_colOne"}>Loading...</td>
                        <td />
                        <td />
                    </tr>}
                </tbody>
            </Table>
        </div>
    )

}

export default MembershipsTable;