import React, {CSSProperties, useEffect, useState} from 'react';
import {Image} from "react-bootstrap";
import {useStore} from "../services/state/useStore";
import {useApi} from "../services/useApi";

interface Props {
    url?: string
    user?: string | 'current'
    size: 'xs' | 's' | 'smd' | 'md' | 'lg'
    style?: CSSProperties
}

const PLACEHOLDER_AVATAR = '/placeholder.png'

const Avatar = ({url, user, size, style}: Props) => {

    const { userAvatar } = useStore();
    const { getAvatar } = useApi();

    const [avatar, setAvatar] = useState<string>(PLACEHOLDER_AVATAR)

    useEffect(() => {
        if (url) {
            setAvatar(url)
            return
        }

        if (user === 'current') {
            if (userAvatar) {
                setAvatar(userAvatar)
            }
        } else {
            if (user) {
                loadUserAvatar(user)
            } else {
                setAvatar(PLACEHOLDER_AVATAR)
            }
        }
    }, [url, user, userAvatar])

    const loadUserAvatar = async (userId: string) => {
        setAvatar('/placeholder.png')
        let a = await getAvatar(userId)
        if (a) {
            setAvatar(a)
        }
    }

    let s = '1em'

    switch (size) {
        case "xs":
            s = '2em'
            break
        case "s":
            s = '5em'
            break
        case "smd":
            s = '7em'
            break
        case "md":
            s = '10em'
            break
        case "lg":
            s = '15em'
            break
    }

    let finalStyle = {
        width: s,
        height: s,
        ...style
    }

    return (
        <Image
            className="announcementBanner_avatar"
            src={avatar}
            style={finalStyle}
            draggable={false}
            roundedCircle
        />
    )
}

export default Avatar;

