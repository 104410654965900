import React from 'react';
import {tertiaryBlue} from "../../../../services/Utils";

interface Props {
    onClick?: (slots: number) => void
    count?: number
}

const slotBlue = tertiaryBlue;

const SlotCountIndicator = ({onClick, count}: Props) => {

    const slotClick = async (e: React.MouseEvent<HTMLDivElement>, slots: number) => {
        e.stopPropagation();
        if (onClick) {
            onClick(slots)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div
                onClick={(e) => slotClick(e, 1)}
                style={{
                    width: '1.25em',
                    height: '1.25em',
                    borderRadius: '100%',
                    marginRight: '0.5em',
                    borderColor: slotBlue,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    backgroundColor: count && count >= 1 ? slotBlue : '',
                }}/>
            <div
                onClick={(e) => slotClick(e, 2)}
                style={{
                    width: '1.25em',
                    height: '1.25em',
                    borderRadius: '100%',
                    marginRight: '0.5em',
                    borderColor: slotBlue,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    backgroundColor: count && count >= 2 ? slotBlue : '',
                }}/>
            <div
                onClick={(e) => slotClick(e, 3)}
                style={{
                    width: '1.25em',
                    height: '1.25em',
                    borderRadius: '100%',
                    marginRight: '0.5em',
                    borderColor: slotBlue,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    backgroundColor: count && count >= 3 ? slotBlue : '',
                }}/>
            <div
                onClick={(e) => slotClick(e, 4)}
                style={{
                    width: '1.25em',
                    height: '1.25em',
                    borderRadius: '100%',
                    borderColor: slotBlue,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    backgroundColor: count && count >= 4 ? slotBlue : '',
                }}/>
        </div>
    )
}

export default SlotCountIndicator;