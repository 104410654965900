import React from "react";
import {FieldAttributes, useField} from "formik";

interface TextFieldProps extends FieldAttributes<any> {
    label?: string;
    className?: string;
    placeholder?: string;
}

export const TextField: React.FC<TextFieldProps> = ({label, className, placeholder, ...props}) => {
    const [field, meta] = useField(props.name);

    return (
        <>
            <input
                {...field}
                {...props}
                placeholder={label}
                className={`form-control ${className} ${meta.touched && meta.error ? "is-invalid" : ""}`}
            />
            {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
        </>
    );
};
