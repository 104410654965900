import './UpcomingBookings.scss';

import React, {useEffect} from "react";
import {Button} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useApi} from "../../../services/useApi";
import {buildUrl, SCHEDULER} from "../../../services/PageUtils";
import {darkerHandicaddieGreen} from "../../../services/Utils";
import {ClubBookingSlot, Course, TeeTimeResource, TimesheetResource} from "../../../services/ApiDomain";
import {useSelector} from "react-redux";
import {RootState} from "../../../services/store/store";

const columnHelper = createColumnHelper<TeeTimeResource>()

const defaultColumns = [
    columnHelper.accessor('teeTime', {
        header: () => <span>TIME</span>,
    }),
    columnHelper.accessor(row => row?.booking?.title || "TBD", {
        id: 'reservationName',
        header: () => <span>RESERVATION NAME</span>,
    }),
    columnHelper.accessor(row => row?.booking?.bookingSlots.length, {
        id: 'numberOfCaddies',
        header: () => <span>NUMBER OF CADDIES</span>,
    }),
    columnHelper.accessor(row => row?.booking?.bookingSlots?.filter(bs => bs.caddieId), {
        id: 'caddies',
        header: () => <span>CADDIES</span>,
        size: 350
    }),
    columnHelper.accessor(row => row?.booking?.notes, {
        id: 'notes',
        header: () => <span>NOTES</span>,
    }),
]

interface Props {
}

export const UpcomingBookings = ({}: Props) => {

    const [columns, setColumns] = React.useState<any>(defaultColumns);
    const [timesheetResource, setTimesheetResource] = React.useState<TimesheetResource>();
    const [courseIdToCourse, setCourseIdToCourse] = React.useState<Map<string, Course>>();

    const navigate = useNavigate();
    const {getTimesheet} = useApi();
    const {club, courses} = useSelector((state: RootState) => state.clubs.selectedClub);
    const rerender = React.useReducer(() => ({}), {})[1]

    useEffect(() => {
        if (!club) {
            return;
        }

        loadBookings(club.id);
    }, [club]);

    const loadBookings = async (clubId: string | undefined) => {
        if (!clubId) {
            return;
        }

        const timesheetResource = await getTimesheet(clubId, undefined, true);
        if (courses.length > 0 && columns?.length === 5) {
            const courseColumn = columnHelper.accessor(row => row.booking?.courseId, {
                id: 'course',
                header: () => <span>COURSE</span>,
            })
            let existingColumns = [...columns];
            existingColumns.splice(1, 0, courseColumn);
            setColumns(existingColumns);

            let courseIdToCourse = new Map<string, Course>();
            courses.forEach(course => courseIdToCourse.set(course.id, course));
            setCourseIdToCourse(courseIdToCourse);
        } else if (courses?.length <= 0 && columns?.length === 6) {
            let existingColumns = [...columns];
            existingColumns.splice(1, 1);
            setColumns(existingColumns);
            setCourseIdToCourse(new Map<string, Course>());
        }
        setTimesheetResource(timesheetResource);
        rerender();
    }

    const table = useReactTable({
        data: timesheetResource?.teeTimes || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    const getCaddieClassName = (status: string) => {
        switch (status) {
            case "PENDING":
                return "pending-booking";
            case "ACCEPTED":
                return "accepted-booking";
            case "REJECTED":
                return "rejected-booking";
            case "DRAFT":
                return "draft-booking";
            default:
                return '';
        }
    };

    const getCaddies = (caddies: ClubBookingSlot[]) => {
        return (
            caddies?.map(caddie => (
                caddie.noSmartphone ?
                    <div className={`render-caddie-name no-smartphone`}>{caddie?.displayName}</div> :
                    <div
                        className={`render-caddie-name ${getCaddieClassName(caddie?.status)}`}>{caddie?.displayName}</div>
            ))
        );
    }

    const getColumnData = (columnId: any, cell: any) => {
        switch (columnId) {
            case 'course':
                return (<span>{courseIdToCourse?.get(cell.getValue() as string)?.name}</span>);
            case 'caddies':
                return (
                    <div style={{display: 'flex', flexFlow: 'wrap'}}>
                        {getCaddies(cell.getValue() as ClubBookingSlot[])}
                    </div>);
            default:
                return (flexRender(cell.column?.columnDef?.cell, cell.getContext()));
        }
    };

    return (
        <div>
            <div className="upcoming-booking-title">
                <div>Today's Upcoming Bookings</div>
                <div>
                    <Button style={{backgroundColor: darkerHandicaddieGreen, borderRadius: 5}}
                            onClick={() => {
                                navigate(buildUrl(SCHEDULER))
                            }}>
                        VIEW FULL SCHEDULE
                    </Button>
                </div>
            </div>
            <div className="booking-table">
                <table className='bookings-table-content'>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} style={{width: cell.column.getSize()}}>
                                    {getColumnData(cell?.column?.id, cell)}
                                </td>
                            ))}
                        </tr>
                    ))}
                    {table.getRowModel().rows.length === 0 && <tr>
                        <td colSpan={timesheetResource && courses?.length > 0 ? 6 : 5} className='bg-white pb-xl-5'>
                            <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                                <p className='text-center'>You currently have no bookings for today.</p>
                                <img alt="no-time-sheet" src={'/no-time-sheet.png'}
                                     className="d-block mx-auto img-fluid w-25"/>
                            </div>
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}