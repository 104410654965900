import React from "react";
import {Col, Image, Row} from "react-bootstrap";

import './WelcomeAnnoucement.scss';

interface Props {

}

const WelcomeAnnouncement = ({}: Props) => {

    return (
        <>
            <p className="announcementBanner_text">The Handicaddie team have been working hard on bringing you this
                platform.</p>
            <p className="announcementBanner_text">If there's anything we can do to improve your experience, please feel
                free to contact us on our twitter account @handicaddie or email us at help@handicaddie.com</p>
            <Row className="welcomeBanner_ceoRow">
                <Image
                    className="welcomeBanner_avatar"
                    src={`/graham.png`}
                    roundedCircle
                />
                <Col>
                    <p className="welcomeBanner_ceoRow_name">Graham Curry</p>
                    <p className="welcomeBanner_ceoRow_position"> -- Founder & CEO</p>
                </Col>
            </Row>
        </>
    )

}

export default WelcomeAnnouncement;