import React, {useEffect, useState} from 'react';
import {Button, Card, Carousel, Form, Row, Spinner} from "react-bootstrap";
import PlanCard from "./card/PlanCard";

import './TourAvailablePasses.scss';
import {TourPass, TourPassService} from "../../../../../services/tourpass/TourPassService";
import {PurchaseService} from "../../../../../services/purchase/PurchaseService";

interface Props {
    tourId: string
}

const TourAvailablePasses = ({tourId}: Props) => {

    const { getTourPasses } = TourPassService();
    const { createCheckoutSession } = PurchaseService();

    const [index, setIndex] = useState<number>(0);

    const [passes, setPasses] = useState<TourPass[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [purchasingPassId, setPurchasingPassId] = useState<string>();

    useEffect(() => {
        loadPasses();
    }, []);

    const loadPasses = async () => {
        const tourPasses = await getTourPasses(tourId);
        if (tourPasses) {
            setPasses(tourPasses);
        }
    }

    const purchasePass = async (id?: string) => {
        if (!id) {
            return;
        }
        setLoading(true);
        setPurchasingPassId(id);
        const resp = await createCheckoutSession('PASS', id);
        if (resp) {
            window.location.href = resp.url;
        } else {
            setLoading(false);
            setPurchasingPassId('');
        }
    }

    return (
        <Carousel controls={false} indicators={false} activeIndex={index} >
            <Carousel.Item>
                {
                    !passes && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Spinner animation="border" />
                    </div>
                }
                {passes &&
                    <>
                        <h3 className="ladiesEuropeanTourPlans_sectionHeading"><span>Choose Your Pass</span></h3>
                        <Row style={{justifyContent: 'center'}}>
                            {passes && passes.map((pass) => {
                                return <PlanCard
                                    loading={loading}
                                    purchasing={loading && purchasingPassId === pass.id}
                                    tourPass={pass}
                                    purchase={purchasePass}
                                    paid={false}
                                />
                            })}
                            {passes.length === 0 && <p>No passes currently created, contact your tour admin.</p>}
                        </Row>
                    </>
                }
            </Carousel.Item>
            <Carousel.Item>
                <h3 className="ladiesEuropeanTourPlans_sectionHeading"><span>Payment Details</span></h3>
                <Card>
                    <Card.Body>
                        <h3 className="pageSubHeading">Card Details</h3>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type="number" placeholder="Card number..." />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Expiry</Form.Label>
                                <Form.Control type="month" placeholder="Expiry" />
                            </Form.Group>

                            <Form.Group controlId="formBasicCode">
                                <Form.Label>Code</Form.Label>
                                <Form.Control type="number" placeholder="Code" />
                            </Form.Group>
                        </Form>
                        <Button onClick={() => setIndex(2)}>Pay Now</Button>
                    </Card.Body>
                </Card>
            </Carousel.Item>
        </Carousel>
    )
}

export default TourAvailablePasses;