import React, {useEffect, useState} from 'react';
import {Col, FormControl, Row, Table} from "react-bootstrap";

import './CaddieTable.scss';
import {TourCaddie} from "../../../../services/ApiDomain";
import CaddieTableRow from "./CaddieTableRow";
import {USER_PROFILE} from "../../../../services/PageUtils";
import {useNavigate} from "react-router-dom";

type Props = {
    caddies?: TourCaddie[]
};

export const CaddieTable = ({caddies}: Props) => {

    type Row = {
        name: string,
        userId: string
        currentMember: boolean
    }

    const [rows, setRows] = useState<Row[]>();
    const [searchValue, setSearchValue] = useState<string>();
    const navigation = useNavigate();

    useEffect(() => {
        buildRows();
    }, [caddies, searchValue]);

    const buildRows = async () => {
        const matchesSearchValue = (caddie: TourCaddie) => {
            return searchValue && (caddie.displayName.toLowerCase().includes(searchValue.toLowerCase())
                || (searchValue.toLowerCase() === 'paid' && caddie.currentMember)
                || (searchValue.toLowerCase() === 'not paid' && !caddie.currentMember));
        }

        if (!caddies || caddies.length === 0) {
            return;
        }

        let list: Row[] = [];

        for (const caddie of caddies) {
            if (!searchValue || matchesSearchValue(caddie)) {
                list.push({
                    name: caddie.displayName,
                    userId: caddie.userId,
                    currentMember: caddie.currentMember,
                })
            }
        }

        setRows(list);
    }

    const navigateToProfile = (userId: string) => {
        navigation(USER_PROFILE.replace(':userId', userId))
    }

    return (
        <>
            <Row className="caddieTable_header">
                <Col md={{span: 4, offset: 8}}>
                    <FormControl type="text"
                                 placeholder="Search"
                                 onChange={(e) => setSearchValue(e.target.value)}/>
                </Col>
            </Row>
            <Table className="caddieTable_table">
                <thead className="caddieTable_headingContainer">
                <td className="caddieTable_colOne">
                    <h3 className="caddieTable_heading">Name</h3>
                </td>
                <td>
                    <h3 className="caddieTable_heading">Current Member?</h3>
                </td>
                </thead>
                <tbody className="caddieTable_bodyContainer">
                {!caddies &&
                    <tr>
                        <td className="caddieTable_colOne">Loading...</td>
                        <td/>
                    </tr>
                }
                {caddies && rows && rows.map(row => {
                    return <CaddieTableRow
                        name={row.name}
                        userId={row.userId}
                        currentMember={row.currentMember}
                        onClick={() => navigateToProfile(row.userId)}
                    />
                })}
                {caddies && caddies.length === 0 && <tr>
                    <td className="caddieTable_colOne">
                        No approved caddies.
                    </td>
                    <td />
                </tr>}
                {caddies && rows && rows.length === 0 && <tr>
                    <td className="caddieTable_colOne">
                        No matching caddies.
                    </td>
                    <td />
                </tr>}
                </tbody>
            </Table>
            {/*<div className="caddieTable_footer">*/}
            {/*    <Row className="justify-content-md-center caddieTable_footer_pageBtnContainer">*/}
            {/*        <Button disabled variant={"outline-primary"} className="caddieTable_footer_pageBtn">{'<'}</Button>*/}
            {/*        <Button className="caddieTable_footer_pageBtn">1</Button>*/}
            {/*        /!*<Button variant={"outline-primary"} className="caddieTable_footer_pageBtn">2</Button>*!/*/}
            {/*        /!*<Button variant={"outline-primary"} className="caddieTable_footer_pageBtn">3</Button>*!/*/}
            {/*        /!*<Button variant={"outline-primary"} className="caddieTable_footer_pageBtn">4</Button>*!/*/}
            {/*        <Button disabled variant={"outline-primary"} className="caddieTable_footer_pageBtn">{'>'}</Button>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </>
    )
}
