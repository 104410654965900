import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../../services/state/useStore";
import {logError, logErrorFromApi} from "../../../services/Utils";
import {toast} from "react-toastify";
import {useApi} from "../../../services/useApi";
import {Club} from "../../../services/ApiDomain";

interface Props {
    show: boolean
    closeModal: () => void
    onAddSuccess: () => void
}

export const AddGolfClubMembershipModal = ({show, closeModal, onAddSuccess}: Props) => {

    const [clubs, setClubs] = useState<Club[]>([]);

    const navigate = useNavigate();
    const { currentUser } = useStore();
    const { getListOfClubs, createCaddie } = useApi();

    const submitForm = async (values: any) => {
        if (!currentUser) {
            logError('Error loading your profile.', {});
            return;
        }

        createCaddie(currentUser.id, values.club)
            .then(() => {
                toast.success('Successfully requested to join golf club.');
                onAddSuccess();
            })
            .catch((e) => logErrorFromApi(e));
        closeModal();
    };

    const goToRegisterClub = async () => {
        navigate("/club-register");
    };

    useEffect(() => {
        async function getClubListing() {
            const clubsResponse = await getListOfClubs();
            setClubs(clubsResponse.filter(club => club.name !== 'Handicaddie Golf Club'));
        }

        if (!show) {
            return;
        }
        getClubListing();
    }, [show]);

    return (
        <Modal show={show} onHide={closeModal}>
            <Formik
                onSubmit={submitForm}
                initialValues={{
                    club: clubs[0]?.id,
                }}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form
                        style={{
                            marginTop: "10px",
                            padding: "2rem",
                        }}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "2rem",
                            }}
                        >
                            <Form.Label
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Club Selection
                            </Form.Label>
                            <Form.Label
                                style={{
                                    fontSize: "1.2rem",
                                    fontWeight: 500,
                                }}
                            >
                                Select the golf club you want to
                                caddie for
                            </Form.Label>
                        </div>
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>
                                        Select a club
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="club"
                                        onBlur={handleBlur}
                                        value={values.club}
                                        onChange={handleChange}
                                    >
                                        {clubs.map((club: Club) => (
                                            <option value={club.id}>{club.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{color: '#4caf50', textDecoration: 'underline'}}
                                   onClick={() => goToRegisterClub()}>Don't see your club? Click here.</p>
                            </Col>
                        </Row>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <Button type="submit">Go</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}