import React, {useEffect, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {useApi} from "../../../services/useApi";
import {TourCaddieApplicationAnswer} from "../../../services/ApiDomain";

import './CaddieApplicationModal.scss';

type Props = {
    tourId?: string
    applicationId: string
    show: boolean
    closeModal: () => void
    applicatorName: string
}

export const CaddieApplicationModal = ({tourId, applicationId, show, closeModal, applicatorName}: Props) => {

    const { getTourCaddieApplicationAnswers } = useApi();

    const [loading, setLoading] = useState<boolean>();
    const [answers, setAnswers] = useState<TourCaddieApplicationAnswer[]>();

    useEffect(() => {
        if (tourId === undefined) {
            return;
        }

        async function loadAnswers(tourId: string, applicationId: string) {
            try {
                setLoading(true);
                const applicationAnswers = await getTourCaddieApplicationAnswers(tourId, applicationId);
                setAnswers(applicationAnswers);
            } finally {
                setLoading(false);
            }
        }

        loadAnswers(tourId, applicationId);
    }, [applicationId])

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{applicatorName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {loading && <Spinner animation="border"/>}
                {answers?.map(answer => {
                    if (answer.answer === 'false') {
                        answer.answer = 'No';
                    } else if (answer.answer === 'true') {
                        answer.answer = 'Yes';
                    }
                    return <>
                        <h5>{answer.question}</h5>
                        <p>{answer.answer}</p>
                    </>
                })}
            </Modal.Body>
        </Modal>
    );
}
