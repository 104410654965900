import React, {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";

import './AnnouncementBanner.scss';

import {useStore} from "../../services/state/useStore";
import Avatar from "../Avatar";

interface Props {
    children?: ReactElement
}

const AnnouncementBanner = ({children}: Props) => {

    const { showAnnouncementBanner, hideAnnouncementBanner, currentUser } = useStore();

    return (
        <>
            {showAnnouncementBanner &&
            <div className="announcementBanner">
                <Container className={"contentContainer"} fluid="lg">
                    <Row className="announcementBanner_userRow">
                        <Avatar size={'s'} user={'current'} style={{ marginRight: '1em' }} />
                        <Col>
                            <h2 className="announcementBanner_welcome">Welcome,</h2>
                            <h4 className="announcementBanner_username">{currentUser?.displayName}</h4>
                        </Col>
                        <Col className="announcementBanner_dismissBtn_container">
                            <div className="announcementBanner_dismissBtn" onClick={hideAnnouncementBanner}>
                                HIDE
                            </div>
                        </Col>
                    </Row>
                    {children}
                </Container>
            </div>}
        </>
    )

}

export default AnnouncementBanner;