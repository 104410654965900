import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {logErrorFromApi} from "../services/Utils";
import {Member, MemberService} from "../services/member/MemberService";
import MemberProfileHeader from "../parts/caddiemaster/members/profile/header/MemberProfileHeader";
import {Container} from "react-bootstrap";
import {MemberProfileContent} from "../parts/caddiemaster/members/profile/content/MemberProfileContent";

interface Props {
}

const MemberProfile = ({}: Props) => {

    const navigate = useNavigate();
    const {clubId, memberId} = useParams();

    const [member, setMember] = useState<Member>();
    const {getMember} = MemberService();

    useEffect(() => {
        if (!memberId || !clubId) {
            return;
        }

        loadMember(memberId)
            .catch((err) => {
                logErrorFromApi(err);
                navigate(-1);
            });
    }, [memberId]);

    const loadMember = async (id: string) => {
        let m = await getMember(id);
        if (m) {
            setMember(m);
        }
    }

    return (
        <>
            {member && <><MemberProfileHeader
                userId={member.user.id}
                displayName={member.user.displayName}/>
                <Container className={"contentContainer"}>
                    <MemberProfileContent member={member}/>
                </Container>
            </>}
            {!member && <p>Loading... </p>}
        </>
    )

}

export default MemberProfile;