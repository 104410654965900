import React from "react";
import {CourseCreateForm} from "./CourseCreateForm";
import {Course} from "../../../../../services/ApiDomain";
import {Modal} from "react-bootstrap";

type Props = {
    open: boolean,
    onClose: () => void,
    itemData?: any;
    onSelectCourse: (c: Course) => void
}

export const AddCourseModal = ({open, onClose, itemData, onSelectCourse}: Props) => {
    return (
        <Modal
            show={open}
            backdrop="static"
            keyboard={false}
            id="staticBackdrop"
            tabIndex="-1"
            centered
            size="sm"
            contentClassName="">
            <CourseCreateForm onClose={onClose} item={itemData} onSelectCourse={onSelectCourse} />
        </Modal>
    );
};


