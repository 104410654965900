import React, {useEffect, useState} from 'react';
import {Dropdown, FormControl} from "react-bootstrap";
import {AddRounded, ArrowDropDown, DeleteOutlined} from "@material-ui/icons";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../../services/Utils";
import {BookingSlotPlayer} from "../../../../services/ApiDomain";
import {Member, MemberService} from "../../../../services/player/MemberService";

interface Props {
    selectedPlayer?: BookingSlotPlayer
    updatePlayer: (player?: BookingSlotPlayer) => void
    allPlayersInBooking?: BookingSlotPlayer[]
}

const PlayerDropdown = ({updatePlayer, selectedPlayer, allPlayersInBooking}: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [members, setMembers] = useState<Member[]>([])

    const { club } = handicaddieSelector(state => state.clubs.selectedClub)

    const { findMembers } = MemberService();

    useEffect(() => {
        try {
            if (!searchTerm || searchTerm === "") {
                return;
            }

            performSearch()
        } catch (e) {
            logErrorFromApi(e)
        }
    }, [searchTerm]);

    const performSearch = async () => {
        if (!club) {
            toast.error("Can't detect the club.. please refresh and try again")
            return;
        }

        let m = await findMembers(club.id, searchTerm)
        if (m) {
            setMembers(m)
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="slot-type-dropdown">
                <div>
                    <span className="slot-type-title">{selectedPlayer?.name || "Player"}</span>
                    <ArrowDropDown style={{ verticalAlign: 'top' }} />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                />
                {searchTerm.length > 1 && <>
                    <Dropdown.Divider/>
                    <Dropdown.Item className="slot-type-menu-item" onClick={() => updatePlayer({
                        name: searchTerm,
                        type: 'PLAYER'
                    })}>
                        <AddRounded/> {searchTerm}
                    </Dropdown.Item>
                </>}
                {!selectedPlayer && allPlayersInBooking && allPlayersInBooking.length >= 1 && <>
                    <Dropdown.Divider/>
                    {allPlayersInBooking
                        .filter(player => player.type !== 'GUEST')
                        .map((player) => (
                            <Dropdown.Item className="slot-type-menu-item" eventKey={player.id}
                                           onClick={() => updatePlayer({
                                               ...player,
                                               type: 'GUEST'
                                           })}>
                                Guest of {player.name}
                            </Dropdown.Item>
                        ))}
                </>}
                {selectedPlayer && <>
                    <Dropdown.Divider/>
                    <Dropdown.Item eventKey={selectedPlayer.id}
                                   active
                                   className="slot-type-menu-item"
                                   onClick={() => updatePlayer()}>
                        <div style={{ display:"flex", justifyContent: "space-between" }}>
                            <span>{selectedPlayer.name}</span>
                            <DeleteOutlined />
                        </div>

                    </Dropdown.Item>
                </>}
                <Dropdown.Divider />
                {members
                    .filter(member => member.id !== selectedPlayer?.id)
                    .map((member) => (
                    <Dropdown.Item className="slot-type-menu-item" eventKey={member.id}
                                   onClick={() => updatePlayer({
                                       id: member.id,
                                       name: member.user.displayName,
                                       type: 'PLAYER'
                                   })}>
                        {member.user.displayName}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default PlayerDropdown;