import React from "react";
import {useStore} from "../services/state/useStore";
import {Tasks} from "../parts/dashboard/sections/Tasks";
import {Analytics} from "../parts/dashboard/sections/Analytics";
import './Dashboard.scss';
import {Col, Row} from "react-bootstrap";
import ClubSelectorDropdown from "../components/dropdown/clubselector/ClubSelectorDropdown";

interface Props {}

export const Dashboard = ({ }: Props) => {

  const { currentUser } = useStore();

  return (
      <div className="p-3">
        <Row>
          <Col xs={8} className="pt-3">
            <h3 className="welcomeHeading">Welcome Back{currentUser ? ", " + currentUser.firstName : ""}</h3>
            <p className="welcomeSubHeading">Track how your caddie programme is performing.</p>
          </Col>
          <Col xs={4} className="d-flex justify-content-end align-items-end pb-3">
            <ClubSelectorDropdown/>
          </Col>
        </Row>
        <Tasks/>
        <Analytics />
      </div>
  )
}