import React from 'react';
import MembersTable from "../parts/caddiemaster/members/table/MembersTable";

interface Props {

}

const Members = ({}: Props) => {
    return (
        <div style={{ padding: '1em' }}>
            <MembersTable />
        </div>
    )
}

export default Members;