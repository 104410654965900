import React from 'react';
import {Modal} from "react-bootstrap";
import CaddieSelectorModalContent from "./CaddieSelectorModalContent";
import {BookingSlot, ClubBooking} from "../../../../services/ApiDomain";

type CaddieSelectionModalProps = {
    clubId: string
    caddieId?: string
    bookingSlot?: BookingSlot
    date?: string
    booking?: ClubBooking
    showCaddieSelectionModal: boolean
    handleCaddieSelectionClose: () => void
}

const CaddieSelectionModal = ({clubId, caddieId, bookingSlot, date, booking, showCaddieSelectionModal, handleCaddieSelectionClose}: CaddieSelectionModalProps) => {

    return (
        <>
            <Modal show={showCaddieSelectionModal} onHide={handleCaddieSelectionClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Caddie Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CaddieSelectorModalContent
                        clubId={clubId}
                        id={caddieId}
                        date={date}
                        booking={booking}
                        bookingSlot={bookingSlot}
                        closeModal={handleCaddieSelectionClose} />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default CaddieSelectionModal;