import React, {useRef, useState} from 'react';
import {InfoOutlined} from "@material-ui/icons";
import {Col, Overlay, Tooltip} from "react-bootstrap";
import {StatisticsAnalyticsPayload} from "../../../services/ApiDomain";

interface Props {
    infoText: string
    payload?: StatisticsAnalyticsPayload
    className?: string
}

const Statistic = ({infoText, payload, className}: Props) => {
    const iconTarget = useRef(null);
    const [showInfo, setShowInfo] = useState(false);

    if (!payload) {
        return <></>;
    }

    const {name, value, delta, deltaType} = payload;

    return (
        <Col sm={12} md={4} className={`${className}`}>
            <div className="statistics-container">
                <div className="statistics">
                    <div className="statistics-heading-container">
                        <div className="statistics-heading">{name}</div>
                        <InfoOutlined
                            ref={iconTarget}
                            className="statistics-heading-icon statistics-info-icon"
                            onMouseEnter={() => setShowInfo(true)}
                            onMouseLeave={() => setShowInfo(false)}
                        />
                        <Overlay target={iconTarget.current} show={showInfo} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>{infoText}</Tooltip>}
                        </Overlay>
                    </div>

                    <div className="statistics-value">{value}</div>
                    {delta && deltaType &&
                        <div style={{ color: deltaType === "NEGATIVE" ? '#B72626' : '#1DB954' }}>{delta}</div>
                    }
                </div>
            </div>
        </Col>
    )
}

export default Statistic;