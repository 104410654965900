import React, {ReactElement, useEffect, useState} from "react";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {Edit, HelpRounded} from "@material-ui/icons";
import {Alert, Button, Col, Container, Form, Image, NavLink, Row, Spinner} from "react-bootstrap";
import background from "./background-1.jpg";
import {getApiError, isTokenInvalid, logError} from "../../services/Utils";
import {useStore} from "../../services/state/useStore";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";
import {ApiError} from "../../services/ApiDomain";

import "./Login.scss";
import {LOADING} from "../../services/PageUtils";
import appStore from "../../images/DownloadOnAppStore.svg";
import playStore from "../../images/DownloadOnGooglePlay.png";

interface Props {}

export default function Login({}: Props): ReactElement {
    const navigate = useNavigate();
    const { token, updateToken, clearLocalStorage } = useStore();
    const { resendConfirmation, loginUser } = useApi();

    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email().required("Email address is required"),
        password: yup.string().required("Password is required"),
    });

    useEffect(() => {
        if (isTokenInvalid(token)) {
            clearLocalStorage();
        } else {
            navigate(LOADING);
        }
        setLoading(false);
    }, []);

    const submitForm = async (values: any) => {
        const login = async () => {
            let session = await loginUser(values.email, values.password);
            let idToken = `Bearer ${session.idToken}`;
            updateToken(idToken);
            navigate(LOADING);
        }

        setLoading(true);
        login().catch((err) => {
            setLoading(false);
            let errorResponse: ApiError = getApiError(err);
            if (errorResponse?.code === 'E1000') {
                logError(errorResponse.message, err);
                return;
            } else if (errorResponse?.code === 'E1001') {
                resendConfirmation(values.email, values.password);
                navigate('/confirm', {
                    state: {
                        email: values.email,
                        password: values.password,
                    }
                });
                return;
            }
            logError('Error logging in.', err)
        });
    };

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "50%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >

                        <Row>
                            <Image
                                src={"./logo.svg"}
                                className="card_menu_img"
                                style={{ margin: "auto", height: "100px" }}
                            />
                        </Row>

                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Email">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email address"
                                                    value={values.email}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.email &&
                                                    errors.email && (
                                                        <Alert
                                                            variant={"danger"}
                                                        >
                                                            {errors.email}
                                                        </Alert>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Password">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.password &&
                                                    errors.password && (
                                                        <Alert
                                                            variant={"danger"}
                                                        >
                                                            {errors.password}
                                                        </Alert>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            {
                                                loading &&
                                                <Spinner animation={"border"}
                                                         variant="primary"
                                                         style={{marginRight: '1em'}} />
                                            }
                                            <Button type="submit" disabled={loading}>Login</Button>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <NavLink
                                                as={Link}
                                                to="/register"
                                                style={{
                                                    fontSize: 14,
                                                    color: "green",
                                                }}
                                            >
                                                <Edit
                                                    style={{
                                                        fontSize: 16,
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Register as Caddie Master
                                            </NavLink>
                                            <NavLink
                                                as={Link}
                                                to="/accountconfirmation"
                                                style={{
                                                    fontSize: 14,
                                                    color: "green",
                                                }}
                                            >
                                                <HelpRounded
                                                    style={{
                                                        fontSize: 16,
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Forgot your password?
                                            </NavLink>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <p style={{ textAlign: 'center' }}>Are you a caddie? Download the caddie app: </p>

                        <Row>
                            <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href="https://apps.apple.com/us/app/id1621131417">
                                    <Image src={appStore} style={{ height: 60 }}/>
                                </a>
                            </Col>
                            <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href="https://play.google.com/store/apps/details?id=com.handicaddie.caddie">
                                    <Image src={playStore} style={{ height: 60, cursor: 'pointer' }} />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}
