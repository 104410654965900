import React from "react";

import './UserProfileHeader.scss';
import {Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {buildUrl, EDIT_MY_PROFILE} from "../../../services/PageUtils";
import Avatar from "../../../components/Avatar";

interface Props {
    userId: string
    displayName: string | undefined
    isCurrentUser: boolean
}

const UserProfileHeader = ({userId, displayName, isCurrentUser}: Props) => {

    const navigate = useNavigate();

    const navigateToEdit = () => {
        navigate(buildUrl(EDIT_MY_PROFILE));
    }

    return (
        <div className="userProfileHeader_container">
            <Container className={"contentContainer"} fluid="lg">
                <Row>
                    <Col xs={12} lg={4}>
                        <Avatar
                            style={{
                                display: 'flex',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                marginBottom: '15px',
                            }}
                            user={isCurrentUser ? 'current' : userId}
                            size={'lg'}
                        />
                    </Col>
                    <Col xs={12} lg={8}>
                        <h2>{displayName}</h2>
                        {/* if current user, then they can edit */}
                        {isCurrentUser &&
                            <Button
                                className="userProfileHeader_editBtn"
                                variant="light"
                                onClick={() => navigateToEdit()}>
                                Edit profile
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default UserProfileHeader;