import React, {ReactElement} from "react";
import {Table} from "react-bootstrap";

import './ProfileInfoTable.scss';

interface Props {
    header: ReactElement
    body: ReactElement
}

const ProfileInfoTable = ({header, body}: Props) => {

    return (
        <Table>
            <thead className="profileInfoTable_headingContainer">
                {header}
            </thead>
            <tbody className="profileInfoTable_bodyContainer">
                {body}
            </tbody>
        </Table>
    )

}

export default ProfileInfoTable;