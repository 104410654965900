import React from 'react';
import Form from 'react-bootstrap/Form';

type Props = {
    value?: string,
    onTimeChange: (val: string) => void
}
export const TimeSelector = ({value, onTimeChange}: Props) => {

    return (
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <h6>Tee Time</h6>
                <Form.Control type="time" placeholder="00:00" value={value} onChange={(e) => {
                    onTimeChange(e.currentTarget.value)
                }} />
            </Form.Group>
        </Form>
    );
}

