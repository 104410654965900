import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Club, Course, SlotType} from "../../../ApiDomain"
import {
    loadClubs,
    loadMembersStatus,
    loadSelectedClub,
    loadSelectedClubCourses,
    loadSelectedClubSlotTypes, searchForMembers
} from "./asyncActions";
import {Member} from "../../../member/MemberService";


interface State {
    allClubs: Club[]
    selectedClub: SelectedClubState
}

interface SelectedClubState {
    club?: Club
    hasMultipleCourses: boolean
    courses: Course[]
    slotTypes: SlotType[]
    isMembersEnabled: boolean
    members: Member[]
}

const initialState: State = {
    allClubs: [],
    selectedClub: {
        hasMultipleCourses: false,
        courses: [],
        slotTypes: [],
        isMembersEnabled: false,
        members: [],
    },
};

export interface LoadClubsActionPayload {
    clubs?: Club[]
    selectedClub?: Club
}

export interface LoadSelectedClubActionPayload {
    courses: Course[]
    slotTypes: SlotType[]
    hasMultipleCourses: boolean
    isMembersEnabled: boolean
}

let clubSelectionSlice = createSlice(
    {
        name: "Clubs",
        initialState: initialState,
        reducers: {
            handleClubChange: (state, action: PayloadAction<string | undefined>) => {
                let clubId = action.payload

                if (!clubId) {
                    return state;
                }

                const selectedClub = state.allClubs
                    .find((item) => item.id === clubId);
                if (!selectedClub) {
                    return;
                }

                state.selectedClub = {
                    club: selectedClub,
                    courses: [],
                    hasMultipleCourses: false,
                    isMembersEnabled: false,
                    slotTypes: [],
                    members: [],
                }
            }
        },
        extraReducers: (builder) => {
            builder.addCase(
                loadClubs.fulfilled,
                (state, action: PayloadAction<LoadClubsActionPayload>) => {
                    if (action.payload.clubs) {
                        state.allClubs = action.payload.clubs
                    }
                    state.selectedClub.club = action.payload.selectedClub
                }
            )
            builder.addCase(
                loadSelectedClub.fulfilled,
                (state, action: PayloadAction<LoadSelectedClubActionPayload | undefined>) => {
                    if (!action.payload) {
                        return;
                    }

                    state.selectedClub.courses = action.payload.courses
                    state.selectedClub.hasMultipleCourses = action.payload.hasMultipleCourses
                    state.selectedClub.isMembersEnabled = action.payload.isMembersEnabled
                    state.selectedClub.slotTypes = action.payload.slotTypes
                }
            )
            builder.addCase(
                loadSelectedClubSlotTypes.fulfilled,
                (state, action: PayloadAction<SlotType[]|undefined>) => {
                    state.selectedClub.slotTypes = action.payload ? action.payload : []
                }
            )
            builder.addCase(
                loadSelectedClubCourses.fulfilled,
                (state, action: PayloadAction<Course[]|undefined>) => {
                    state.selectedClub.courses = action.payload ? action.payload : []
                }
            )
            builder.addCase(
                loadMembersStatus.fulfilled,
                (state, action: PayloadAction<boolean|undefined>) => {
                    state.selectedClub.isMembersEnabled = !!action.payload
                }
            )
            builder.addCase(
                searchForMembers.fulfilled,
                (state, action: PayloadAction<Member[]|undefined>) => {
                    state.selectedClub.members = action.payload ? action.payload : []
                }
            )
        }
    }
);

export const { handleClubChange } = clubSelectionSlice.actions;

export default clubSelectionSlice.reducer;