import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {TypedUseSelectorHook, useSelector} from "react-redux";

export const handicaddieAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()

export const handicaddieSelector: TypedUseSelectorHook<RootState> = useSelector
