import {ReactElement, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {Alert, Button, Col, Container, Form, Row, Spinner,} from "react-bootstrap";
import background from "./background-1.jpg";
import {useStore} from "../../services/state/useStore";
import {getApiError, logError} from "../../services/Utils";
import {toast} from "react-toastify";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";
import {ApiError} from "../../services/ApiDomain";

import "./Login.scss";

interface Props {}

export default function EmailConfirm({}: Props): ReactElement {
    const navigate = useNavigate();
    const location: any = useLocation();
    const { updateToken } = useStore();
    const { confirmEmail, loginUser } = useApi();

    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        code: yup.string().required("Code is required"),
    });

    useEffect(() => {
        setLoading(false);
    }, []);

    const submitForm = async (values: any) => {

        const confirm = async () => {
            await confirmEmail(
                location.state.email,
                location.state.password,
                values.code
            );
        }

        const login = async () => {
            const loginResponse: any = await loginUser(
                location.state.email,
                location.state.password,
            );

            updateToken(`Bearer ${loginResponse.idToken}`);
            navigate("/create", {
                state: {
                    email: location.state.email
                }
            });
        }

        setLoading(true);
        confirm()
            .then(() => {
                toast.success('Email confirmed.')
                login().catch((err) => {
                    let errorResponse: ApiError = getApiError(err);
                    logError(errorResponse.message, err);
                    navigate('/login')
                })
            })
            .catch((err) => {
                let errorResponse: ApiError = getApiError(err);

                if (errorResponse.code === 'E1000') {
                    toast.success('Email confirmed.')
                    logError(errorResponse.message, err);
                    navigate('/login')
                    return;
                }

                logError(errorResponse.message, err);
            })
            .finally(() => setLoading(false))
    };

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                    backgroundSize: "cover",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "30%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            initialValues={{
                                code: "",
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Form.Label
                                        style={{
                                            fontSize: "1.5rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Confirm Email
                                    </Form.Label>
                                    <Row>
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "2rem",
                                            }}
                                        >
                                            <Form.Label
                                                style={{ marginRight: "1rem" }}
                                            >
                                                Code:
                                            </Form.Label>
                                            <Form.Group controlId="detailsForm.Code">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="text"
                                                    name="code"
                                                    placeholder="xxxx"
                                                    value={values.code}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.code &&
                                                    errors.code && (
                                                        <Alert
                                                            variant={"danger"}
                                                        >
                                                            {errors.code}
                                                        </Alert>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            {
                                                loading &&
                                                <Spinner animation={"border"}
                                                         variant="primary"
                                                         style={{marginRight: '1em'}} />
                                            }
                                            <Button type="submit" disabled={loading}>Go</Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        </>
    );
}
