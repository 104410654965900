import React, {ReactElement} from "react";
import {Button, Card} from "react-bootstrap";

interface Props {
    onClick: () => void
    buttonText?: string
    pricePerSeason?: string
    image: string
    children: ReactElement
}

export const TourShowcaseCard = ({onClick, image, children, buttonText = "Apply Now", pricePerSeason}: Props) => {
    return <Card>
        <Card.Img src={image} />
        <Card.Body>
            { children }
        </Card.Body>
        <Card.Footer>
            <div style={{display: 'flex'}}>
                <div style={{marginLeft: 'auto'}}>
                    {/*{pricePerSeason && <span style={{marginRight: '0.5em'}}>from £{pricePerSeason} per season</span>}*/}
                    <Button variant="primary" onClick={onClick}>{buttonText}</Button>
                </div>
            </div>
        </Card.Footer>
    </Card>

}