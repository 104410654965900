import React, {useEffect} from 'react';
import {useStore} from "../../services/state/useStore";
import {Col, ListGroup} from "react-bootstrap";

type Props = {
    updateTourId: (tourId: string) => void
}

export const TourMasterSelector = ({updateTourId}: Props) => {

    const { tourMasters } = useStore();

    useEffect(() => {
        if (!tourMasters) {
            return;
        }
        if (tourMasters.length === 1) {
            updateTourId(tourMasters[0].tourId);
        }
    }, [tourMasters]);


    return (
        <Col>
            <p>Select the tour you want to manage:</p>
            <ListGroup>
                {tourMasters && tourMasters.map((tourMaster) => {
                    return <ListGroup.Item
                        onClick={() => updateTourId(tourMaster.tourId)}
                        style={{cursor: 'pointer'}}>
                        {tourMaster.tourName}
                    </ListGroup.Item>
                })}
                {!tourMasters && <h5>You don't manage any tours.</h5>}
            </ListGroup>
        </Col>
    )
}
