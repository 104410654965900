import React from 'react';

interface Props {
    title: string
    children: any
}

const Section = ({title, children}: Props) => {
    return (
        <section className="page_section">
            <h3 className="pageSubHeading" style={{ minHeight: 0 }}>{title}</h3>
            {children}
        </section>
    )
}

export default Section;