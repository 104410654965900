import React from 'react';

import {CloseButton, Col, Form, ProgressBar, Row} from "react-bootstrap";
import {Event} from "../../../../services/events/EventService";

import './PendingDocument.scss'

interface Props {
    file: File,
    name: string
    tourId: string
    uploading: boolean
    progress?: number
    updateFileName: (file: File, newName: string) => void
    updateFileScope: (file: File, newScope: string) => void
    removeFile: (file: File) => void
    events: Event[]
}

const PendingDocument = ({file, name, tourId, uploading, updateFileName, updateFileScope, removeFile, events, progress}: Props) => {

    return (
        <Row className="pendingDocument">
            <Col xs={12} md={6}>
                <Form.Control
                    type="text"
                    className="pendingDocument_fileName"
                    disabled={uploading}
                    defaultValue={name}
                    onChange={e => updateFileName(file, e.target.value)}
                />
            </Col>

            <Col xs={12} md={5}>
                <Form.Control
                    disabled={uploading}
                    as="select"
                    style={{width: '15em'}}
                    onChange={(e) => updateFileScope(file, e.target.value)}
                >
                    <option value={tourId}>Global</option>
                    <option disabled>-----------</option>
                    {events.map((event) => {
                        return <option key={event.id} value={event.id}>{event.name}</option>
                    })}
                </Form.Control>
            </Col>

            <Col xs={12} md={1} >
                <CloseButton
                    disabled={uploading}
                    style={{ lineHeight: '1.5em' }}
                    onClick={() => removeFile(file)}
                />
            </Col>

            {progress &&
                <Col xs={12} style={{ marginTop: '0.5em' }}>
                    <Col xs={{ span: 5, offset: 7 }} >
                        {progress < 100 && <>
                            Uploading...
                            <ProgressBar now={progress} />
                        </>}
                        {progress === 100 && <>
                            Uploaded successfully.
                        </>}
                    </Col>
                </Col>
            }
        </Row>
    )
}

export default PendingDocument;