import React from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import {useApi} from "../../../../services/useApi";

import './PendingTourCaddieApplicationCard.scss';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {USER_PROFILE} from "../../../../services/PageUtils";
import Avatar from "../../../../components/Avatar";

type Props = {
    name: string
    applicationId: string
    tourId: string
    userId: string
    contactConsent: boolean
    refreshCaddies: () => void
    showCaddieApplication: (id: string, name: string) => void
};

export const PendingTourCaddieApplicationCard = ({name, applicationId, tourId, userId, contactConsent, refreshCaddies, showCaddieApplication}: Props) => {

    const { approveTourApplication, disapproveTourApplication } = useApi();
    const navigation = useNavigate();

    const goToProfile = () => {
        if (!contactConsent) {
            toast.info('This caddie has asked not to be contacted.');
            return;
        }

        navigation(USER_PROFILE.replace(':userId', userId))
    }

    const approve = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        approveTourApplication(tourId, applicationId)
            .then(async (r) => {
                refreshCaddies();
                toast.success(`${name} approved.`);
            })
            .catch(async (r) => {
                toast.error(`Couldn't approve ${name}`);
            })
    }

    const deny = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        disapproveTourApplication(tourId, applicationId)
            .then(async (r) => {
                refreshCaddies();
                toast.success(`${name} denied.`);
            })
            .catch(async (r) => {
                toast.error(`Couldn't deny ${name}`);
            })
    }

    return (
        <Card className="tourCaddieApplicationCard">
            <Card.Body onClick={goToProfile}>
                <Row className="tourCaddieApplicationCard_userInfo">
                    <Col xs={12} md={"auto"}>
                        <Avatar user={userId} size='s' />
                    </Col>
                    <Col>
                        <h4 className="tourCaddieApplicationCard_userInfo_name">{name}</h4>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="outline-info"
                    onClick={() => showCaddieApplication(applicationId, name)}
                    className="tourCaddieApplicationCard_footer_reviewBtn">
                    Review Application
                </Button>
                <Row>
                    <Col xs={12} md={9}>
                        <Button
                            variant="primary"
                            onClick={e => approve(e)}
                            className="tourCaddieApplicationCard_footer_btn">
                            Approve
                        </Button>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button
                            variant="outline-danger"
                            onClick={e => deny(e)}
                            className="tourCaddieApplicationCard_footer_btn">
                            Deny
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}