import React, {useRef, useState} from 'react';
import {InfoOutlined} from "@material-ui/icons";
import {Image, Overlay, Tooltip} from "react-bootstrap";
import {AnalyticsResource, MostCaddiesRoundTableRow} from "../../../services/ApiDomain";
import Avatar from "../../../components/Avatar";
import crownIcon from "../../../images/crownIcon.svg";

interface Props {
    caddieRounds?: AnalyticsResource
}

const MostCaddieRoundsTable = ({caddieRounds}: Props) => {

    const iconTarget = useRef(null);
    const [showInfo, setShowInfo] = useState(false);

    const rows = caddieRounds?.payload?.items as MostCaddiesRoundTableRow[]

    const isTopCaddie = (index: number) => {
        let isTopCaddie = false;
        if (index === 0 || index === 1 || index === 2) {
            isTopCaddie = true;
        }
        return isTopCaddie;
    };

    const getTopCaddieClassName = (index: number) => {
        switch (index) {
            case 0:
                return 'first-caddie-position';
            case 1:
                return 'second-caddie-position';
            case 2:
                return 'third-caddie-position';
            default:
                return '';
        }
    };

    const renderCaddie = (caddie: any, index: number) => {
        return (
            <div className="caddie-details">
                <div>
                    <Avatar size={'xs'} user={caddie?.userId} style={{ marginRight: '1em' }} />
                    {isTopCaddie(index) ? <span style={{ position: 'relative' }}>
            <Image
                src={crownIcon}
                className={`crown-icon ${getTopCaddieClassName(index)}`}
            />
          </span> : null}
                </div>
                <div className="caddie-rank">
                    {index + 1}
                </div>
                <div style={{ marginLeft: 15, marginRight: 10 }}>
                    {`${caddie.firstName} ${caddie.lastName}`}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <span>{caddie.numberOfRounds}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="most-caddie-rounds-container">
            <div className="most-caddie-rounds-table">
                <div className="most-caddie-rounds-title">
                    <div>Most Caddie Rounds</div>
                    <InfoOutlined
                        ref={iconTarget}
                        className="statistics-info-icon"
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                    />
                    <Overlay target={iconTarget.current} show={showInfo} placement="top">
                        {(props) => <Tooltip id="overlay-example" {...props}>Your top performing caddies in the last 30 days.</Tooltip>}
                    </Overlay>
                </div>
                <div>
                    {rows && rows.length > 0 ?
                        rows.map((caddie: any, index: number) => (
                            renderCaddie(caddie, index)
                        )) :
                        <div style={{ padding: 10 }}>
                            There is no data to display
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default MostCaddieRoundsTable;