import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Profile} from "../parts/profile/Profile";
import {logErrorFromApi} from "../services/Utils";
import {useApi} from "../services/useApi";
import {User} from "../services/ApiDomain";

interface Props {
}

const UserProfile = ({ }: Props) => {

    const [user, setUser] = useState<User>();

    const navigate = useNavigate();
    const { userId } = useParams();
    const { getUser } = useApi();

    useEffect(() => {
        if (!userId) {
            return;
        }

        loadUser(userId)
            .catch((err) => {
                logErrorFromApi(err);
                navigate(-1);
            });
    }, [userId]);

    const loadUser = async (userId: string) => {
        let usr = await getUser(userId);

        setUser(usr);
    }

    return (
        <>
            {user && <Profile
                userId={user.id}
                isCurrentUser={false}
                displayName={user.displayName}
                email={user.email}
                phone={user.phoneNumber}
                bio={user.bio}
                isCaddieMasterForUser={true}
            />}
            {!user && <p>Loading... </p>}
        </>
    )

}

export default UserProfile;