import React, {useState} from "react";
import MembershipsTable from "../../components/tables/MembershipsTable";
import {AddGolfClubMembershipModal} from "./add-golf-club-modal/AddGolfClubMembershipModal";
import {MembershipDomain} from "../../services/ApiDomain";

interface Props {
    memberships: MembershipDomain[]
    loading: boolean
    refreshMemberships: () => void
}

const GolfClubMemberships = ({memberships, loading, refreshMemberships}: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const navigateToMembership = (id?: string) => {

    }

    return <>
        <MembershipsTable
            title={"Golf Clubs"}
            buttonText="Add"
            onClickAddMembership={() => setShowModal(true)}
            navigateToMembership={navigateToMembership}
            emptyState={{text: 'You\'ve not joined any golf clubs.'}}
            memberships={memberships}
            loading={loading}
        />
        <AddGolfClubMembershipModal
            show={showModal}
            closeModal={() => setShowModal(false)}
            onAddSuccess={() => refreshMemberships()}
        />
    </>
}

export default GolfClubMemberships;