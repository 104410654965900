import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    addBooking,
    calculateTeeTimesFromTimesheet,
    deleteBooking,
    loadAvailableCaddies,
    loadTimesheet, searchForCaddies, searchForPlayers
} from "./asyncActions";
import {dateWithoutTimezone} from "./service";
import {DeleteModalAction, Drawer, DrawerStatus} from "./domain";
import {getReducers} from "./reducers";
import {ClubBooking, Course, PagedApiResponse, TimesheetResource, UserAvailability} from "../../../ApiDomain";
import {Member} from "../../../player/MemberService";

export interface SelectedTeeTimeState {
    loadingBooking: boolean
    booking?: ClubBooking
    course?: Course
    isEditing: boolean
    isSqueezedOrNoTeeTimeBooking: boolean
    teeTime?: string
    teeTimesInPool?: number
}

export interface PlayerDropdownState {
    players: Member[]
    selectedPlayer?: Member
}

export interface SchedulerState {
    loading: boolean
    addBookingLoading: boolean
    caddieSearchLoading: boolean
    caddiesForDateLoading: boolean
    selectedDate: string
    activeDrawer: Drawer
    drawerStatus: DrawerStatus
    areNoTeeTimesAllowed: string[]
    isManualCourseAllowed: string[]
    isPlayersEnabled: string[]
    hideEmptyTeeTimes: boolean
    courseFilter?: Course
    selectedTeeTime?: SelectedTeeTimeState
    timesheet: TimesheetResource
    teeTimes: string[]
    hasDraftBookings: boolean
    allAvailableCaddies: UserAvailability[]
    caddies: UserAvailability[]
    showDeleteModal: boolean
    deletingBookingId?: string
    playerDropdown: PlayerDropdownState
}

const initialState: SchedulerState = {
    loading: false,
    addBookingLoading: false,
    caddieSearchLoading: false,
    caddiesForDateLoading: false,
    selectedDate: dateWithoutTimezone(),
    activeDrawer: Drawer.AVAILABLE_CADDIES,
    drawerStatus: DrawerStatus.OPEN,
    areNoTeeTimesAllowed: [],
    isManualCourseAllowed: [],
    isPlayersEnabled: [],
    hideEmptyTeeTimes: false,
    timesheet: {
        teeTimes: []
    },
    teeTimes: [],
    hasDraftBookings: false,
    allAvailableCaddies: [],
    caddies: [],
    showDeleteModal: false,
    playerDropdown: {
        players: []
    },
};

let schedulerSlice = createSlice(
    {
        name: "Scheduler",
        initialState: initialState,
        reducers: getReducers,
        extraReducers: builder => {
            builder
                .addCase(loadTimesheet.pending, (state) => {
                    state.loading = true
                })
            builder
                .addCase(loadTimesheet.fulfilled, (state, action: PayloadAction<TimesheetResource | undefined>) => {
                    state.timesheet = action.payload ? action.payload : { teeTimes: [] }
                })
            builder
                .addCase(loadAvailableCaddies.pending, (state) => {
                    state.caddiesForDateLoading = true
                })
            builder
                .addCase(loadAvailableCaddies.fulfilled, (state, action: PayloadAction<UserAvailability[]>) => {
                    state.caddiesForDateLoading = false
                    state.allAvailableCaddies = action.payload
                    state.caddies = action.payload
                })
            builder
                .addCase(calculateTeeTimesFromTimesheet.fulfilled, (state, action: PayloadAction<TimesheetCalculationsActionPayload>) => {
                    state.loading = false
                    state.teeTimes = action.payload.teeTimes
                    state.hasDraftBookings = action.payload.hasDraftBookings
                })
            builder
                .addCase(addBooking.pending, (state, action: PayloadAction) => {
                    state.addBookingLoading = true
                })
            builder
                .addCase(addBooking.rejected, (state) => {
                    state.addBookingLoading = false
                })
            builder
                .addCase(addBooking.fulfilled, (state, action: PayloadAction) => {
                    state.addBookingLoading = false
                })
            builder
                .addCase(deleteBooking.fulfilled, (state, action: PayloadAction<DeleteModalAction>) => {
                    state.showDeleteModal = action.payload.show
                    state.deletingBookingId = action.payload.bookingId
                })
            builder
                .addCase(searchForPlayers.fulfilled, (state, action: PayloadAction<Member[]>) => {
                    state.playerDropdown.players = action.payload
                })
            builder
                .addCase(searchForCaddies.pending, (state, action: PayloadAction) => {
                    state.caddieSearchLoading = true
                })
            builder
                .addCase(searchForCaddies.fulfilled, (state, action: PayloadAction<UserAvailability[] | undefined>) => {
                    state.caddieSearchLoading = false
                    if (action.payload) {
                        state.caddies = action.payload
                        return
                    }

                    state.caddies = state.allAvailableCaddies
                })
        }
    }
);

export const {
    handleDateChange,
    handleDrawerChange,
    handleToggleDrawer,
    handleOpenDrawer,
    handleAddClubToNoTeeTimes,
    handleAddClubToManualCourseAllowed,
    handleAddClubToPlayersEnabled,
    handleNewSqueezedOrNoTeeTimeBooking,
    handleToggleHideTeeTimes,
    handleCourseIdFilterUpdate,
    handleSelectedTeeTime,
    handleResetDrawer,
    handleAddBookingLoading,
    handleAddBookingFinished,
    handleShowDeleteBookingModal,
} = schedulerSlice.actions;

export default schedulerSlice.reducer;

export interface TimesheetCalculationsActionPayload {
    teeTimes: string[],
    hasDraftBookings: boolean
}