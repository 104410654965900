import React, {useEffect, useState} from 'react';
import {Button, Card, CardDeck, Col, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import moment from "moment";
import {logError} from "../../../../services/Utils";
import CaddieCard from "../../../../components/club/caddie_card/CaddieCard";
import {useApi} from "../../../../services/useApi";
import {BookingSlot, ClubBooking, ClubCaddie, UserAvailability} from "../../../../services/ApiDomain";
import CaddieBadge from "../components/CaddieBadge";

type CaddieSelectorModalProps = {
    clubId: string
    id?: string
    bookingSlot?: BookingSlot
    date?: string
    booking?: ClubBooking
    closeModal: () => void
}

const CaddieSelectorModalContent = ({clubId, id, bookingSlot, date, booking, closeModal}: CaddieSelectorModalProps) => {

    const { getCaddie, getAvailableCaddiesForBooking, updateBookingSlot, deleteBookingSlot } = useApi();

    const [caddie, setCaddie] = useState<ClubCaddie>();
    const [availableCaddies, setAvailableCaddies] = useState<UserAvailability[]>();

    const [canPerformAction, setCanPerformAction] = useState<boolean>(true)

    useEffect(() => {
        setCanPerformAction(true);

        const loadCaddie = async (caddieId: string) => {
            let caddie = await getCaddie(clubId, caddieId)
            setCaddie(caddie);
        }

        const loadAvailableCaddiesForBooking = async (teeTime: string) => {
            let availableCaddies = await getAvailableCaddiesForBooking(clubId, teeTime);
            if (availableCaddies && availableCaddies.resource) {
                setAvailableCaddies(availableCaddies.resource);
            }
        }

        if (id) {
            loadCaddie(id)
                .catch((err) => logError('Error getting currently selected caddie', err));
        }

        if (date !== undefined && booking !== undefined) {
            loadAvailableCaddiesForBooking(booking.teeTime)
                .catch((err) => logError('Error getting available caddies', err));
        }
    }, [id, date])

    const addCaddieToBookingSlot = async (userId?: string) => {
        if (!userId || !bookingSlot?.id || !booking?.bookingId) {
            toast.error('Unable to detect the selected caddie.')
            return
        }

        let existingBookingSlot = {...bookingSlot};
        const now = moment().format("YYYY-MM-DD");
        const bookingTeeTime = moment(booking?.teeTime).format("YYYY-MM-DD");
        if (now === bookingTeeTime) {
            existingBookingSlot.caddieId = userId;
            existingBookingSlot.status = "PENDING";
        } else {
            existingBookingSlot.caddieId = userId;
            existingBookingSlot.status = "DRAFT";
        }
        await updateBookingSlot(existingBookingSlot);
        closeModal();
    }

    const onDelete = async () => {
        if (!booking || !bookingSlot?.id) {
            toast.error('Unable to determine the booking or slot you are trying to delete.')
            return;
        }

        await deleteBookingSlot(booking.bookingId, bookingSlot.id)
        closeModal();
    }

    return (
        <Row>
            <Col xs={12}>
                {/* <h4 className={"heading"}>Currently Selected Caddie</h4>
                {caddie && <CaddieCard
                    name={caddie.displayName}
                    userId={caddie.userId}>

                    <Card.Footer>
                        <Button
                            variant="warning"
                            onClick={() => removeCaddie(caddie.caddieId)}>
                            Remove from Job
                        </Button>
                    </Card.Footer>
                </CaddieCard>
                }
                {!caddie && <p>No caddie currently selected.</p>} */}
                <CaddieBadge
                    key={`${booking?.bookingId}-${bookingSlot?.id}`}
                    clubId={booking?.clubId || clubId}
                    bookingId={bookingSlot?.bookingId}
                    onStatusChange={() => closeModal()}
                    onDelete={onDelete}
                    bookingSlot={bookingSlot}
                    isFromBookingSidePanel={false}
                />
            </Col>
            <Col xs={12}>
                <h4 className={"heading"}>Your Available Caddies</h4>
                {availableCaddies && availableCaddies.length > 0 &&
                <CardDeck>
                    {availableCaddies.map((caddie) => {
                        let t = new Date()
                        let window = ''
                        let todayAvailForClub = caddie.availabilities.filter((a) => {
                            let d = new Date(a.date)
                            return d.getDate() === t.getDate() && a.club?.id && a.club.id === clubId
                        });
                        if (todayAvailForClub.length === 1) {
                            let availabilityResource = todayAvailForClub[0];
                            if (availabilityResource.windows && availabilityResource.windows.length === 1) {
                                let windowResource = availabilityResource.windows[0];
                                let from = windowResource.from === '00:00' ? 'Start of Day' : windowResource.from
                                let to = windowResource.to === '23:59' ? 'End of Day' : windowResource.to
                                window = from + ' - ' + to
                            }
                        }
                        return <CaddieCard
                            key={caddie.caddieId}
                            name={caddie.user.displayName}
                            userId={caddie.user.id}
                            window={window}
                        >
                            <Card.Footer>
                                <Button
                                    variant="primary"
                                    disabled={!canPerformAction}
                                    onClick={() => addCaddieToBookingSlot(caddie.caddieId)}>
                                    Select for Job
                                </Button>
                            </Card.Footer>
                        </CaddieCard>
                    })}
                </CardDeck>
                }
                {(!availableCaddies || availableCaddies.length === 0) &&
                <p>No available caddies.</p>}
            </Col>
        </Row>
    )
}

export default CaddieSelectorModalContent;