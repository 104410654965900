import React, {useEffect, useState} from 'react';
import {Member, MemberComment, MemberService} from "../../../../../../services/member/MemberService";
import {handicaddieGreen, logErrorFromApi} from "../../../../../../services/Utils";
import Comment from "../../../../../../components/Comment";
import {toast} from "react-toastify";
import NewComment from "../../../../../../components/EnterNewComment";
import {Card} from "react-bootstrap";

interface Props {
    member: Member
}

const MemberComments = ({ member }: Props) => {

    const { getMemberComments, createMemberComment, deleteMemberComment } = MemberService();

    const [comments, setComments] = useState<MemberComment[]>([])

    useEffect(() => {
        loadComments(member.id)
    }, [member]);

    const loadComments = async (id: string) => {
        let comments = await getMemberComments(id)
            .catch(logErrorFromApi)
        if (comments) {
            setComments(comments)
        }
    }

    const submitComment = async (comment: string) => {
        createMemberComment(member.id, comment)
            .then((r) => {
                if (r) {
                    toast.success('Comment saved.')
                }
            })
            .catch(logErrorFromApi)
            .finally(() => loadComments(member.id))
    }

    const handleDelete = async (commentId: string) => {
        deleteMemberComment(member.id, commentId)
            .then((r) => {
                if (r) {
                    toast.success('Comment deleted.')
                }
            })
            .catch(logErrorFromApi)
            .finally(() => loadComments(member.id))
    }

    return (
        <>
            <NewComment submit={submitComment} />

            <div style={{
                backgroundColor: handicaddieGreen,
                color: 'white',
                padding: '1em'
            }}>
                <h3 style={{
                    fontSize: '1.3em',
                    margin: 0,
                    padding: 0
                }}>COMMENTS</h3>
            </div>

            {comments.map(c => <Comment
                comment={c.comment}
                submittedBy={c.submitted}
                submittedOn={c.submittedOn}
                onDelete={() => handleDelete(c.id)}
            />)}
            {comments && comments.length === 0 && <Card><Card.Body>No comments.</Card.Body></Card>}
        </>

    )
}

export default MemberComments;