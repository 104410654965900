import React, {useEffect, useState} from "react";
import {useStore} from "../services/state/useStore";
import {Profile} from "../parts/profile/Profile";
import {User} from "../services/ApiDomain";

interface Props {
}

export const MyProfile = ({ }: Props) => {

    const [user, setUser] = useState<User>();

    const { currentUser } = useStore();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        setUser(currentUser);
    }, [currentUser]);

    return (
        <>
            {user && <Profile
                userId={user.id}
                isCurrentUser={true}
                displayName={user.displayName}
                email={user.email}
                phone={user.phoneNumber}
                bio={user.bio}
                isCaddieMasterForUser={false}
            />}
            {(!user) && <p>Loading... </p>}
        </>
    )

}