import './ProfileAvailability.scss';

import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import {useApi} from "../../../../services/useApi";
import {logErrorFromApi} from "../../../../services/Utils";
import moment from "moment";

interface Row {
    key: number
    date: string
    doubleBag: string
    window: string
    hasBooking: string
}

interface Props {
    caddieId: string
    clubId: string
}

export const ProfileAvailability = ({caddieId, clubId}: Props) => {

    const {getCurrentAvailableCaddiesForCaddie} = useApi();

    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<Row[]>([]);

    useEffect(() => {
        loadAvailabilities()
    }, [caddieId, clubId]);

    const loadAvailabilities = async () => {
        setLoading(true)
        try {
            let resp = await getCurrentAvailableCaddiesForCaddie(clubId, caddieId)
            if (resp.resource == null || resp.resource.length !== 1) {
                return;
            }
            setRows(
                resp.resource[0].availabilities
                    .map(avail => {
                        let w = 'All day'
                        if (avail.windows && avail.windows.length === 1) {
                            let from = avail.windows[0].from
                            let to = avail.windows[0].to

                            w = `${from} - ${to}`
                        }

                        let date = moment(avail.date)
                        return {
                            key: date.date() + date.month() + date.year(),
                            date: date.format('dddd Do MMM YYYY'),
                            doubleBag: avail.doubleBag ? 'Accept multiple bags' : 'Single bag only',
                            window: w,
                            hasBooking: avail.bookings ? avail.bookings.length + '' : '0'
                        }
                    })
            )
        } catch (e) {
            logErrorFromApi(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={'bookingsTable_dateRangeContainer'}>
                <span style={{paddingRight: '1em', lineHeight: '2.5em', textAlign: 'center'}}>Caddie Availability</span>
            </div>
            <Table borderless striped>
                <thead className="bookingsTable_header">
                    <tr>
                        <th>Date</th>
                        <th>Double Bag</th>
                        <th>Window</th>
                        <th>Bookings</th>
                    </tr>
                </thead>
                <tbody className={"bookingsTable_bodyContainer"}>
                {!loading && rows && rows
                    .sort((a, b) => a.key - b.key)
                    .map(row => {
                        return <tr key={row.key}>
                            <td>{row.date}</td>
                            <td>{row.doubleBag}</td>
                            <td>{row.window}</td>
                            <td>{row.hasBooking}</td>
                        </tr>
                    })}
                {!loading && rows && rows.length === 0 && <tr>
                    <td>No availability.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>}
                {loading && <tr>
                    <td>Loading...</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>}
                </tbody>
            </Table>
        </>
    )
}