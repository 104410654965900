import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../services/Utils";
import {useApi} from "../../../services/useApi";

type BusyDayModalProps = {
    clubId: string
    selectedDate?: string
    showBusyDayModal: boolean
    handleBusyDayModalClose: () => void
}

const BusyDayModal = ({clubId, selectedDate, showBusyDayModal, handleBusyDayModalClose}: BusyDayModalProps) => {

    const [loading, setLoading] = useState(false);
    const { createAnnouncement } = useApi();

    const sendBusyDayNotification = () => {
        if (!clubId || !selectedDate) {
            return;
        }
        setLoading(true);

        createAnnouncement(clubId, "BUSY_DAY", selectedDate)
            .then(() => {
                toast.success('Success.');
                handleBusyDayModalClose();
                setLoading(false);
            })
            .catch((error) => {
                logErrorFromApi(error);
                handleBusyDayModalClose();
                setLoading(false);
            })
    }

    return (
        <Modal show={showBusyDayModal} onHide={() => handleBusyDayModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Send Busy Day Notification</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to send the notification to the full caddie program?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={() => handleBusyDayModalClose()}>Close</Button>
                <Button disabled={loading} variant="primary" onClick={sendBusyDayNotification}>Send</Button>
                {loading &&
                    <Spinner animation={"border"}
                        variant="primary"
                        style={{ marginLeft: '1em' }} />}
            </Modal.Footer>
        </Modal>
    )
}

export default BusyDayModal;