import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";
import {User} from "../ApiDomain";

export interface Member {
    id: string
    externalRef: string
    claimed: boolean
    user: User
}

export const MemberService = () => {

    const find = (clubId: string, searchTerm: string): Promise<Member[] | void> => {
        return ApiClient.getInstance().get<Member[]>(`/members?forClubId=${clubId}&forSearch=${searchTerm}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        findMembers: find
    }
}
