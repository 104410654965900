import React from 'react';
import {Document} from "../../../../services/documents/DocumentService";
import {CloudDownloadOutlined, DeleteOutlined} from "@material-ui/icons";

interface Props {
    document: Document
    downloadDocument: (doc: Document) => void
    deleteDocument: (docId: string) => void
}

const DocumentRow = ({document, downloadDocument, deleteDocument}: Props) => {
    return (
        <tr>
            <td>{document.name}</td>
            <td>{document.scopeType === 'TOUR' ? 'Global' : 'Event'}</td>
            <td>{document.uploaded.split('T')[0]}</td>
            <td>
                <CloudDownloadOutlined onClick={() => downloadDocument(document)} htmlColor='#1db954' style={{
                    cursor: 'pointer',
                    marginRight: '0.25em'
                }} />
                <DeleteOutlined onClick={() => deleteDocument(document.id)} color={'error'} style={{
                    cursor: 'pointer'
                }}/>
            </td>
        </tr>
    )
}

export default DocumentRow;