import React from "react";
import {LadiesEuropeanTourShowcaseCard} from "../parts/caddieontour/tours/let/LadiesEuropeanTourShowcase";

interface Props {

}

const CaddieOnTour = ({}: Props) => {

    return (
        <>
            <div style={{paddingBottom: '10em'}}>
                <h1 className={"pageHeading"}>Caddie on Tour</h1>
                <LadiesEuropeanTourShowcaseCard />
            </div>
        </>
    )

}

export default CaddieOnTour;