import {Field, FieldProps, Formik, FormikHelpers,} from "formik";
import {useEffect, useState} from "react";
import {TextField} from "../../../../../components/forms/TextField";
import {toast} from "react-toastify";
import {useApi} from "../../../../../services/useApi";
import * as Yup from "yup"
import {Course} from "../../../../../services/ApiDomain";
import {logErrorFromApi} from "../../../../../services/Utils";
import {Form} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../services/store/store";
import {loadSelectedClubCourses} from "../../../../../services/store/reducers/clubs/asyncActions";

type FormValuesType = {
    clubId: string,
    name: string,
    colourCode: string
}

const initialValues: FormValuesType = {
    clubId: "",
    name: "",
    colourCode: "",
};

type Props = {
    onClose: () => void,
    item?: any
    onSelectCourse: (c: Course) => void
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    colourCode: Yup.string().required("Please select a colour for the course"),
});

export const CourseCreateForm = (props: Props) => {
    const {createClubCourse} = useApi()
    const {onClose, item, onSelectCourse} = props;
    const {clubId} = item ?? {};

    const [formInitialObjValue, setFormInitalObjValue] = useState<FormValuesType>(initialValues);

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (clubId) {
            setFormInitalObjValue(prev => ({...prev, clubId}));
        } else {
            setFormInitalObjValue({
                ...initialValues,
            });
        }
    }, [clubId]);

    const handleCreateCourse = async (data: FormValuesType, handlers: FormikHelpers<FormValuesType>) => {
        const {name, clubId, colourCode} = data;

        const {resetForm, setSubmitting} = handlers;

        try {

            const response = await createClubCourse(clubId, name, colourCode);

            // Check if response is valid
            if (!response?.id) {
                toast.error("Failed to create Course");
                return;
            }

            onSelectCourse(response)
            resetForm();
            setSubmitting(false);
            onClose();
            dispatch(loadSelectedClubCourses())
        }
        catch (error) {
            logErrorFromApi(error)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={formInitialObjValue}
                validationSchema={validationSchema}
                onSubmit={(values: FormValuesType, handleProps: FormikHelpers<FormValuesType>) => {
                    handleCreateCourse(values, {...handleProps});
                }}
            >
                {({touched, errors, submitForm}) => (
                    <Form>
                        <div className="p-3">
                            <div className="mb-3" style={{fontSize: "18px", fontWeight: 600}}>Add New Course</div>
                            {/* 1 */}
                            <div className="input-group mb-2">
                                <TextField name="name" label={"Name"} />
                            </div>
                            {/* 2 */}
                            <div className="mb-2 mt-3" style={{fontSize: "18px", fontWeight: 600}}>Choose Color</div>

                            <div className="d-flex align-items-center " style={{gap: '15px'}}>
                                {['#ff6161', '#136724', '#666', '#6196ff'].map((color, index) => (
                                    <div className="form-check" key={index}>
                                        <Field name="colourCode">
                                            {({field, form, meta}: FieldProps) => (
                                                <>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id={`colorCode${index + 1}`}
                                                        value={color}
                                                        checked={field.value === color}
                                                        onChange={() => form.setFieldValue('colourCode', color)}
                                                    />
                                                    <label
                                                        className="form-check-label color-code-preview"
                                                        htmlFor={`colorCode${index + 1}`}
                                                        style={{backgroundColor: color}}
                                                    ></label>
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                ))}

                            </div>
                            <div className="invalid-feedback mt-0" style={{display: touched.colourCode && errors.colourCode ? "block" : ""}}>{touched.colourCode && errors.colourCode}</div>
                            <div className="text-right mt-4">
                                <button className="btn btn-outline-danger mr-2" style={{fontSize: "15px", fontWeight: 500}} type="button" onClick={onClose}>
                                    Cancel
                                </button>

                                <button className="btn btn-primary px-4"
                                        style={{fontSize: "15px", fontWeight: 500}}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            submitForm()
                                        }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};
