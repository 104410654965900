import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";

export interface CheckoutSession {
    url: string
}

export const PurchaseService = () => {

    const create: (productType: string, productId: string) => Promise<CheckoutSession | void> =
        (productType: string, productId: string) => {
        return ApiClient.getInstance().post<CheckoutSession>(`/purchases`, {
            productType: productType,
            productId: productId
        })
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        createCheckoutSession: create
    }
}

