import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useApi} from "../../../../services/useApi";
import {logErrorFromApi} from "../../../../services/Utils";
import {toast} from "react-toastify";

interface Props {
    show: boolean
    close: () => void
    refreshCaddies: () => void
    id?: string
    name?: string
}

const DeleteCaddieConfirmationModal = ({show, close, refreshCaddies, id, name}: Props) => {

    const { deleteCaddie } = useApi();

    const confirm = async () => {
        if (!id) {
            toast.error('Error: please try reloading the page and trying again.')
            return;
        }

        try {
            await deleteCaddie(id);
            toast.success('Caddie successfully deleted.');
            close();
            refreshCaddies();
        } catch (e) {
            logErrorFromApi(e)
        }
    }

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Caddie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete {name} as a caddie on your caddie program?</p>

                <p>If you perform this action, the caddie will have all their future bookings cancelled.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Close</Button>
                <Button variant="primary" onClick={confirm}>Delete Caddie</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteCaddieConfirmationModal;