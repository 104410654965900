import React from 'react';
import {Form, Spinner, Table} from "react-bootstrap";
import Section from "../../../../components/section/Section";
import {Document} from "../../../../services/documents/DocumentService";
import DocumentRow from "./DocumentRow";

interface Props {
    tourDocuments: Document[]
    loading: boolean
    downloadDocument: (doc: Document) => void
    deleteDocument: (docId: string) => void
}

const DocumentsTable = ({ tourDocuments, loading, downloadDocument, deleteDocument }: Props) => {

    return (
        <Section title="Document Library">
            <Form.Text id="DocumentLibraryHelp" style={{ marginBottom: '2em' }}>
                Below are all the files you've already uploaded. These will be viewable by your caddies on their app.
            </Form.Text>

            <Table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Type</td>
                        <td>Uploaded</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                {loading && <Spinner animation="border" />}
                {!loading && tourDocuments && <tbody>
                    {tourDocuments.map((doc) => {
                        return <DocumentRow
                            key={doc.id}
                            document={doc}
                            downloadDocument={downloadDocument}
                            deleteDocument={deleteDocument}
                        />
                    })}
                </tbody>}
            </Table>
        </Section>
    )
}

export default DocumentsTable;