import React, {ReactElement} from 'react';
import DropdownMenu from "react-bootstrap/DropdownMenu";
import SvgIcon from "@material-ui/core/SvgIcon";
import {MoreVert} from "@material-ui/icons";
import {Dropdown} from "react-bootstrap";

import './threedotmenu.scss';

interface Props {
    children: ReactElement<DropdownMenu>
    disabled?: boolean
}

const ThreeDotMenu = ({children, disabled}: Props) => {
    return (
        <>
            {disabled && <MoreVert style={{ color: '#ccc', cursor: 'not-allowed' }} />}
            {!disabled && <Dropdown className={"threeDotMenu"}>
                    <Dropdown.Toggle as={SvgIcon} disabled={disabled}>
                        <MoreVert />
                    </Dropdown.Toggle>
                    {children}
                </Dropdown>
            }
        </>
    )
}

export default ThreeDotMenu;