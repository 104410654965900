import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import Avatar from "../../Avatar";

type CaddieCardProps = {
    name: string
    userId: string
    clubName?: string
    phoneNumber?: string
    window?: string,
    onClick?: () => void,
    children?: React.ReactNode
};

const CaddieCard = ({name, userId, clubName, phoneNumber, window, onClick = () => {}, children}: CaddieCardProps) => {

    return (
        <Card onClick={() => onClick()} className="caddieCard">
            <Card.Body>
                <Row>
                    <Col xs={12} md={"auto"}>
                        <Avatar user={userId} size={'s'} />
                    </Col>
                    <Col>
                        <h4>{name}</h4>
                        {clubName && <p>{clubName}</p>}
                        {phoneNumber && <p><i>{phoneNumber}</i></p>}
                        {window && <p><i>{window}</i></p>}
                    </Col>
                </Row>
            </Card.Body>
            {children && children}
        </Card>
    )
}

export default CaddieCard;