import React, {useEffect, useState} from 'react';
import {Button, Col, FormControl, Row, Table} from "react-bootstrap";
import {UserCaddie} from "../../../../services/ApiDomain";

import './CaddiesTable.scss';
import {AddCircle, DeleteOutline} from "@material-ui/icons";
import AddNewNoSmartphoneCaddie from "../modals/AddNewNoSmartphoneCaddie";
import {darkerHandicaddieGreen} from "../../../../services/Utils";

interface Props {
    caddies: UserCaddie[]
    refreshCaddies: () => void
    filterFunc: (allCaddies: UserCaddie[], searchTerm: string) => UserCaddie[]
    showDeleteAlert: (id: string, name: string) => void
    loading?: boolean
}

const NoSmartphoneCaddieTable = ({caddies, refreshCaddies, filterFunc, showDeleteAlert, loading}: Props) => {

    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredCaddies, setFilteredCaddies] = useState<UserCaddie[]>([]);

    useEffect(() => {
        setFilteredCaddies(filterFunc(caddies, searchTerm.trim()))
    }, [caddies])

    useEffect(() => {
        setFilteredCaddies(filterFunc(caddies, searchTerm.trim()));
    }, [searchTerm])

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <h3 className="pageSubHeading">No Smartphone Caddies</h3>
                </Col>
                <Col xs={12} md={6}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            style={{ marginRight: '0.5em', backgroundColor: darkerHandicaddieGreen }}
                            onClick={() => setShowAddModal(true)}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <AddCircle style={{ marginRight: '0.5em' }}/> New
                            </div>
                            </Button>
                        <FormControl type="text" placeholder="Search"
                                     onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Table borderless>
                    <thead className={"caddiesTable_headingContainer"}>
                        <tr>
                            <td>Name</td>
                            <td>Club</td>
                            <td>Contact Number</td>
                            <td />
                        </tr>
                    </thead>
                    <tbody className={"caddiesTable_bodyContainer"}>
                        {!loading && filteredCaddies && filteredCaddies.map(caddie => {
                            return <tr key={caddie.caddieId}>
                                <td>{caddie.displayName}</td>
                                <td>{caddie.clubName}</td>
                                <td>{caddie.phoneNumber}</td>
                                <td>
                                    <DeleteOutline
                                        className={"caddiesTable_deleteBtn"}
                                        onClick={() => showDeleteAlert(caddie.caddieId, caddie.displayName)}/>
                                </td>
                            </tr>
                        })}
                        {!loading && filteredCaddies && filteredCaddies.length === 0 && <tr>
                            <td className={"caddiesTable_colOne"}>No caddies...</td>
                            <td/>
                            <td/>
                            <td/>
                        </tr>}
                        {loading && <tr>
                            <td>Loading...</td>
                            <td/>
                            <td/>
                            <td/>
                        </tr>}
                    </tbody>
                </Table>
            </Row>

            <AddNewNoSmartphoneCaddie
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={() => {
                    refreshCaddies()
                    setShowAddModal(false)
                }} />
        </>
    )
}

export default NoSmartphoneCaddieTable;