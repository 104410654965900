import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../services/store/store";
import {Form, Spinner} from "react-bootstrap";
import {searchForCaddies} from "../../../../../services/store/reducers/scheduler/asyncActions";
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";

interface Props {

}

const CaddieSearchInput = ({}: Props) => {

    const [caddieSearchTerm, setCaddieSearchTerm] = useState<string>("")
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [typing, setTyping] = useState<boolean>(false)

    const { caddieSearchLoading, caddiesForDateLoading } = handicaddieSelector(state => state.scheduler)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        setTyping(true)
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dispatch(searchForCaddies(caddieSearchTerm))
                setTyping(false)
            }, 1000)
        );
    }, [caddieSearchTerm]);

    return (
        <>
            <Form.Control
                type={"text"}
                placeholder="Search for caddie..."
                style={{marginBottom: '0.5em'}}
                onChange={(e) => setCaddieSearchTerm(e.target.value)}
            />
            {(typing || caddieSearchLoading || caddiesForDateLoading) && <div style={{display: 'flex', justifyContent: 'center'}}>
                <Spinner animation={'border'} />
            </div>}
        </>
    )
}

export default CaddieSearchInput;