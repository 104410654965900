import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../services/Utils";
import {useApi} from "../../../services/useApi";
import {ClubBooking} from "../../../services/ApiDomain";
import SelectableCaddie from "./sidedrawer/components/SelectableCaddie";

type PublicSlotModalProps = {
    booking?: ClubBooking
    show: boolean
    close: (refreshTimesheet: boolean) => void
}

const PublicSlotModal = ({booking, show, close}: PublicSlotModalProps) => {

    const [loading, setLoading] = useState(false);

    const { createPublicSlots } = useApi();

    const createPublicSlotsInBooking = () => {
        if (!booking) {
            return;
        }
        setLoading(true);

        createPublicSlots(booking.bookingId)
            .then(() => {
                toast.success('Successfully marked booking as public.');
                close(true);
            })
            .catch((error) => {
                logErrorFromApi(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Modal show={show} onHide={() => close(false)} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Publish Public Slots</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>You are making all Open, Pending and Rejected slots public to your full caddie program.
                It'll be fastest finger first!
                </p>

                {booking && <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                    <p>These caddies will remain on this booking:</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {booking.bookingSlots.map(caddie => {
                            if (!caddie.userId || caddie.status !== 'ACCEPTED') {
                                return <></>;
                            }
                            return <SelectableCaddie
                                key={caddie.userId}
                                selected={false}
                                userId={caddie.userId}
                                name={caddie.displayName || ""}
                                noSmartphone={caddie.noSmartphone} />
                        })}
                        {booking.bookingSlots.filter(c => c.status === 'ACCEPTED').length === 0 &&
                            <p style={{ marginLeft: 16 }}>No caddies will remain.</p>}
                    </div>
                </div>}

                {booking && <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                    <p>These caddies will be unbooked:</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {booking.bookingSlots.map(caddie => {
                            if (!caddie.userId || caddie.status === 'ACCEPTED') {
                                return <></>;
                            }
                            return <SelectableCaddie
                                key={caddie.userId}
                                selected={false}
                                userId={caddie.userId}
                                name={caddie.displayName || ""}
                                noSmartphone={caddie.noSmartphone} />
                        })}
                        {booking.bookingSlots.filter(c => c.status !== 'ACCEPTED').length === 0 &&
                            <p style={{ marginLeft: 16 }}>No caddies will be unbooked.</p>}
                    </div>
                </div>}

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={() => close(false)}>Close</Button>
                <Button disabled={loading} variant="primary" onClick={createPublicSlotsInBooking}>Publish</Button>
                {loading &&
                    <Spinner animation={"border"}
                        variant="primary"
                        style={{ marginLeft: '1em' }} />}
            </Modal.Footer>
        </Modal>
    )
}

export default PublicSlotModal;