import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useStore} from "../services/state/useStore";
import {useApi} from "../services/useApi";
import {UserCaddie} from "../services/ApiDomain";
import {PendingCaddieFeed} from "../parts/caddiemaster/caddies/PendingCaddieFeed";
import NoSmartphoneCaddieTable from "../parts/caddiemaster/caddies/table/NoSmartphoneCaddieTable";
import CaddiesTable from "../parts/caddiemaster/caddies/table/CaddiesTable";
import DeleteCaddieConfirmationModal from "../parts/caddiemaster/caddies/modals/DeleteCaddieConfirmationModal";

interface Props {

}

const Caddies = ({}: Props) => {

    const { currentUser } = useStore();
    const { getCaddies, getPendingCaddies, getNoSmartphoneCaddies } = useApi();

    const [pendingCaddies, setPendingCaddies] = useState<UserCaddie[]>([]);
    const [approvedCaddies, setApprovedCaddies] = useState<UserCaddie[]>([]);
    const [noSmartphoneCaddies, setNoSmartphoneCaddies] = useState<UserCaddie[]>([]);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deletingCaddieId, setDeletingCaddieId] = useState<string>()
    const [deletingCaddieName, setDeletingCaddieName] = useState<string>()

    useEffect(() => {
        refreshCaddies()
    }, [currentUser])

    const refreshCaddies = async () => {
        if (!currentUser) {
            return;
        }

        let c = await getCaddies(currentUser.id)
        setApprovedCaddies(c)

        let p = await getPendingCaddies(currentUser.id)
        setPendingCaddies(p)

        let n = await getNoSmartphoneCaddies(currentUser.id)
        setNoSmartphoneCaddies(n)
    }

    const filterCaddies = (caddies: UserCaddie[], searchTerm: string) => {
        let list: UserCaddie[] = [];

        for (const caddie of caddies) {
            if (caddie.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                caddie.clubName.toLowerCase().includes(searchTerm.toLowerCase())) {
                list.push(caddie);
            }
        }
        return list;
    }

    const deleteCaddie = (id: string, name: string) => {
        setDeletingCaddieId(id)
        setDeletingCaddieName(name)
        setShowDeleteModal(true)
    }

    return (
        <>
        <Row>
            <Col xs={12} style={{ marginTop: '3em' }}>
                <h3 className="pageSubHeading">Pending Caddie Requests</h3>
                <PendingCaddieFeed
                    caddies={pendingCaddies}
                    refreshCaddies={refreshCaddies}
                />
            </Col>
            <Col xs={12} style={{ marginTop: '3em' }}>
                <CaddiesTable
                    caddies={approvedCaddies}
                    filterFunc={filterCaddies}
                    refreshCaddies={refreshCaddies}
                    showDeleteAlert={deleteCaddie} />
            </Col>
            <Col xs={12} style={{ marginTop: '3em' }}>
                <NoSmartphoneCaddieTable
                    caddies={noSmartphoneCaddies}
                    filterFunc={filterCaddies}
                    refreshCaddies={refreshCaddies}
                    showDeleteAlert={deleteCaddie} />
            </Col>
        </Row>
            <DeleteCaddieConfirmationModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                id={deletingCaddieId}
                name={deletingCaddieName}
                refreshCaddies={refreshCaddies} />
            </>
    )
}

export default Caddies;