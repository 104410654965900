import {useEffect, useState} from "react";
import {useApi} from "../useApi";
import {CaddieMaster, CaddieResource, TourCaddieResource, TourMaster, User} from "../ApiDomain";

export interface Store {
    showAnnouncementBanner: boolean,
    updateShowAnnouncementBanner: (show: boolean) => void,
    hideAnnouncementBanner: () => void,

    token: string;
    updateToken: (token: string) => void;

    isCaddie: boolean,
    caddies: CaddieResource[],
    caddiesLoaded: boolean,

    isCaddieMaster: boolean,
    caddieMasters: CaddieMaster[],
    caddieMastersLoaded: boolean,

    tourCaddies: TourCaddieResource[],
    isTourCaddie: boolean,
    tourCaddiesLoaded: boolean,

    tourMasters: TourMaster[],
    isTourMaster: boolean,
    tourMastersLoaded: boolean,

    userLoaded: boolean,
    currentUser: User | undefined,
    reloadUser: () => void,

    userAvatar: string | undefined,
    reloadUserAvatar: () => void,

    clearLocalStorage: () => void;
}

const SHOW_ANNOUNCEMENT_BANNER = 'showAnnouncementBanner';
const CADDIES = 'caddies';
const TOUR_CADDIES = 'tourcaddies';
const CADDIE_MASTERS = 'caddieMasters';
const TOUR_MASTERS = 'tourMasters';
const TOKEN = 'token';

export const useGlobalState: () => Store = () => {
    const [showAnnouncementBanner, setShowAnnouncementBanner] = useState<boolean>(false);
    const [token, setToken] = useState<string>(initialValueFromLocalStorage(TOKEN));

    const [isCaddieMaster, setIsCaddieMaster] = useState<boolean>(false);
    const [caddieMasters, setCaddieMasters] = useState<CaddieMaster[]>(initialCaddieMastersValueFromLocalStorage(CADDIE_MASTERS));
    const [caddieMastersLoaded, setCaddieMastersLoaded] = useState<boolean>(false);
    const [userCaddieMastersLoading, setUserCaddieMastersLoading] = useState<boolean>(false);

    const [isCaddie, setIsCaddie] = useState<boolean>(false);
    const [caddies, setCaddies] = useState<CaddieResource[]>(initialCaddiesValueFromLocalStorage(CADDIES));
    const [caddiesLoaded, setCaddiesLoaded] = useState<boolean>(false);
    const [userCaddiesLoading, setUserCaddiesLoading] = useState<boolean>(false);

    const [isTourCaddie, setIsTourCaddie] = useState<boolean>(false);
    const [tourCaddies, setTourCaddies] = useState<TourCaddieResource[]>(initialTourCaddiesValueFromLocalStorage(TOUR_CADDIES));
    const [tourCaddiesLoaded, setTourCaddiesLoaded] = useState<boolean>(false);
    const [tourCaddiesLoading, setTourCaddiesLoading] = useState<boolean>(false);

    const [isTourMaster, setIsTourMaster] = useState<boolean>(false);
    const [tourMasters, setTourMasters] = useState<TourMaster[]>(initialTourMastersValueFromLocalStorage(TOUR_MASTERS));
    const [tourMastersLoaded, setTourMastersLoaded] = useState<boolean>(false);
    const [tourMastersLoading, setTourMastersLoading] = useState<boolean>(false);

    const [currentUser, setCurrentUser] = useState<User | undefined>();
    const [userLoaded, setUserLoaded] = useState<boolean>(false);
    const [currentUserLoading, setCurrentUserLoading] = useState<boolean>(false);

    const [userAvatar, setCurrentUserAvatar] = useState<string | undefined>();
    const [currentUserLoadingAvatar, setCurrentUserLoadingAvatar] = useState<boolean>(false);

    const {
        getCurrentUser,
        getAvatar,
        getCaddieMastersForUser,
        getCaddiesForUser,
        getTourCaddiesForUser,
        getTourMastersForUser
    } = useApi();

    const updateToken = (token: string) => {
        setToken(token);
        localStorage.setItem(TOKEN, token);
    }

    const updateCaddies = async () => {
        if (userCaddiesLoading || !currentUser) {
            return;
        }

        try {
            // Don't know why this was added & now needs removed again??
            // setUserLoaded(false);
            setUserCaddiesLoading(true);
            let caddiesForUser = await getCaddiesForUser(currentUser.id);
            setCaddies(caddiesForUser);
            setIsCaddie(caddiesForUser &&
                caddiesForUser.length > 0 &&
                caddiesForUser.find(caddie => caddie.approved) != undefined
            );
            localStorage.setItem(CADDIES, JSON.stringify(caddiesForUser));
        } finally {
            setUserCaddiesLoading(false);
            setCaddiesLoaded(true);
        }
    }

    const updateTourCaddies = async () => {
        if (tourCaddiesLoading || !currentUser) {
            return;
        }

        try {
            setTourCaddiesLoaded(false);
            setTourCaddiesLoading(true);
            let tourCaddiesForUser = await getTourCaddiesForUser(currentUser.id);
            setTourCaddies(tourCaddiesForUser);
            setIsTourCaddie(tourCaddiesForUser &&
                tourCaddiesForUser.length > 0 &&
                tourCaddiesForUser.find(caddie => caddie.approved) != undefined
            );
            localStorage.setItem(TOUR_CADDIES, JSON.stringify(tourCaddiesForUser));
        } finally {
            setTourCaddiesLoading(false);
            setTourCaddiesLoaded(true);
        }
    }

    const updateCaddieMasters = async () => {
        if (userCaddieMastersLoading || !currentUser) {
            return;
        }

        try {
            setCaddieMastersLoaded(false);
            setUserCaddieMastersLoading(true);
            let caddieMastersForUser = await getCaddieMastersForUser(currentUser.id);
            setCaddieMasters(caddieMastersForUser);
            if (caddieMastersForUser && caddieMastersForUser.length > 0) {
                setIsCaddieMaster(true);
            } else {
                setIsCaddieMaster(false);
            }
            localStorage.setItem(CADDIE_MASTERS, JSON.stringify(caddieMastersForUser));
        } finally {
            setUserCaddieMastersLoading(false);
            setCaddieMastersLoaded(true);
        }
    }

    const updateTourMasters = async () => {
        if (tourMastersLoading || !currentUser) {
            return;
        }

        try {
            setTourMastersLoaded(false);
            setTourMastersLoading(true);
            let tourMastersForUser = await getTourMastersForUser();
            setTourMasters(tourMastersForUser);
            setIsTourMaster(tourMastersForUser && tourMastersForUser.length > 0);
            localStorage.setItem(TOUR_MASTERS, JSON.stringify(tourMastersForUser));
        } finally {
            setTourMastersLoading(false);
            setTourMastersLoaded(true);
        }
    }

    const hideAnnouncementBanner = () => {
        updateShowAnnouncementBanner(false);
    }

    const updateShowAnnouncementBanner = (showAnnouncementBanner: boolean) => {
        setShowAnnouncementBanner(showAnnouncementBanner);
        localStorage.setItem(SHOW_ANNOUNCEMENT_BANNER, String(showAnnouncementBanner));
    }

    const reloadUser = () => {
        const updateCurrentUser = async () => {
            if (currentUserLoading) {
                return;
            }

            try {
                setCurrentUserLoading(true);
                setCurrentUser(await getCurrentUser());
            } finally {
                setCurrentUserLoading(false);
                setUserLoaded(true);
            }
        }

        if (token) {
            updateCurrentUser();
        }
    }

    const reloadUserAvatar = () => {
        const updateAvatar = async () => {
            if (currentUserLoadingAvatar || !currentUser) {
                return;
            }

            try {
                setCurrentUserLoadingAvatar(true);
                setCurrentUserAvatar(await getAvatar(currentUser.id));
            } finally {
                setCurrentUserLoadingAvatar(false);
            }
        }

        updateAvatar();
    }

    useEffect(() => {
        reloadUser();
    }, [token]);

    useEffect(() => {
        reloadUserAvatar();
        updateCaddieMasters();
        updateCaddies();
        updateTourCaddies();
        updateTourMasters();
    }, [currentUser]);

    const clearLocalStorage = () => {
        setToken('');
        setCaddies([])
        setCaddieMasters([])
        setCurrentUser(undefined);
        setCurrentUserAvatar(undefined);
        localStorage.removeItem(CADDIES);
        localStorage.removeItem(TOUR_CADDIES);
        localStorage.removeItem(CADDIE_MASTERS);
        localStorage.removeItem(TOUR_MASTERS);
        localStorage.removeItem(TOKEN);
    }

    return {
        showAnnouncementBanner,
        updateShowAnnouncementBanner,
        hideAnnouncementBanner,

        token,
        updateToken,

        isCaddie,
        caddies,
        caddiesLoaded,

        isCaddieMaster,
        caddieMasters,
        caddieMastersLoaded,

        isTourCaddie,
        tourCaddies,
        tourCaddiesLoaded,

        isTourMaster,
        tourMasters,
        tourMastersLoaded,

        currentUser,
        reloadUser,
        userLoaded,

        userAvatar,
        reloadUserAvatar,

        clearLocalStorage
    };
};

const initialValueFromLocalStorage: (key: string) => string = (key: string) => {
    let item = localStorage.getItem(key);
    return item == null ? '' : item;
}

const initialBooleanValueFromLocalStorage: (key: string) => boolean = (key: string) => {
    let item = localStorage.getItem(key);
    return item === null || item === 'true';
}

const initialCaddieMastersValueFromLocalStorage: (key: string) => CaddieMaster[] = (key: string) => {
    let item = localStorage.getItem(key);
    return item == null ? [] : JSON.parse(item) as CaddieMaster[];
}

const initialCaddiesValueFromLocalStorage: (key: string) => CaddieResource[] = (key: string) => {
    let item = localStorage.getItem(key);
    return item == null ? [] : JSON.parse(item) as CaddieResource[];
}

const initialTourCaddiesValueFromLocalStorage: (key: string) => TourCaddieResource[] = (key: string) => {
    let item = localStorage.getItem(key);
    return item == null ? [] : JSON.parse(item) as TourCaddieResource[];
}

const initialTourMastersValueFromLocalStorage: (key: string) => TourMaster[] = (key: string) => {
    let item = localStorage.getItem(key);
    return item == null ? [] : JSON.parse(item) as TourMaster[];
}
