import React from 'react';
import Avatar from "./Avatar";
import {User} from "../services/ApiDomain";
import moment from "moment";
import {Card} from "react-bootstrap";
import {DeleteOutlined} from "@material-ui/icons";

interface Props {
    comment: string
    submittedBy?: User
    submittedOn: Date
    onDelete: () => void
}

const Comment = ({comment, submittedBy, submittedOn, onDelete}: Props) => {
    return (
        <Card style={{ marginBottom: '1em' }}>
            <Card.Body>
                <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{whiteSpace: 'pre-wrap'}}>{comment}</div>
                    <DeleteOutlined className={"deleteBtn"} onClick={onDelete} />
                </div>
            </Card.Body>

            <Card.Footer>
                <div style={{display: "flex", flexDirection: 'column', width: '100%', alignItems: 'end'}}>
                    <div>
                        {submittedBy && <>
                            <Avatar size={'xs'} user={submittedBy.id}/>
                            <span>{submittedBy.displayName}</span>
                        </>}
                    </div>
                    <span style={{ marginTop: '0.5em' }}>{moment(submittedOn).format('MMMM Do YYYY, h:mm a')}</span>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default Comment;