import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Card, Form, Spinner} from "react-bootstrap";
import Section from "../../../../components/section/Section";
import {CommunicationAudience, CommunicationService} from "../../../../services/communication/CommunicationService";
import {toast} from "react-toastify";
import {Document, DocumentService} from "../../../../services/documents/DocumentService";

interface Props {
    tourId: string
}

const NewCommunicationForm = ({ tourId }: Props) => {

    const { createCommunication } = CommunicationService();
    const { getDocuments } = DocumentService();

    const [content, setContent] = useState<string>();
    const [audience, setAudience] = useState<string>();
    const [documents, setDocuments] = useState<Document[]>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [sending, setSending] = useState<boolean>(false);

    useEffect(() => {
        loadDocuments();
    }, []);

    const loadDocuments = async () => {
        let resp = await getDocuments(tourId);
        if (resp) {
            setDocuments(resp.resource);
        }
    }

    const sendMessage = async () => {
        if (!audience) {
            toast.error('Please select an audience');
            return;
        }
        if (!content) {
            toast.error('No message!');
            return;
        }
        setSending(true);
        try {
            let resp = await createCommunication(content, selectedDocuments, audience, tourId)
            if (resp) {
                toast.success('Communication sent.')
            }
        } finally {
            setSending(false);
        }

    }

    const amendSelectedDocs = (e: ChangeEvent<HTMLInputElement>, docId: string) => {
        e.target.checked ?
            setSelectedDocuments([...selectedDocuments, docId]) :
            setSelectedDocuments(selectedDocuments.filter(id => id !== docId));
    }

    return (
        <Section title="new message">
            <Form.Text id="NewMessageHelp" style={{ marginBottom: '2em' }}>
                Send out a message via email to your caddies.
            </Form.Text>
            <Form>
                <p>Select the audience for your new message</p>
                <Form.Check
                    type={'radio'}
                    label={'Approved caddies'}
                    id={'newMessage-audience-all'}
                    name={'newMessage-audience'}
                    onChange={() => setAudience(CommunicationAudience.APPROVED_TOUR_CADDIES)}
                />
                <Form.Text>These caddies have applied and you've approved them.</Form.Text>
                <Form.Check
                    type={'radio'}
                    label={'Valid caddies'}
                    id={'newMessage-audience-valid'}
                    name={'newMessage-audience'}
                    style={{ marginTop: '1em' }}
                    onChange={() => setAudience(CommunicationAudience.VALID_TOUR_CADDIES)}
                />
                <Form.Text>These caddies are approved and have purchased their annual pass.</Form.Text>

                <Form.Group className="mb-3" controlId="newMessage-message" style={{ marginTop: '2em' }}>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => setContent(e.target.value)}/>
                </Form.Group>

                <Form.Label style={{ marginTop: '2em' }}>Attachments</Form.Label>
                <Form.Text id="uploadDocumentHelp" style={{ marginBottom: '2em' }}>
                    You may optionally attach any of your uploaded documents to be sent in your message to your caddies.
                </Form.Text>

                {documents.map((doc) => {
                    return <Card>
                        <Card.Body>
                            <Form.Check
                                type={'checkbox'}
                                label={doc.name}
                                id={'newMessage-audience-valid'}
                                name={'newMessage-doc-' + doc.id}
                                onChange={(e) => amendSelectedDocs(e, doc.id)}
                            />
                        </Card.Body>
                    </Card>
                })}

                <br />

                {!sending && <Button onClick={() => sendMessage()} disabled={sending}>Send Message</Button>}
                {sending && <Spinner animation={"border"} />}
            </Form>
        </Section>
    )
}

export default NewCommunicationForm;