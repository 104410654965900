import {AppStartListening} from "../../store";
import {ListenerEffectAPI, PayloadAction, Unsubscribe} from "@reduxjs/toolkit";
import {loadClubs, loadSelectedClub} from "./asyncActions";
import {loadUser, LoadUserActionPayload} from "../currentUser";
import {handleClubChange} from "./slice";

const handleUserLoaded = async (_: PayloadAction<LoadUserActionPayload>, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(loadClubs())
}

const postLoadClubs = async (_: any, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(loadSelectedClub())
}

const postHandleClubChange = async (_: any, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(loadSelectedClub())
}

export function setupClubListeners(startListening: AppStartListening): Unsubscribe {
    const subscriptions = [
        startListening({
            actionCreator: handleClubChange,
            effect: postHandleClubChange,
        }),
        startListening({
            actionCreator: loadClubs.fulfilled,
            effect: postLoadClubs,
        }),
        startListening({
            actionCreator: loadUser.fulfilled,
            effect: handleUserLoaded,
        }),
    ]

    return () => {
        subscriptions.forEach((unsubscribe) => unsubscribe())
    }
}