import React, {useEffect, useRef, useState} from 'react';
import SchedulerHeaderDate from "../components/SchedulerHeaderDate";
import {
    CalendarTodayOutlined,
    ChevronLeft,
    ChevronRight,
    RefreshOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@material-ui/icons";
import moment from "moment";

import '../schedulerHeader.scss'
import {Spinner} from "react-bootstrap";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {useDispatch} from "react-redux";
import {handleDateChange, handleToggleHideTeeTimes} from "../../../../services/store/reducers/scheduler/slice";
import {AppDispatch} from "../../../../services/store/store";
import {dateWithoutTimezone} from "../../../../services/store/reducers/scheduler/service";
import {loadTimesheet} from "../../../../services/store/reducers/scheduler/asyncActions";

interface Props {
}

const MobileSchedulerHeader = ({}: Props) => {

    const [dates, setDates] = useState<Date[]>([]);

    const dateInput = useRef<HTMLInputElement>(null);

    const {
        loading,
        selectedDate,
        hideEmptyTeeTimes
    } = handicaddieSelector(state => state.scheduler)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        calculateDatesFrom(new Date(selectedDate));
    }, [])

    function calculateDatesFrom(start: Date) {
        let d = []
        let cursor = new Date(start);
        for (let i = 0; i < 7; i++) {
            d.push(new Date(cursor))
            cursor.setDate(cursor.getDate() + 1)
        }

        setDates(d)
    }

    const addDates = (n: number) => {
        let d = dates[0]
        d.setDate(d.getDate() + n)
        calculateDatesFrom(d)
    }

    const onDateSelect = (dStr: string) => {
        let d = new Date(dStr);
        dispatch(handleDateChange(dateWithoutTimezone(d)))
        calculateDatesFrom(d);
    }

    const showCalendar = () => {
        try {
            // @ts-ignore - I don't know why showPicker() isn't an allowed method for HTMLInputElement 🤷‍
            dateInput.current!.showPicker()
        } catch (e) {
            dateInput.current!.focus()
        }
    }

    return (
        <>
            <div style={{width: '100%', marginBottom: '1em'}}>
                <p className="monthHeading">
                    {moment(dates[0]).format('MMMM YYYY')}
                </p>
                <div className="datesContainer">
                    <ChevronLeft width={20} onClick={() => addDates(-1)}/>
                    <div>
                        {dates.map((d: Date) => {
                                let selectedDateAsDate = new Date(selectedDate)
                                return <SchedulerHeaderDate
                                    key={d.getDate()}
                                    date={d}
                                    highlighted={d.getDate() === selectedDateAsDate.getDate() &&
                                        d.getMonth() === selectedDateAsDate.getMonth()}
                                    onClick={() => dispatch(handleDateChange(dateWithoutTimezone(d)))}
                                />
                            }
                        )}
                    </div>
                    <ChevronRight width={20} onClick={() => addDates(1)}/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 1em'}}>
                <div className="scheduler_actionBtn" onClick={showCalendar}
                     style={{display: 'flex', width: 'fit-content', padding: '0 0.5em', paddingTop: '0.2em'}}>
                    <CalendarTodayOutlined style={{color: 'white'}}/>
                    <input
                        type="date"
                        className="mobileScheduler_dateInput"
                        // style={{ 'backgroundColor': darkerHandicaddieGreen, color: 'white', border: 'none' }}
                        ref={dateInput}
                        value={new Date(selectedDate).toISOString().split("T")[0]}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={e => onDateSelect(e.target.value)}
                    />
                </div>
                <div className="scheduler_actionsBar" style={{marginTop: 0}}>
                    <div className='scheduler_actionBtn' onClick={() => dispatch(handleToggleHideTeeTimes())}
                         style={{marginRight: '0.25em'}}>
                        {hideEmptyTeeTimes &&
                            <VisibilityOffOutlined style={{color: 'white', verticalAlign: 'text-top'}}/>}
                        {!hideEmptyTeeTimes &&
                            <VisibilityOutlined style={{color: 'white', verticalAlign: 'text-top'}}/>}
                    </div>
                    <div className='scheduler_actionBtn' onClick={() => dispatch(loadTimesheet())} style={{marginRight: '0.25em'}}>
                        {loading && <Spinner animation={'border'} size={'sm'} style={{color: 'white'}}/>}
                        {!loading && <RefreshOutlined style={{color: 'white', verticalAlign: 'text-top'}}/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileSchedulerHeader;