import './settingsDrawerContent.scss';

import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, FormControl, InputGroup, Spinner} from 'react-bootstrap';

import {useApi} from "../../../../../services/useApi";
import {getApiError} from "../../../../../services/Utils";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../services/store/store";
import {handleDrawerChange, handleResetDrawer} from "../../../../../services/store/reducers/scheduler/slice";
import {Drawer} from "../../../../../services/store/reducers/scheduler/domain";
import {loadClubs} from "../../../../../services/store/reducers/clubs/asyncActions";
import {reloadScheduler} from "../../../../../services/store/reducers/scheduler/asyncActions";

interface Props {
}

const SettingsDrawerContent = ({ }: Props) => {

    const { club, hasMultipleCourses } = useSelector((state: RootState) => state.clubs.selectedClub);
    const {updateClub} = useApi();

    const [needsUpdating, setNeedsUpdating] = useState<boolean>(false)
    const [interval, setInterval] = useState<string>()
    const [firstTeeTime, setFirstTeeTime] = useState<string>()
    const [lastTeeTime, setLastTeeTime] = useState<string>()
    const [paceOfPlay, setPaceOfPlay] = useState<number>(360) // 6 hours

    const [updating, setUpdating] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!club) {
            return;
        }

        setInterval(club.timesheetInterval + ''); // string hack
        setFirstTeeTime(club.timesheetStartTime);
        setLastTeeTime(club.timesheetEndTime);
        setPaceOfPlay(club.paceOfPlayMinutes);
        setNeedsUpdating(false);
    }, [club]);

    const updateValue = (setNewValue: () => void) => {
        setNewValue()
        setNeedsUpdating(true)
        setErrMsg(undefined)
    }

    const updateClubSettings = async () => {
        if (!club) {
            toast.error('Error loading your club, can\'t update your settings. Try refreshing and trying again.')
            return;
        }

        setUpdating(true);
        try {
            let newClub = { ...club }

            newClub.paceOfPlayMinutes = paceOfPlay ? paceOfPlay : club.paceOfPlayMinutes;
            newClub.timesheetInterval = interval ? parseInt(interval) : club.timesheetInterval;
            newClub.timesheetStartTime = firstTeeTime ? firstTeeTime : club.timesheetStartTime;
            newClub.timesheetEndTime = lastTeeTime ? lastTeeTime : club.timesheetEndTime;

            await updateClub(club.id, newClub)
            dispatch(loadClubs())
            toast.success('Club settings updated.')
            dispatch(reloadScheduler())
            dispatch(handleResetDrawer())
        } catch (e) {
            let apiError = getApiError(e);
            setErrMsg(apiError ? apiError.message : 'An unknown problem occurred.');
        } finally {
            setUpdating(false);
        }
    }

    return (
        <>
            <h3 style={{marginBottom: '3rem'}}>Settings</h3>
            {!club && <Spinner animation={'border'}/>}
            {club && <>
                <h6>Timesheet Settings</h6>

                {hasMultipleCourses && <Alert variant='danger'>
                    You have multiple courses, we currently don't support updating these values in the product.
                    <br />
                    Please reach out at <code>help@handicaddie.com</code> and we'll sort it!
                </Alert>}

                <div style={{width: '100%', marginBottom: '3rem'}}>
                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Interval</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                        This is the number of minutes between each tee time.</p>
                    <InputGroup className="mb-3">
                        <FormControl
                            type="number"
                            placeholder="Interval"
                            value={interval}
                            disabled={hasMultipleCourses}
                            onChange={(e) => updateValue(() => setInterval(e.target.value))}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>minutes</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>

                    <label htmlFor="basic-url" style={{marginBottom: 0}}>First Tee Time</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                        This is the number of minutes between each tee time.</p>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="time"
                            placeholder="Time"
                            value={firstTeeTime}
                            disabled={hasMultipleCourses}
                            onChange={(e) => updateValue(() => setFirstTeeTime(e.target.value))}
                        />
                    </InputGroup>

                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Last Tee Time</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>This is the
                        number of minutes between each tee time.</p>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="time"
                            placeholder="Time"
                            value={lastTeeTime}
                            disabled={hasMultipleCourses}
                            onChange={(e) => updateValue(() => setLastTeeTime(e.target.value))}
                        />
                    </InputGroup>
                </div>

                <h6>Caddie Settings</h6>
                <div style={{width: '100%', marginBottom: '2.5em'}}>
                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Caddie Unavailability Window</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>This is how
                        long your caddie will be unavailable for once they are placed on a booking. This time should
                        equate to your pace of play for your course plus an additional rest period for your caddie.</p>
                    <Form.Control as="select"
                                  value={paceOfPlay}
                                  disabled={hasMultipleCourses}
                                  onChange={(e) => setPaceOfPlay(parseInt(e.target.value))}>
                        <option value={240}>4 hours</option>
                        <option value={270}>4.5 hours</option>
                        <option value={300}>5 hours</option>
                        <option value={330}>5.5 hours</option>
                        <option value={360}>6 hours</option>
                        <option value={390}>6.5 hours</option>
                    </Form.Control>
                </div>

                <div style={{
                    borderTop: '1px solid #ccc',
                    paddingTop: '1em'
                }}>
                    {needsUpdating && <Alert variant='warning'>
                        You have changed your timesheet settings. We will attempt to update all your future bookings
                        to the new available tee times based on these new settings.
                        <p style={{padding: 0, margin: 0}}>If you are happy with your changes, please click update
                            below.</p>
                    </Alert>}

                    {errMsg && <Alert variant='danger'>{errMsg}</Alert>}

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '1em',
                        paddingBottom: '0.75em',
                    }}>
                        {updating && <Spinner animation={'border'} />}
                        <Button
                            variant='outline-danger'
                            style={{marginRight: '0.5em'}}
                            disabled={updating}
                            onClick={() => dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))}>Cancel</Button>
                        <Button disabled={updating || hasMultipleCourses} onClick={updateClubSettings}>UPDATE</Button>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default SettingsDrawerContent;