import React, {useState} from 'react';
import {Button, Card} from "react-bootstrap";
import {toast} from "react-toastify";

interface Props {
    submit: (x: string) => void
}

const NewComment = ({submit}: Props) => {

    const [comment, setComment] = useState<string>('')

    return (
        <Card style={{ marginTop: '1em', marginBottom: '1em' }}>
            <Card.Body>
                <p>Enter a new comment:</p>
                <textarea
                    style={{
                        width: '100%'
                    }}
                    rows={5}
                    value={comment}
                    onChange={e => setComment(e.target.value)} />
            </Card.Body>

            <Card.Footer>
                <div style={{display: "flex", flexDirection: 'column', width: '100%', alignItems: 'end'}}>
                    <Button onClick={() => {
                        if (comment === "") {
                            toast.warning('Your comment contains no content. Please input text and try again.')
                            return;
                        }

                        submit(comment)
                        setComment("")
                    }}>
                        SUBMIT</Button>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default NewComment;