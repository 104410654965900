import React from "react";

import ProfileInfoTable from "../infotable/ProfileInfoTable";

interface ContactMethodRow {
    type: string
    value: string
}

interface Props {
    contactMethods: ContactMethodRow[]
}

const ContactMethodsTable = ({contactMethods}: Props) => {

    return (
        <ProfileInfoTable
            header={<>
                <td>
                    <h3>CONTACT METHODS</h3>
                </td>
                <td />
            </>}
            body={<>
                {contactMethods && contactMethods.map(contactMethod => {
                    return <tr>
                        <td>{contactMethod.type}</td>
                        <td>{contactMethod.value}</td>
                    </tr>
                })}
            </>}
        />
    )

}

export default ContactMethodsTable;