import React, {useState} from "react";
import {TourMasterSelector} from "../parts/tourmaster/TourMasterSelector";
import {Container} from "react-bootstrap";
import NewMessageForm from "../parts/tourmaster/communication/form/NewCommunicationForm";

interface Props {

}

export const TourMasterCommunication = ({}: Props) => {

    const [tourId, setTourId] = useState<string>();

    return (
        <Container>
            <h1 className={"pageHeading"}>Messages</h1>

            {!tourId && <TourMasterSelector updateTourId={setTourId} />}
            {tourId && <>
                <NewMessageForm tourId={tourId} />
            </>}
        </Container>
    )

}
