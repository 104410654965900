import ApiClient from "../../../ApiClient";
import {logErrorFromApi} from "../../Utils";
import moment from "moment";

export interface ProfileBooking {
    slotId: string
    bookingId: string
    course?: string
    title?: string
    teeTime: string
    status: string
    slotType: string
    player: string
    rejectReason: string
    assignedToBookingTime: string
    cancelledTime?: string
}

export const ProfileBookingService = () => {

    const get = (clubId: string, caddieId: string, from: Date, to: Date): Promise<ProfileBooking[] | void> => {
        let fromDate = moment(from).format('YYYY-MM-DD')
        let toDate = moment(to).format('YYYY-MM-DD')

        return ApiClient.getInstance().get<ProfileBooking[]>(
            `/profile-bookings?forClub=${clubId}&forCaddie=${caddieId}&fromDate=${fromDate}&toDate=${toDate}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        getAll: get
    }
}

