import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";
import {ClubCaddie, PagedApiResponse, User, UserAvailability} from "../ApiDomain";
import {toast} from "react-toastify";

export interface CaddieComment {
    id: string
    comment: string
    caddie: string

    submitted: User
    submittedOn: Date
}

export const CaddieService = () => {

    const getComments = (id: string): Promise<CaddieComment[] | void> => {
        return ApiClient.getInstance().get<CaddieComment[]>(`/caddies/${id}/comments`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const createComment = (id: string, comment: string): Promise<CaddieComment | void> => {
        return ApiClient.getInstance().post<CaddieComment>(`/caddies/${id}/comments`, {
            caddieId: id,
            comment: comment
        })
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const deleteComment = (id: string, commentId: string): Promise<boolean | void> => {
        return ApiClient.getInstance().delete<void>(`/caddies/${id}/comments/${commentId}`)
            .then(() => {
                toast.success('Comment deleted successfully.')
            })
            .catch((err) => logErrorFromApi(err));
    }

    const searchCaddieAvailabilities = (clubId: string, dateTime: string, searchTerm: string): Promise<PagedApiResponse<UserAvailability[]> | void> => {
        let date = dateTime.split('T')[0]
        return ApiClient.getInstance().get<PagedApiResponse<UserAvailability[]>>(`/club-availabilities?forClub=${clubId}&forDate=${date}&forSearch=${searchTerm}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        getCaddieComments: getComments,
        createCaddieComment: createComment,
        deleteCaddieComment: deleteComment,
        searchCaddieAvailabilities: searchCaddieAvailabilities,
    }
}
