import React, {ReactElement} from "react";
import {ClubBooking, Course, TeeTimeResource, TimesheetResource} from "../ApiDomain";

export const DateSchedulerService = () => {

    const ROW_COLOURS = ['white', '#f1']

    const buildTimeSheet = (
        courseRowBuilder: (
            c: Course,
            teeTime: string,
            displayTeeTime: boolean,
            rowBackgroundColour: string,
            booking?: ClubBooking,
            teeTimesInPool?: number,
            endTeeTime?: string) => ReactElement,
        noCourseRowBuilder: (
            teeTime: string,
            rowBackgroundColour: string,
            booking?: ClubBooking,
            teeTimesInPool?: number,
            endTeeTime?: string) => ReactElement,
        timesheet: TimesheetResource,
        courseIdFilter: string|undefined,
        hideEmptyTeeTimes: boolean,
        courses: Course[]): ReactElement | ReactElement[] => {
        if (!timesheet) {
            return <tr>
                <td>Loading...</td>
            </tr>;
        }

        let rows: ReactElement[] = []
        let courseIdToCourse = new Map<string, Course>();
        courses.forEach(c => courseIdToCourse.set(c.id, c))
        let currentRowColour = 1;
        const teeTimes = new Set<string>();

        timesheet.teeTimes
            .filter(resource => {
                if (courses.length > 1 && courseIdFilter) {
                    return resource.courseId === courseIdFilter
                }
                return true;
            })
            .forEach((resource: TeeTimeResource) => {
            currentRowColour = currentRowColour === 1 ? 0 : 1;

            if (hideEmptyTeeTimes) {
                if (!resource.booking) {
                    return <></>;
                }
            }

            if (courses.length >= 1) {
                if (!resource.courseId) {
                    return <></>;
                }
                let course = courseIdToCourse.get(resource.courseId)

                if (!course) {
                    return <></>;
                }
                let teeTimeAlreadyDisplayed = teeTimes.has(resource.teeTime)
                teeTimes.add(resource.teeTime)

                let row = courseRowBuilder(
                    course,
                    resource.teeTime,
                    !teeTimeAlreadyDisplayed,
                    ROW_COLOURS[currentRowColour],
                    resource.booking,
                    resource.teeTimesInPool,
                    resource.endTeeTime)
                if (row) {
                    rows.push(row)
                }
            } else {
                let row = noCourseRowBuilder(
                    resource.teeTime,
                    ROW_COLOURS[currentRowColour],
                    resource.booking,
                    resource.teeTimesInPool,
                    resource.endTeeTime)
                if (row) {
                    rows.push(row)
                }
            }
        })

        if (rows.length === 0) {
            rows.push(
                <>
                    <td colSpan={7} className='bg-white pb-xl-5'>
                        <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                            <p className='text-center'>You currently have no bookings for today.</p>
                            <img alt="no-time-sheet" src={'/no-time-sheet.png'} className="d-block mx-auto img-fluid w-25" />
                        </div>
                    </td>
                </>
            )
        }

        return rows
    }

    return {
        buildTimeSheet: buildTimeSheet
    }
}

