import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";
import {PagedResource} from "../ApiDomain";

export interface Event {
    id: string
    name: string
    description: string
    splashImageS3Location: string
    prizeFund: string
}

export interface PagedEvent extends PagedResource {
    resource: Event[]
}

export const EventService = () => {

    const getForTourCurrentSeason: (tourId: string) => Promise<PagedEvent | void> = (tourId: string) => {
        return ApiClient.getInstance().get<PagedEvent>(`/events?tourId=${tourId}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        getTourEventsForCurrentSeason: getForTourCurrentSeason
    }
}

