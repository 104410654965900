import React from "react";
import {Col, Image, Row} from "react-bootstrap";

import './ChoiceContent.scss';
import avatar from "../../images/golfClubCaddie.png";
import {handicaddieGreen} from "../../services/Utils";
import appStore from "../../images/DownloadOnAppStore.svg";
import playStore from "../../images/DownloadOnGooglePlay.png";

interface Props {

}

export const ChoiceContent = ({}: Props) => {

    return <>
        <div className="choiceContent_container">
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
                <Image src={avatar} style={{height: 250}}/>
            </div>

            <div style={{textAlign: "center"}}>
                <h1 style={{color: handicaddieGreen}}>
                    Thank You!
                </h1>

                <p>That's your account created. We'll be in touch when we've approved your account to be a caddie master.</p>
            </div>

            <p style={{ textAlign: 'center' }}>Are you a caddie? Download and login on the caddie app: </p>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Col xs={12} md={"auto"}>
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <a href="https://apps.apple.com/us/app/id1621131417">
                            <Image src={appStore} style={{ height: 60, cursor: 'pointer' }}/>
                        </a>
                    </div>
                </Col>
                <Col xs={12} md={"auto"}>
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <a href="https://play.google.com/store/apps/details?id=com.handicaddie.caddie">
                            <Image src={playStore} style={{ height: 60, cursor: 'pointer' }} />
                        </a>
                    </div>
                </Col>
            </Row>
        </div>
    </>
}