import React, {useEffect, useState} from "react";
import {TourMasterSelector} from "../parts/tourmaster/TourMasterSelector";
import UploadDocuments from "../parts/tourmaster/communication/documents/UploadDocuments";
import {Container} from "react-bootstrap";
import DocumentsTable from "../parts/tourmaster/communication/documents/DocumentsTable";
import {Document, DocumentService} from "../services/documents/DocumentService";

interface Props {

}

export const TourMasterDocuments = ({}: Props) => {

    const { getDocuments, deleteDocument, getDocumentContent } = DocumentService();

    const [tourId, setTourId] = useState<string>();

    const [loading, setLoading] = useState<boolean>(false);
    const [tourDocuments, setTourDocuments] = useState<Document[]>([]);

    useEffect(() => {
        loadDocuments();
    }, [tourId])

    const loadDocuments = async () => {
        if (!tourId) {
            return;
        }

        setLoading(true);
        try {
            const docs = await getDocuments(tourId);
            if (docs) {
                setTourDocuments(docs.resource);
            }
        } finally {
            setLoading(false);
        }
    }

    const downloadDocument = async (doc: Document) => {
        let downloadUrl = await getDocumentContent(doc.id);
        if (downloadUrl) {
            window.open(downloadUrl, '_self');
        }
    }

    const deleteAndReloadDocuments = async (docId: string) => {
        await deleteDocument(docId)
        loadDocuments();
    }

    return (
        <Container>
            <h1 className={"pageHeading"}>Documents</h1>

            {!tourId && <TourMasterSelector updateTourId={setTourId} />}
            {tourId && <>
                <UploadDocuments tourId={tourId} refreshDocumentsTable={loadDocuments} />
                <DocumentsTable
                    tourDocuments={tourDocuments}
                    loading={loading}
                    downloadDocument={downloadDocument}
                    deleteDocument={deleteAndReloadDocuments}
                />
            </>}
        </Container>
    )
}
