import React from "react";
import AvailabilitySelection from "../parts/caddie/availability/AvailabilitySelection";

interface Props {

}

const Availability = ({}: Props) => {

    return (
        <>
            <h1 className={"pageHeading"}>Availability</h1>
            <AvailabilitySelection />
        </>
    )

}

export default Availability;