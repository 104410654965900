import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";

export interface ConnectedAccount {
    id?: string
    sourceId: string
    sourceType: string
    connectedBy: string
    canAcceptPayments: boolean
}

export interface ConnectedAccountConnection {
    redirectUrl?: string
}

export interface CreateConnectedAccountRequest {
    sourceId: string
    sourceType: string
}

export const ConnectedAccountsService = () => {

    const getForSource: (sourceId: string, sourceType: string) => Promise<ConnectedAccount | void> = (
        sourceId: string, sourceType: string) => {
        return ApiClient.getInstance().get<ConnectedAccount>(`/connectedaccounts?sourceId=${sourceId}&sourceType=${sourceType}`)
            .then((resp) => resp)
            .catch((err) => {
                console.log('Connected account not found.');
                return;
            });
    }

    const create: (connectedAccount: CreateConnectedAccountRequest) => Promise<ConnectedAccount | void> = (connectedAccount: CreateConnectedAccountRequest) => {
        return ApiClient.getInstance().post<ConnectedAccount>('/connectedaccounts', connectedAccount)
            .then((resp) => resp)
            .catch((err) => {
                console.log('Connected account not found.');
                return;
            });
    }

    const createConnection: (connectedAccountId: string) => Promise<ConnectedAccountConnection | void> = (connectedAccountId: string) => {
        return ApiClient.getInstance().post<ConnectedAccountConnection>(`/connectedaccounts/${connectedAccountId}/connection`)
            .then((resp) => resp)
            .catch(logErrorFromApi);
    }

    return {
        getConnectedAccountForSource: getForSource,
        createConnectedAccount: create,
        createConnectedAccountConnection: createConnection
    }
}

