import React from 'react';

import './DragAndDropArea.scss';
import {Button} from "react-bootstrap";

interface Props {
    files: File[]
    addFiles: (files: File[]) => void
}

const DragAndDropArea = ({files, addFiles}: Props) => {

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick: (e: React.MouseEvent) => void = event => {
        if (!hiddenFileInput || !hiddenFileInput.current) {
            return;
        }

        hiddenFileInput.current.click();
    };

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = event => {
        if (!event.target.files) {
            return;
        }
        const selectedFiles: File[] = [];
        const fileList: FileList = event.target.files;
        for (let i = 0; i < fileList.length; i++) {
            selectedFiles.push(fileList[i]);
        }

        addNewFiles(selectedFiles);
    };

    const handleDrop: (e: React.DragEvent<HTMLDivElement>) => void = e => {
        e.preventDefault();
        e.stopPropagation();
        let droppedFiles: File[] = [...e.dataTransfer.files];

        if (droppedFiles && droppedFiles.length > 0) {
            addNewFiles(droppedFiles);
            e.dataTransfer.clearData();
        }
    };

    const addNewFiles: (newFiles: File[]) => void = (newFiles: File[]) => {
        const existingFiles = files.map(f => f.name)
        newFiles = newFiles.filter(f => !existingFiles.includes(f.name))

        addFiles(newFiles);
    }

    return (
        <>
            <div className={'drag-drop-zone'} onDrop={e => handleDrop(e)}>
                <Button onClick={handleClick}>Choose File</Button>
                <input
                    multiple
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{display: 'none'}}
                />
                <p>or drag file in here</p>
            </div>
        </>
    );
}

export default DragAndDropArea;