import React, {useEffect, useState} from 'react';
import {PendingCaddieApplicationsFeed} from "./PendingCaddieApplicationsFeed";
import {useStore} from "../../../services/state/useStore";
import {useApi} from "../../../services/useApi";
import {TourCaddie, TourCaddieApplicationSummary} from "../../../services/ApiDomain";
import {CaddieTable} from "./table/CaddieTable";
import {logError} from "../../../services/Utils";

type Props = {
    tourId?: string
    showCaddieApplication: (id: string, name: string) => void
}

export const TourMasterCaddieFeeds = ({tourId, showCaddieApplication}: Props) => {

    const { currentUser } = useStore();
    const { getTourCaddieApplications, getTourCaddies } = useApi();

    const [pendingCaddies, setPendingCaddies] = useState<TourCaddieApplicationSummary[]>([]);
    const [caddies, setCaddies] = useState<TourCaddie[]>();

    useEffect(() => {
        refreshCaddies();
    }, [tourId]);

    const refreshCaddies = () => {
        if (!currentUser) {
            return;
        }
        refreshPendingCaddieList();
        refreshCaddieTable();
    }

    const refreshPendingCaddieList = () => {
        if (tourId === undefined) {
            return;
        }
        let list: TourCaddieApplicationSummary[] = [];

        getTourCaddieApplications(tourId)
            .then(async (caddies) => {
                for (const caddie of await caddies) {
                    list.push(caddie);
                }
                setPendingCaddies(list);
            }).catch((err) => {
            logError('Error when retrieving pending caddie applications.', err);
        });
    }

    const refreshCaddieTable = () => {
        if (tourId === undefined) {
            return;
        }
        let list: TourCaddie[] = [];

        getTourCaddies(tourId)
            .then(async (caddies) => {
                for (const caddie of await caddies) {
                    list.push(caddie);
                }
                setCaddies(list);
            })
            .catch((err) => {
                logError('Error when retrieving tour caddies.', err);
            });
    }

    return (
        <>
            <h3 className="pageSubHeading">Pending Caddie Applications</h3>
            <PendingCaddieApplicationsFeed
                caddies={pendingCaddies}
                refreshCaddies={refreshCaddies}
                showCaddieApplication={showCaddieApplication}
            />

            <h3 className="pageSubHeading" style={{ marginTop: '3em' }}>Caddies</h3>
            <CaddieTable caddies={caddies}/>
        </>
    )
}