import React from 'react';
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {CheckCircleRounded} from "@material-ui/icons";

import './PlanCard.scss';
import {TourPass} from "../../../../../../services/tourpass/TourPassService";
import {MONTHS} from "../../../../../../services/Utils";

interface Props {
    loading: boolean
    purchasing: boolean
    tourPass: TourPass
    paid: boolean
    purchase: (id?: string) => void
}

const PlanCard = ({loading, purchasing, purchase, tourPass, paid}: Props) => {

    const validTo = new Date(tourPass.validTo);

    const dateSuffix = (date: number) => {
        if (date > 3 && date < 21)
            return 'th';
        switch (date % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    return (
        <Card style={{minWidth: '250px', margin: '1.5em'}}>
            <Card.Body>
                <Row>
                    <Col xs={8}>
                        <h3 className="planCard_heading_season">{tourPass.name}</h3>
                    </Col>
                    <Col xs={4}>
                        {paid && <CheckCircleRounded className={"planCard_paidIcon"} />}
                    </Col>
                </Row>
                <Row>
                    <Col xs={10}>
                        <h3 className="planCard_heading_validHeading">Valid until </h3>
                        <span>{validTo.getDate()}{dateSuffix(validTo.getDate())}, {MONTHS[validTo.getMonth()]} {validTo.getFullYear()}</span>
                    </Col>
                    <Col xs={2}>
                        {paid && <CheckCircleRounded className={"planCard_paidIcon"} />}
                    </Col>
                </Row>

                <br />
                <h3 className="planCard_price">£{tourPass.fee / 100}</h3>
                <br />
                <Row style={{justifyContent: 'center'}}>
                    {paid && <Button variant="outline-primary" className="planCard_payBtn" disabled={true}>PAID</Button>}
                    {!paid && !purchasing &&
                    <Button
                        disabled={loading}
                        className="planCard_payBtn"
                        onClick={() => purchase(tourPass.id)}>
                        Pay
                    </Button>}
                    {!paid && purchasing && <Spinner animation={"border"} />}
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PlanCard;