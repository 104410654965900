import './ProfileBookings.scss';

import React, {useEffect, useState} from "react";
import {logErrorFromApi} from "../../../../services/Utils";
import {ProfileBooking, ProfileBookingService} from "../../../../services/profile/booking/ProfileBookingService";
import Calendar from "react-calendar";
import {Table} from "react-bootstrap";
import moment from "moment";

enum Labels {
    THIS_WEEK = 'This week',
    LAST_7_DAYS = 'Last 7 days',
    LAST_WEEK = 'Last week',
    THIS_MONTH = 'This month',
    LAST_31_DAYS = 'Last 31 days',
    LAST_MONTH = 'Last month',
    THIS_YEAR = 'This year',
    LAST_365_DAYS = 'Last 365 days',
    LAST_YEAR = 'Last year'
}

interface Props {
    caddieId: string
    clubId: string
    clubName: string
}

export const ProfileBookings = ({caddieId, clubId, clubName}: Props) => {

    const { getAll } = ProfileBookingService();

    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
    const [selectedLabel, setSelectedLabel] = useState<string>();
    const [bookings, setBookings] = useState<ProfileBooking[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [hasCourses, setHasCourses] = useState<boolean>(false);
    const [hasSlotTypes, setHasSlotTypes] = useState<boolean>(false);
    const [hasPlayers, setHasPlayers] = useState<boolean>(false);

    useEffect(() => {
        loadBookings()
            .catch(err => logErrorFromApi(err));
    }, [caddieId, clubId, selectedDates]);

    const loadBookings = async () => {
        if (!caddieId || !clubId || !clubName || selectedDates.length !== 2) {
            return;
        }

        try {
            setLoading(true);
            let b = await getAll(
                clubId,
                caddieId,
                selectedDates[0],
                selectedDates[1]);
            if (!b) {
                return;
            }

            setBookings(b);
            setHasSlotTypes(b.some(b => b.slotType))
            setHasPlayers(b.some(b => b.player))
            setHasCourses(b.some(b => b.course))
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (newDates: Date | Date[]) => {
        let newSelectedDates: Date[] = []
        newSelectedDates = Array.isArray(newDates) ?
            newDates : [newDates, newDates];

        setSelectedLabel(undefined)
        setSelectedDates(newSelectedDates)
        setShowCalendar(false)
    };

    const labelClick = (label: string) => {
        let to = new Date()
        let from = new Date()

        let year = to.getFullYear()
        let mnth = to.getMonth()
        let day = to.getDay()

        to.getDay()

        switch (label) {
            case Labels.THIS_WEEK:
                from.setTime(from.getTime() - ((day - 1) * 24 * 60 * 60 * 1000))
                to.setTime(from.getTime() + (7 * 24 * 60 * 60 * 1000))
                break;
            case Labels.LAST_7_DAYS:
                from.setTime(from.getTime() - (7 * 24 * 60 * 60 * 1000))
                break;
            case Labels.LAST_WEEK:
                from.setTime(from.getTime() - ((day - 1 + 7) * 24 * 60 * 60 * 1000))
                to.setTime(from.getTime() + (7 * 24 * 60 * 60 * 1000))
                break;
            case Labels.THIS_MONTH:
                from.setFullYear(year, mnth, 1)
                to.setFullYear(year, mnth + 1, 0)
                break;
            case Labels.LAST_31_DAYS:
                from.setTime(from.getTime() - (31 * 24 * 60 * 60 * 1000))
                break;
            case Labels.LAST_MONTH:
                from.setFullYear(from.getFullYear(), mnth - 1, 1)
                to.setFullYear(to.getFullYear(), mnth, 0)
                break;
            case Labels.THIS_YEAR:
                from.setFullYear(year, 0, 1)
                to.setFullYear(year + 1, 0, 0)
                break;
            case Labels.LAST_365_DAYS:
                from.setTime(from.getTime() - (365 * 24 * 60 * 60 * 1000))
                break;
            case Labels.LAST_YEAR:
                from.setFullYear(year - 1, 0, 1)
                to.setFullYear(year - 1, 11, 31)
                break;
        }
        setSelectedLabel(label)
        setSelectedDates([from, to])
        setShowCalendar(false)
    };

    return (
        <>
            <div className={'bookingsTable_dateRangeContainer'} onClick={() => setShowCalendar(!showCalendar)}>
                <span style={{ paddingRight: '1em', lineHeight: '2.5em', textAlign: 'center' }}>Caddie Bookings</span>

                <div className={"bookingsTable_dateContainer"}>
                    {selectedLabel && <span className={"bookingsTable_dateContainer_badge"}>
                        {selectedLabel}
                    </span>}

                    <span className={"bookingsTable_dateContainer_dates"}>
                        {moment(selectedDates[0]).format('dddd Do MMMM YYYY')}
                        <span style={{ paddingRight: '1em', paddingLeft: '1em' }}>-</span>
                        {moment(selectedDates[1]).format('dddd Do MMMM YYYY')}
                    </span>
                </div>
            </div>
            <div style={{ display: showCalendar ? 'table' : 'none' }} className={"bookingsTable_calendarContainer"}>
                <div className={"bookingsTable_calendarLabelsContainer"}>
                    <ul className={"bookingsTable_calendarLabelsList"}>
                        {Object.values(Labels).map(l => <li onClick={() => labelClick(l)}>
                            <span>{l}</span>
                        </li>)}
                    </ul>
                </div>
                <Calendar
                    className={"bookingsTable_calendar"}
                    selectRange
                    value={selectedDates}
                    onChange={(e) => handleChange(e)}
                />
            </div>
            <Table borderless striped>
                <thead className="bookingsTable_header">
                <td>Tee Time</td>
                <td>Title</td>
                {hasCourses && <td>Course</td>}
                {hasSlotTypes && <td>Slot Type</td>}
                {hasPlayers && <td>Player</td>}
                <td>Status</td>
                <td>Assignment Time</td>
                <td>Cancelled Time</td>
                <td>Reject Reason</td>
                </thead>
                <tbody className={"bookingsTable_bodyContainer"}>
                {!loading && bookings && bookings.map(booking => {
                    return <tr>
                        <td>{moment(booking.teeTime).format('dddd Do MMMM, HH:mm')}</td>
                        <td>{booking.title}</td>
                        {hasCourses && <td>{booking.course}</td>}
                        {hasSlotTypes && <td>{booking.slotType}</td>}
                        {hasPlayers && <td>{booking.player}</td>}
                        <td>{booking.status}</td>
                        <td>{booking.assignedToBookingTime && moment(booking.assignedToBookingTime).format('dddd Do MMMM, HH:mm')}</td>
                        <td>{booking.cancelledTime && moment(booking.cancelledTime).format('dddd Do MMMM, HH:mm')}</td>
                        <td>{booking.rejectReason}</td>
                    </tr>
                })}
                {!loading && bookings && bookings.length === 0 && <tr>
                    <td>No bookings.</td>
                    <td />
                    {hasCourses && <td></td>}
                    {hasSlotTypes && <td></td>}
                    {hasPlayers && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>}
                {loading && <tr>
                    <td>Loading...</td>
                    <td />
                    {hasCourses && <td></td>}
                    {hasSlotTypes && <td></td>}
                    {hasPlayers && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>}
                </tbody>
            </Table>
        </>
    )
}