import React, {useRef, useState} from 'react';
import {Badge, Col, Overlay, Row, Tooltip} from "react-bootstrap";
import {WarningRounded} from "@material-ui/icons";

type BookingCaddieBadgeProps = {
    id: string
    name: string
    date: string
    status: string
    onClick: (id: string, date: string) => void
}

const BookingCaddieBadge = ({id, name, date, status, onClick}: BookingCaddieBadgeProps) => {

    const [showExplanation, setShowExplanation] = useState(false);
    const tooltipTarget = useRef(null);

    let caddieName = name
    let variant = "warning"
    let explanation = 'Waiting for ' + name + ' to accept. Click to view replacements.'

    if (!id) {
        caddieName = 'Click to select a caddie'
        explanation = "You haven't selected a caddie for this slot yet."
        variant = 'secondary'
    } else if (status === 'ACCEPTED') {
        variant = "success"
        explanation = name + ' has accepted this job. Click to view replacements.'
    } else if (status === 'REJECTED') {
        variant = "danger"
        explanation = name + ' has rejected this job. Click to view replacements.'
    }

    return (
        <>
            <Col>
                <Row>
                    <h4>
                        <Badge
                            variant={variant}
                            ref={tooltipTarget}
                            onClick={() => onClick(id, date)}
                            onMouseOver={() => setShowExplanation(!showExplanation)}
                            onMouseLeave={() => setShowExplanation(false)}>
                            {caddieName}
                        </Badge>
                    </h4>
                    { status === 'REJECTED' && <WarningRounded color='error' fontSize='large' /> }
                </Row>
            </Col>
            <Overlay target={tooltipTarget.current} show={showExplanation} placement="right">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {explanation}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}

export default BookingCaddieBadge;