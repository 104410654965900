import React from 'react';
import {Container, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import TourPassesHeader from "../parts/caddieontour/tours/let/passes/TourPassesHeader";
import TourAvailablePasses from "../parts/caddieontour/tours/let/passes/TourAvailablePasses";

interface Props {

}

const TourPasses = ({ }: Props) => {

    const { tourId } = useParams();

    return (
        <Container>
            <h3 className="pageHeading">Tour Passes</h3>
            {!tourId && <Spinner animation={"border"} />}
            {tourId &&
                <div style={{paddingBottom: '10em'}}>
                    <TourPassesHeader />
                    <TourAvailablePasses tourId={tourId} />
                </div>
            }

        </Container>
    )
}

export default TourPasses;