import React from 'react';
import SchedulerHeader from "./SchedulerHeader";
import DateScheduler from "./DateScheduler";
import SideDrawer from "./sidedrawer/SideDrawer";
import {DrawerStatus} from "../../../services/store/reducers/scheduler/domain";
import {handicaddieSelector} from "../../../services/store/asyncThunk";

const DesktopCaddieMasterScheduler = (props: {
    showPublishScheduleModal: (x: boolean) => void
}) => {

    const { drawerStatus } = handicaddieSelector(state => state.scheduler)

    return <div style={{display: "flex", flexDirection: "row", height: '100%'}}>
        <div style={{
            width: drawerStatus === DrawerStatus.OPEN ? `${drawerStatus}%` : `calc(100% - 4em)`,
            padding: "1em 1em 0 1em",
            display: "flex",
            flexDirection: "column"
        }}>
            {/** zindex is required below so that the club dropdown displays over the table.... weird **/}
            <div style={{ marginBottom: '1em', zIndex: 1000 }}>
                <SchedulerHeader showPublishScheduleModal={props.showPublishScheduleModal} />
            </div>
            <div>
                <DateScheduler />
            </div>
        </div>

        <SideDrawer />
    </div>;
}

export default DesktopCaddieMasterScheduler;