import React from 'react';

import './caddieslot.scss'
import {Spinner} from "react-bootstrap";

type EmptyCaddieSlotProps = {
    loading: boolean
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
    className?: string
}

const EmptyCaddieSlot = ({loading, onClick, onMouseEnter, onMouseLeave, className}: EmptyCaddieSlotProps) => {

    const handleClick = () => {
        if (loading) {
            return;
        }

        onClick();
    }

    return (<div className={'caddieSlot_container'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div onClick={handleClick} className={"emptyCaddieSlot " + className}>
                {loading && <Spinner animation='border' />}
                {!loading && <svg xmlns="http://www.w3.org/2000/svg"
                     width={24}
                     height={24}
                     fill="#D9D9D9"
                     className="emptyCaddieSlot_svg"
                     viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="7" fill="black"/>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
                </svg>}
            </div>
        </div>
    )
}

export default EmptyCaddieSlot;