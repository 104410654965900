import React, {useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import ApiClient from "../ApiClient";
import ClubSelection from "../components/login/ClubSelection";
import CreateProfile from "../components/login/CreateProfile";
import EmailConfirm from "../components/login/EmailConfirm";
import Login from "../components/login/Login";
import Register from "../components/login/Register";
import {getApiError, isTokenInvalid} from "../services/Utils";
import {ToastContainer} from "react-toastify";
import PlatformApp from "./PlatformApp";
import {useStore} from "../services/state/useStore";
import ResetPasswordEmailConfirmation from "../components/login/ResetPasswordEmailConfirmation";
import ResetPassword from "../components/login/ResetPassword";
import ClubRegister from "../components/login/ClubRegister";
import {ApiError} from "../services/ApiDomain";
import {Loading} from "../pages/Loading";
import {CADDIE_CHOICE, CADDIE_WEB_NOTICE, LOADING} from "../services/PageUtils";
import {CaddieChoice} from "../pages/CaddieChoice";
import PaymentSuccessfulForApp from "../pages/PaymentSuccessfulForApp";
import PaymentUnsuccessfulForApp from "../pages/PaymentUnsuccessfulForApp";
import {CaddieAppNotice} from "../pages/CaddieAppNotice";
import {useDispatch} from "react-redux";
import {AppDispatch, startAppListening} from "../services/store/store";
import {loadUser} from "../services/store/reducers/currentUser";
import {Unsubscribe} from "@reduxjs/toolkit";
import {setupClubListeners} from "../services/store/reducers/clubs/listeners";
import {setupSchedulerListeners} from "../services/store/reducers/scheduler/listeners";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { token, clearLocalStorage } = useStore();
    const dispatch = useDispatch<AppDispatch>();

    new ApiClient((error: any) => {
        let errorResponse: ApiError = getApiError(error);
        if (errorResponse?.code === 'E0001') {
            navigate('/login');
        }
        if (errorResponse?.code === 'E1003') {
            navigate('/create');
        }
        throw error;
    }, process.env.REACT_APP_API_URL);

    useEffect(() => {
        if (!token) {
            return;
        }

        dispatch(loadUser())
    }, [token]);

    useEffect(() => {
        const subscriptions: Unsubscribe[] = [
            setupClubListeners(startAppListening),
            setupSchedulerListeners(startAppListening),
        ]

        return () => subscriptions.forEach((unsubscribe) => unsubscribe())
    }, [])

    useEffect(() => {
        if (location.pathname !== '/resetpassword' &&
            location.pathname !== '/accountconfirmation' &&
            location.pathname !== '/app/payment_successful' &&
            location.pathname !== '/app/payment_unsuccessful' &&
            !location.pathname.startsWith('/register')
        ) {
            checkToken();
        }
    }, []);

    const checkToken = () => {
        if (isTokenInvalid(token)) {
            navigate('/login')
        }
    }

    const logOut = () => {
        clearLocalStorage();
        navigate('/login')
    }

    return (
        <div style={{height: "100%"}}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path={LOADING} element={<Loading />}/>
                <Route path={CADDIE_CHOICE} element={<CaddieChoice />}/>
                <Route path={CADDIE_WEB_NOTICE} element={<CaddieAppNotice />} />
                <Route path="/accountconfirmation" element={<ResetPasswordEmailConfirmation />}/>
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/club-register" element={<ClubRegister />} />
                <Route path="/register" element={<Register />} />
                <Route path="/confirm" element={<EmailConfirm />} />
                <Route path="/create" element={<CreateProfile />} />
                <Route path="/club-select" element={<ClubSelection />} />
                <Route path="/app/payment_successful" element={<PaymentSuccessfulForApp />} />
                <Route path="/app/payment_unsuccessful" element={<PaymentUnsuccessfulForApp />} />
                <Route path="/platform/*" element={<PlatformApp logOut={logOut} />} />
                <Route index element={<PlatformApp logOut={logOut} />} />
            </Routes>

            <ToastContainer autoClose={3000} />
        </div>
    );
};

export default App;
