import React, {useState} from 'react';
import {handicaddieGreen, tertiaryBlue} from "../../../../../services/Utils";
import Avatar from "../../../../../components/Avatar";
import {DeleteOutline} from "@material-ui/icons";

interface Props {
    selected: boolean
    userId: string
    name: string
    noSmartphone?: boolean
    onClick?: () => void
}

const SelectableCaddie = ({selected, userId, name, noSmartphone, onClick}: Props) => {

    const [hover, setHover] = useState<boolean>(false)

    return (
        <div
            onClick={() => {
                if (onClick) onClick()
            }}

            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                marginRight: '1em',
                marginBottom: '0.5em',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                cursor: 'pointer'
            }}>
            {(!selected || !hover) && <Avatar
                size={'s'}
                user={userId}
                style={{
                    display: 'flex',
                    alignSelf: 'center',
                    marginRight: 0,
                    marginLeft: 0,
                    borderWidth: '5px',
                    borderStyle: 'solid',
                    borderColor: selected ? handicaddieGreen : noSmartphone ? tertiaryBlue : "grey"
                }}/>}
            {selected && hover && <div style={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'center',
                height: '5em',
                width: '5em',
                backgroundColor: '#b72323',
                borderRadius: '50%',
                opacity: 0.5
            }}>
                <DeleteOutline style={{color: 'white', verticalAlign: 'middle', fontSize: '3em', height: '1.65em'}}/>
            </div>}
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>{name}</p>
        </div>
    )
}

export default SelectableCaddie;