import React, {useEffect, useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import './PayoutDetails.scss';
import {
    ConnectedAccount,
    ConnectedAccountsService
} from "../../../../services/connectedAccounts/ConnectedAccountsService";
import {CheckCircleRounded} from "@material-ui/icons";
import {toast} from "react-toastify";
import Section from "../../../../components/section/Section";

interface Props {
    tourId: string
}

const PayoutDetails = ({tourId}: Props) => {

    const {
        getConnectedAccountForSource,
        createConnectedAccount,
        createConnectedAccountConnection } = ConnectedAccountsService();

    const [loading, setLoading] = useState<boolean>();
    const [connectedAccount, setConnectedAccount] = useState<ConnectedAccount>();

    useEffect(() => {
        loadConnectedAccount();
    }, []);

    const loadConnectedAccount = async () => {
        setLoading(true);
        try {
            const resp = await getConnectedAccountForSource(tourId, 'TOUR');
            if (resp) {
                setConnectedAccount(resp);
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    const createAccount = async () => {
        setLoading(true);
        try {
            const resp = await createConnectedAccount({
                sourceId: tourId,
                sourceType: 'TOUR'
            });
            if (resp) {
                toast.success('Payout account created');
                setConnectedAccount(resp);
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    const connectAccount = async () => {
        if (!connectedAccount || !connectedAccount.id) {
            return;
        }
        // const tab = window.open('about:blank');
        // if (!tab) {
        //     toast.error("Unable to redirect to our provider.")
        //     return;
        // }
        setLoading(true);
        try {
            const resp = await createConnectedAccountConnection(connectedAccount.id);
            if (resp && resp.redirectUrl) {
                window.location.href = resp.redirectUrl;
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Section title="Payout details">
            <p style={{ paddingBottom: '1em' }}>Please provide the details of the account where your caddie payments will arrive.</p>
            {loading && <Spinner animation="border" />}
            {!loading && !connectedAccount && <Button onClick={createAccount}>Create payout account</Button>}
            {!loading && connectedAccount && !connectedAccount.canAcceptPayments && <>
                <div style={{ marginLeft: '1em', display: 'flex', flexDirection: 'row'}}>
                    <CheckCircleRounded style={{ marginRight: '0.5em', color: 'green'}}/>
                    <p>Account created, now connect it to accept payouts. You will be redirected to our payment provider.</p>
                </div>

                <Button onClick={connectAccount}>Connect account</Button>
            </>}
            {!loading && connectedAccount && connectedAccount.canAcceptPayments && <>
                <div style={{ marginLeft: '1em', display: 'flex', flexDirection: 'row'}}>
                    <CheckCircleRounded style={{ marginRight: '0.5em', color: 'green'}}/>
                    <p>Account created and connected.</p>
                </div>
            </>}
        </Section>
    )
}

export default PayoutDetails;