import React, {useState} from "react";
import {TourPasses} from "../parts/tourmaster/settings/membershipfees/TourPasses";
import {Container} from "react-bootstrap";
import {TourMasterSelector} from "../parts/tourmaster/TourMasterSelector";
import PayoutDetails from "../parts/tourmaster/settings/payoutdetails/PayoutDetails";

interface Props {

}

export const TourMasterBilling = ({}: Props) => {

    const [tourId, setTourId] = useState<string>();

    return (
        <Container>
            <h1 className={"pageHeading"}>Billing</h1>

            { !tourId && <TourMasterSelector updateTourId={setTourId} /> }
            { tourId && <PayoutDetails tourId={tourId} /> }
            { tourId && <TourPasses tourId={tourId} /> }
        </Container>
    )

}
