import React from 'react';
import {Card, CardDeck} from "react-bootstrap";
import {PendingTourCaddieApplicationCard} from "./cards/PendingTourCaddieApplicationCard";
import {TourCaddieApplicationSummary} from "../../../services/ApiDomain";

type Props = {
    caddies: TourCaddieApplicationSummary[]
    refreshCaddies: () => void,
    showCaddieApplication: (id: string, name: string) => void
}

export const PendingCaddieApplicationsFeed = ({caddies, refreshCaddies, showCaddieApplication}: Props) => {

    return (
        <CardDeck>
            {caddies && caddies.map((caddie) => {
                return <PendingTourCaddieApplicationCard
                    applicationId={caddie.id}
                    tourId={caddie.tourId}
                    userId={caddie.userId}
                    name={caddie.displayName}
                    contactConsent={caddie.contactConsent}
                    refreshCaddies={refreshCaddies}
                    showCaddieApplication={showCaddieApplication}
                />
            })}
            {(!caddies || caddies.length === 0) && <Card><Card.Body>No pending caddie applications to review.</Card.Body></Card>}
        </CardDeck>
    );
}
