import React, {useEffect} from "react";
import {NavigationBar} from "../components/navbar/NavigationBar";
import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import background from "../components/login/background-1.jpg";
import {useStore} from "../services/state/useStore";
import {useNavigate} from "react-router-dom";
import {buildUrl, notLoaded, PLATFORM_HOME, redirectPage} from "../services/PageUtils";

interface Props {
}

export const Loading = ({}: Props) => {

    const {
        token,
        userLoaded,
        tourCaddiesLoaded,
        caddiesLoaded,
        tourMastersLoaded,
        caddieMastersLoaded,
        currentUser,
        tourCaddies,
        caddies,
        tourMasters,
        caddieMasters
    } = useStore();
    const navigation = useNavigate();

    useEffect(() => {
        if (notLoaded(userLoaded, caddiesLoaded, caddieMastersLoaded)) {
            return;
        }

        const redirect = redirectPage(
            token, userLoaded, caddiesLoaded, tourCaddiesLoaded,
            caddieMastersLoaded, tourMastersLoaded, currentUser,
            caddies, tourCaddies, caddieMasters, tourMasters
        );

        if (redirect) {
            navigation(redirect);
        } else {
            navigation(buildUrl(PLATFORM_HOME));
        }
    }, [userLoaded, caddiesLoaded, caddieMastersLoaded, currentUser, caddies, caddieMasters]);

    return <>
        <NavigationBar />
        <Container
            className={"contentContainer"}
            style={{
                backgroundImage: `url(${background})`,
                height: "calc(100vh - 76px)",
                marginTop: "0px",
            }}
            fluid={true}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        minWidth: "50%",
                        width: "auto",
                        height: "auto",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                >
                    <Row>
                        <Image
                            src={"./logo.svg"}
                            className="card_menu_img"
                            style={{ margin: "auto", height: "100px" }}
                        />
                    </Row>
                    <Col style={{display: 'flex', padding: '5em', justifyContent: 'center'}}>
                        <Spinner animation="border" />
                    </Col>
                </div>
            </div>
        </Container>
    </>
}