import React from "react";
import ContactMethodsTable from "./contactmethods/ContactMethodsTable";
import UserBioTable from "./userbio/UserBioTable";

interface Props {
    email?: string,
    phoneNumber?: string,
    bio?: string
}

export const ProfileInfo = ({email, phoneNumber, bio}: Props) => {

    const contactMethods = [];

    if (email) {
        contactMethods.push({
            "type": "Email",
            "value": email
        })
    }

    if (phoneNumber) {
        contactMethods.push({
            "type": "Phone Number",
            "value": phoneNumber
        })
    }

    return <>
        {bio && <UserBioTable bio={bio} />}
        <ContactMethodsTable contactMethods={contactMethods} />
    </>
}