import React from 'react';
import {CheckCircleRounded} from "@material-ui/icons";
import {Container} from "react-bootstrap";
import background from "../components/login/background-1.jpg";

interface Props {

}

const PaymentSuccessfulForApp = ({}: Props) => {

    return (
        <Container
            className={"contentContainer"}
            style={{
                backgroundImage: `url(${background})`,
                height: "calc(100vh)",
                marginTop: "0px",
                backgroundSize: "cover",
            }}
            fluid={true}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        minWidth: "50%",
                        width: "auto",
                        height: "auto",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                >
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <CheckCircleRounded
                            style={{fontSize: '15em', color: '#1db954'}}
                        />
                    </div>
                    <h3 style={{ textAlign: 'center' }}>Payment successful.</h3>
                    <p style={{ textAlign: 'center' }}>Please close this window to go back to the Handicaddie app.</p>
                </div>
            </div>
        </Container>
    )
}

export default PaymentSuccessfulForApp;