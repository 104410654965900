import React, {useEffect, useState} from 'react';
import {handicaddieGreen, logErrorFromApi} from "../../../../services/Utils";
import Comment from "../../../../components/Comment";
import {toast} from "react-toastify";
import NewComment from "../../../../components/EnterNewComment";
import {Card} from "react-bootstrap";
import {CaddieComment, CaddieService} from "../../../../services/caddie/CaddieService";

interface Props {
    caddieId: string
}

const CaddieComments = ({ caddieId }: Props) => {

    const { getCaddieComments, createCaddieComment, deleteCaddieComment } = CaddieService();

    const [comments, setComments] = useState<CaddieComment[]>([])

    useEffect(() => {
        loadComments(caddieId)
    }, [caddieId]);

    const loadComments = async (id: string) => {
        let comments = await getCaddieComments(id)
            .catch(logErrorFromApi)
        if (comments) {
            setComments(comments)
        }
    }

    const submitComment = async (comment: string) => {
        createCaddieComment(caddieId, comment)
            .then((r) => {
                if (r) {
                    toast.success('Comment saved.')
                }
            })
            .catch(logErrorFromApi)
            .finally(() => loadComments(caddieId))
    }

    const handleDelete = async (commentId: string) => {
        deleteCaddieComment(caddieId, commentId)
            .then((r) => {
                if (r) {
                    toast.success('Comment deleted.')
                }
            })
            .catch(logErrorFromApi)
            .finally(() => loadComments(caddieId))
    }

    return (
        <>
            <NewComment submit={submitComment} />

            <div style={{
                backgroundColor: handicaddieGreen,
                color: 'white',
                padding: '1em'
            }}>
                <h3 style={{
                    fontSize: '1.3em',
                    margin: 0,
                    padding: 0
                }}>COMMENTS</h3>
            </div>

            {comments.map(c => <Comment
                comment={c.comment}
                submittedBy={c.submitted}
                submittedOn={c.submittedOn}
                onDelete={() => handleDelete(c.id)}
            />)}
            {comments && comments.length === 0 && <Card><Card.Body>No comments.</Card.Body></Card>}
        </>

    )
}

export default CaddieComments;