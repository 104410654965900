import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Avatar from "../../../../../components/Avatar";

import './MemberProfileHeader.scss';

interface Props {
    userId: string
    displayName: string | undefined
}

const MemberProfileHeader = ({userId, displayName}: Props) => {

    return (
        <div className="userProfileHeader_container">
            <Container className={"contentContainer"} fluid="lg">
                <Row>
                    <Col xs={12} lg={4}>
                        <Avatar
                            style={{
                                display: 'flex',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                marginBottom: '15px',
                            }}
                            user={userId}
                            size={'lg'}
                        />
                    </Col>
                    <Col xs={12} lg={8}>
                        <h2>{displayName}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default MemberProfileHeader;