import React, {useState} from "react";
import {TourMasterCaddieFeeds} from "../parts/tourmaster/caddies/TourMasterCaddieFeeds";
import {CaddieApplicationModal} from "../parts/tourmaster/caddies/CaddieApplicationModal";
import {Container} from "react-bootstrap";
import {TourMasterSelector} from "../parts/tourmaster/TourMasterSelector";

interface Props {

}

export const TourMasterCaddies = ({}: Props) => {

    const [tourId, setTourId] = useState<string>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [applicationId, setApplicationId] = useState<string>("");
    const [caddieName, setCaddieName] = useState<string>("");

    const handleShowModal = (id: string, name: string) => {
        setCaddieName(name);
        setApplicationId(id);
        setShowModal(true);
    }

    return (
        <Container>
            <h1 className={"pageHeading"}>Caddies</h1>

            {!tourId && <TourMasterSelector updateTourId={setTourId} />}
            {tourId && <>
                <TourMasterCaddieFeeds
                    tourId={tourId}
                    showCaddieApplication={handleShowModal}
                />
                <CaddieApplicationModal
                    tourId={tourId}
                    applicationId={applicationId}
                    show={showModal}
                    closeModal={() => setShowModal(false)}
                    applicatorName={caddieName}
                />
            </>}

        </Container>
    )

}
