import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";

export interface Communication {
    id: string
    content: string
    documents: string[]
    audience: CommunicationAudience
    audienceId: string
    status: string
}

export enum CommunicationAudience {
    APPROVED_TOUR_CADDIES = 'APPROVED_TOUR_CADDIES',
    VALID_TOUR_CADDIES = 'VALID_TOUR_CADDIES'
}

export const CommunicationService = () => {

    const create = (content: string, documents: string[], audience: string, audienceId: string) => {
            return ApiClient.getInstance().post<Communication>(`/communications`, {
                content,
                documents,
                audience,
                audienceId
            })
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }
    return {
        createCommunication: create,
    }
}

