import React, {useState} from 'react';
import {Alert, Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {useApi} from "../../../../services/useApi";
import * as yup from "yup";
import {logErrorFromApi} from "../../../../services/Utils";

interface Props {
    show: boolean
    close: () => void
    onSuccess: () => void
}

const AddNewNoSmartphoneCaddie = ({show, close, onSuccess}: Props) => {

    const { createClubCaddie } = useApi();

    const [profileCreating, setProfileCreating] = useState(false);

    const schema = yup.object().shape({
        phoneNumber: yup.string().required("Phone number is required"),
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
    });

    const submitForm = async (values: any) => {
        if (profileCreating) {
            return;
        }

        const createNewUser = async () => {
            return await createClubCaddie(
                "",
                values.firstName,
                values.lastName,
                values.phoneNumber,
                true
            );
        }

        await createNewUser()
            .catch(
                (err) => {
                    logErrorFromApi(err);
                    setProfileCreating(false);
                }
            )
        setProfileCreating(false);
        onSuccess();
    };

    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton={false}>Add new No Smartphone Caddie</Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={submitForm}
                        initialValues={{
                            phoneNumber: "",
                            firstName: "",
                            lastName: ""
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              values,
                              touched,
                              isValid,
                              errors,
                          }) => (
                            <Form
                                style={{
                                    padding: "2rem",
                                    paddingTop: 0
                                }}
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <br />
                                <p style={{ fontSize: '0.85em' }}>
                                    There are a few caveats to a no smartphone caddie:
                                </p>
                                <ol style={{ fontSize: '0.85em' }}>
                                    <li>They will always show as available on the booking panel.</li>
                                    <li>We will never show them on the 'Available Caddies' panel.</li>
                                    <li>Job requests will be sent via text and the caddie will not be able to accept/reject.</li>
                                    <li>Booking notes will not be shared with the caddie.</li>
                                </ol>
                                <p style={{ fontSize: '0.85em' }}>We highly encourage you to allow the caddie to make their account and set their availability on the Caddie Companion app.</p>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.FirstName">
                                            <Form.Control
                                                required
                                                className="form-input"
                                                type="text"
                                                name="firstName"
                                                placeholder="First Name"
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                                onChange={handleChange}
                                            />
                                            {touched.firstName && errors.firstName && (
                                                <Alert variant={"danger"}>
                                                    {errors.firstName}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.LastName">
                                            <Form.Control
                                                required
                                                className="form-input"
                                                type="text"
                                                name="lastName"
                                                placeholder="Last Name"
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                                onChange={handleChange}
                                            />
                                            {touched.lastName && errors.lastName && (
                                                <Alert variant={"danger"}>
                                                    {errors.lastName}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.PhoneNumber">
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>+</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="phoneNumber"
                                                    name="phoneNumber"
                                                    placeholder="Phone Number"
                                                    onBlur={handleBlur}
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                />
                                            </InputGroup>
                                            <Form.Text muted>
                                                Please provide the full phone number including international code.
                                                For example, 447123456789 for UK based number or 353812345678 for Ireland based number.
                                            </Form.Text>
                                            {touched.phoneNumber && errors.phoneNumber && (
                                                <Alert variant={"danger"}>
                                                    {errors.phoneNumber}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {
                                            profileCreating &&
                                            <Spinner animation={"border"}
                                                     variant="primary"
                                                     style={{marginRight: '1em'}} />
                                        }
                                        <Button
                                            type="cancel"
                                            variant='outline-danger'
                                            disabled={profileCreating}
                                            style={{ marginRight: '0.25em' }}
                                            onClick={close}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" disabled={profileCreating}>Add</Button>
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewNoSmartphoneCaddie;