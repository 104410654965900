import {toast} from "react-toastify";
import {ApiError} from "./ApiDomain";

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const handicaddieGreen = '#1db954';
export const tertiaryBlue = '#2f95dc';
export const tertiaryGreen = '#169041';
export const darkerHandicaddieGreen = '#136724';

export const today = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const currentMonth = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month].join('-');
};

export const delay = (time: number) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const logErrorFromApiWithDefaultMessage = (exception: any, defaultMessage: string) => {
    let errorResponse: ApiError = getApiError(exception);
    if (errorResponse === undefined) {
        toast.error(defaultMessage);
    } else {
        console.log(errorResponse.message, exception);
        toast.error(errorResponse.message);
    }
}

export const logErrorFromApi = (exception: any) => {
    let errorResponse: ApiError = getApiError(exception);
    let errMsg = errorResponse && errorResponse.message ? errorResponse.message : 'An unknown error occurred.'
    console.log(errMsg, exception);
    toast.error(errMsg);
}

export const logError = (message: string, exception: any) => {
    console.log(message, exception);
    toast.error(message);
}

export const getApiError: (err: any) => ApiError = (err: any) => {
    if (!err || !err.response) {
        return undefined;
    }
    try {
        return err.response.data;
    } catch (e) {
        return undefined;
    }
}

export const isTokenInvalid = (token: string | null) => {
    let payload: JwtPayload | undefined = getPayloadFromToken(token);

    if (!payload) {
        return true;
    }

    let now = new Date();
    return now > new Date(payload.exp * 1000);
}

export const getEmailFromToken = (token: string | null) => {
    let payload: JwtPayload | undefined = getPayloadFromToken(token);

    return payload?.email;
}

const getPayloadFromToken: (token: string | null) => JwtPayload | undefined = (token: string | null) => {
    if (!token) {
        return undefined;
    }

    let bearerSplit = token.split(' ');
    if (bearerSplit.length !== 2) {
        return undefined;
    }

    let jwtSplit = bearerSplit[1].split('.');
    if (jwtSplit.length !== 3) {
        return undefined;
    }

    return JSON.parse(atob(jwtSplit[1]));
}

interface JwtPayload {
    exp: number
    email: string
}
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}