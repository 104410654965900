import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Button, Card, Col, Container, Form, Navbar, Row} from "react-bootstrap";
import {useStore} from "../../../services/state/useStore";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {logError, logErrorFromApi} from "../../../services/Utils";
import {buildUrl, MY_PROFILE} from "../../../services/PageUtils";
import {useApi} from "../../../services/useApi";

import './ProfileForm.scss';
import {UserDetails} from "../../../services/ApiDomain";
import axios from "axios";
import Avatar from "../../../components/Avatar";


export const ProfileForm = () => {

    const navigate = useNavigate();
    const { currentUser, reloadUser, userAvatar, reloadUserAvatar } = useStore();
    const { updateCurrentUser, uploadAvatar } = useApi();

    const [newAvatar, setNewAvatar] = useState();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [bio, setBio] = useState<string>();
    // const [shareEmail, setShareEmail] = useState<boolean>();
    // const [sharePhoneNumber, setSharePhoneNumber] = useState<boolean>();

    const profileImageFileFinder = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        if (!firstName) {
            setFirstName(currentUser.firstName)
        }
        if (!lastName) {
            setLastName(currentUser.lastName)
        }
        if (!phoneNumber) {
            setPhoneNumber(currentUser.phoneNumber)
        }
        if (!bio) {
            setBio(currentUser.bio)
        }
    }, [currentUser]);

    const cancel = () => {
        navigate(buildUrl(MY_PROFILE));
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        saveUser()
            .catch((err) => logErrorFromApi(err));

        event.stopPropagation();
        event.preventDefault();
    }

    const saveUser = async () => {
        if (!currentUser) {
            toast.error("Can't update your profile at the minute.")
            return;
        }

        let details: UserDetails = {
            firstName: firstName ? firstName : currentUser.firstName,
            lastName: lastName ? lastName : currentUser.lastName,
            phoneNumber: phoneNumber ? phoneNumber : currentUser.phoneNumber,
            bio: bio ? bio : currentUser.bio,
            email: currentUser.email
        }

        updateCurrentUser(currentUser.id, details)
            .then((r) => {
                toast.success('Profile updated successfully.');
                if (newAvatar) {
                    uploadAvatar(currentUser.id)
                        .then((r) => {
                            setNewAvatar(undefined);
                            // @ts-ignore
                            axios.put(r, newAvatar, {
                                headers: {'Content-Type': 'multipart/form-data'},
                            }).finally(() => reloadUserAvatar())
                        })
                        .catch((err) => logError('Error uploading new avatar', err));
                }
                reloadUser();
                navigate(buildUrl(MY_PROFILE));
            })
            .catch((err) => logError('Error updating your profile', err));
    };

    return <>
        <Form style={{marginBottom: '10em'}} onSubmit={handleSubmit}>
            <Card className="profileForm_card">
                <Card.Header className="profileForm_sectionHeading" />
                <Card.Body>

                    <Row>
                        <Col xs={12} lg={4}>
                            <Row>
                                <Avatar
                                    url={newAvatar ? URL.createObjectURL(newAvatar) : undefined}
                                    user={'current'}
                                    size={'md'}
                                    style={{
                                        maxWidth: '300px',
                                        margin: '0 auto'
                                    }}
                                />
                            </Row>
                            <Row>
                                <Form.Control
                                    type="file"
                                    name="avatar"
                                    placeholder="Avatar"
                                    ref={profileImageFileFinder}
                                    className="profileForm_profileImage_input"
                                    onChange={(event: any) => {
                                        setNewAvatar(event.currentTarget.files[0]);
                                    }}
                                />
                                <Form.Label
                                    className="profileForm_profileImage_label"
                                    onClick={() => profileImageFileFinder.current?.click()}>
                                    Edit Profile Photo
                                </Form.Label>
                            </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Form.Group controlId="formGridFirstName">
                                        <Form.Label className="profileForm_inputLabel">First Name</Form.Label>
                                        <Form.Control
                                            placeholder="First Name"
                                            defaultValue={currentUser?.firstName}
                                            onChange={(e) => setFirstName(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                <Col xs={12} lg={6}>
                                    <Form.Group controlId="formGridLastName">
                                        <Form.Label className="profileForm_inputLabel">Last Name</Form.Label>
                                        <Form.Control
                                            placeholder="Last Name"
                                            defaultValue={currentUser?.lastName}
                                            onChange={(e) => setLastName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <Form.Group controlId="formGridEmail">
                                        <Form.Label className="profileForm_inputLabel">Email</Form.Label>
                                        <Form.Control type="email" placeholder="Email" defaultValue={currentUser?.email} disabled />
                                    </Form.Group>
                                </Col>

                                <Col xs={12} lg={6}>
                                    <Form.Group controlId="formGridPhoneNumber">
                                        <Form.Label className="profileForm_inputLabel">Phone Number</Form.Label>
                                        <Form.Control
                                            placeholder="Phone Number"
                                            defaultValue={currentUser?.phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Form.Group controlId="formGridBio">
                        <Form.Label className="profileForm_inputLabel">Bio</Form.Label>
                        <Form.Control
                            placeholder="Bio"
                            defaultValue={currentUser?.bio}
                            as="textarea"
                            className="profileForm_bio"
                            onChange={(e) => setBio(e.target.value)} />
                    </Form.Group>
                </Card.Body>
            </Card>

            {/*<Card className="profileForm_card">*/}
            {/*    <Card.Header className="profileForm_sectionHeading" />*/}
            {/*    <Card.Body>*/}
            {/*        <h3>Your Contact Preferences</h3>*/}
            {/*        <p>Please select the contact methods your caddie master should be able to contact you on</p>*/}
            {/*        <Form.Check*/}
            {/*            name="emailMe"*/}
            {/*            label="Email"*/}
            {/*            onChange={(e) => setShareEmail(e.target.checked)}*/}
            {/*        />*/}
            {/*        <Form.Check*/}
            {/*            name="phoneMe"*/}
            {/*            label="Phone Number"*/}
            {/*            onChange={(e) => setSharePhoneNumber(e.target.checked)}*/}
            {/*        />*/}
            {/*    </Card.Body>*/}
            {/*</Card>*/}

        <div className="fixed-bottom">
            <Navbar className="profileForm_actionPanel">
                <Container className="contentContainer" fluid="lg">
                    <div className="profileForm_actionPanel_content">
                        <Button
                            variant="outline-danger"
                            onClick={() => cancel()}
                            className="profileForm_actionPanel_paddedBtn">
                            Cancel
                        </Button>
                        <Button type="submit" className="profileForm_actionPanel_btn">Save Profile</Button>
                    </div>
                </Container>
            </Navbar>
        </div>
        </Form>
    </>
}
