import React from "react";
import LadiesEuropeanTourApplicationForm from "../parts/caddieontour/tours/let/form/LadiesEuropeanTourApplicationForm";

interface Props {

}

const LadiesEuropeanTourCaddieApplication = ({}: Props) => {

    return (
        <>
            <h1 className={"pageHeading"}>Ladies European Tour Caddie Application</h1>
            <LadiesEuropeanTourApplicationForm />
        </>
    )

}

export default LadiesEuropeanTourCaddieApplication;