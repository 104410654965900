import './NavBar.scss';
import {Button, Nav, Navbar, NavDropdown, OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";
import {useStore} from "../../services/state/useStore";
import {useNavigate} from "react-router-dom";
import {buildUrl, MEMBERSHIPS, MY_PROFILE, SETTINGS} from "../../services/PageUtils";
import {toast} from "react-toastify";
import Avatar from "../Avatar";

export interface Props {
    logOut?: () => void
    children?: React.ReactNode
}

export const NavigationBar = ({logOut, children}: Props) => {
    const navigate = useNavigate();
    const {currentUser} = useStore();

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Email Us!</Popover.Title>
            <Popover.Content>
                We want to make sure you get the most out of Handicaddie,
                please feel free to reach out to help@handicaddie.com for assistance or feedback.
            </Popover.Content>
        </Popover>
    );

    const goToUserProfile = () => {
        if (!currentUser) {
            toast.error('Can not detect your user. Please refresh & try again.');
            return;
        }

        navigate(buildUrl(MY_PROFILE));
    }

    const goToMemberships = () => {
        if (!currentUser) {
            toast.error('Can not detect your user. Please refresh & try again.');
            return;
        }

        navigate(buildUrl(MEMBERSHIPS));
    }

    const goToSettings = () => {
        navigate(buildUrl(SETTINGS));
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="white" id="top-navigation-bar" fixed='top'>
            <Navbar.Brand>
                <img
                    src="/logo.svg"
                    width="80"
                    className="d-inline-block align-top"
                    alt="Handicaddie logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {children && children}
                </Nav>

                <Nav>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                        <Button variant="outline-info" style={{'margin': '0.5em'}}>
                            <div
                                style={{
                                    fontSize: 16,
                                    marginRight: "10px",
                                }}
                            />
                            Need Help?
                        </Button>
                    </OverlayTrigger>

                    {currentUser && logOut &&
                        <NavDropdown id="accountOptions"
                                     title={<Button variant="outline-primary">
                                         <Avatar size={'xs'} user={'current'} style={{marginRight: '1em'}}/>
                                         {currentUser?.displayName}
                                     </Button>}>
                            <NavDropdown.Item onClick={goToUserProfile}>My Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={goToMemberships}>My Memberships</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={goToSettings}>Settings</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={logOut}>Log Out</NavDropdown.Item>
                        </NavDropdown>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );

}
