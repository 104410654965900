import {ReactElement, useEffect, useState} from "react";
import {NavLink as Link, useLocation, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {Edit} from "@material-ui/icons";
import {Alert, Button, Col, Container, Form, Image, NavLink, Row, Spinner,} from "react-bootstrap";
import background from "./background-1.jpg";
import {logErrorFromApi} from "../../services/Utils";
import {toast} from "react-toastify";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";

import "./Login.scss";

export default function ResetPassword(): ReactElement {
    const navigate = useNavigate();
    const location: any = useLocation();
    const { updateForgotPassword } = useApi();

    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        password: yup.string()
            .required("Password is required")
            .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
                "Password should contain, at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number"),
        passwordConfirmation: yup
            .string()
            .required("Password confirmation is required")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        code: yup.string().required("Code is required")
    });

    useEffect(() => {
        setLoading(false);

        if (!location.state || !location.state.email) {
            navigate('/accountconfirmation')
        }
    }, []);

    const submitForm = async (values: any) => {
        const resetPassword = async () => {
            await updateForgotPassword(location.state.email, values.password, values.code);
        }

        setLoading(true);

        resetPassword()
            .then(() => {
                toast.success("Password reset.")
                navigate('/login');
            })
            .catch((err) => logErrorFromApi(err))
            .finally(() => setLoading(false))
    };

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                    backgroundSize: "cover",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "50%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <Row>
                            <Image
                                src={"./logo.svg"}
                                className="card_menu_img"
                                style={{ margin: "auto", height: "100px" }}
                            />
                        </Row>

                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            initialValues={{
                                email: "",
                                password: "",
                                passwordConfirmation: "",
                                code: ""
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >

                                    <Row>
                                        <Col>
                                            <h4>Password Reset</h4>
                                            <p>We sent the confirmation code to your email.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Email">
                                                <Form.Control
                                                    className="form-input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email address"
                                                    value={location?.state?.email}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Password">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.password &&
                                                errors.password && (
                                                    <Alert
                                                        variant={"danger"}
                                                    >
                                                        {errors.password}
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.PasswordConfirmation">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="password"
                                                    name="passwordConfirmation"
                                                    placeholder="Password Confirmation"
                                                    onBlur={handleBlur}
                                                    value={
                                                        values.passwordConfirmation
                                                    }
                                                    onChange={handleChange}
                                                />
                                                {touched.passwordConfirmation &&
                                                errors.passwordConfirmation && (
                                                    <Alert
                                                        variant={"danger"}
                                                    >
                                                        {
                                                            errors.passwordConfirmation
                                                        }
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "2rem",
                                            }}
                                        >
                                            <Form.Label
                                                style={{ marginRight: "1rem" }}
                                            >
                                                Confirmation Code:
                                            </Form.Label>
                                            <Form.Group controlId="detailsForm.Code">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="text"
                                                    name="code"
                                                    placeholder="xxxx"
                                                    value={values.code}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.code &&
                                                errors.code && (
                                                    <Alert
                                                        variant={"danger"}
                                                    >
                                                        {errors.code}
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            {
                                                loading &&
                                                <Spinner animation={"border"}
                                                         variant="primary"
                                                         style={{marginRight: '1em'}} />
                                            }
                                            <Button type="submit" disabled={loading}>Reset Password</Button>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <NavLink
                                                as={Link}
                                                to="/login"
                                                style={{
                                                    fontSize: 14,
                                                    color: "green",
                                                }}
                                            >
                                                <Edit
                                                    style={{
                                                        fontSize: 16,
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Login
                                            </NavLink>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        </>
    );
}
