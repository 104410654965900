import {useRef, useState} from 'react';
import {Overlay, Tooltip} from "react-bootstrap";

interface Props {
    displayTeeTime: boolean
    teeTime: string
    endTeeTime?: string
}

const TeeTimeCell = ({displayTeeTime, teeTime, endTeeTime}: Props) => {
    const [showInfoLabel, setShowInfoLabel] = useState(false);

    const infoLabelTarget = useRef(null);

    return (
        <div className='d-flex justify-content-center flex-column align-items-start h-100'>
            {
                teeTime && teeTime === "02:02" ? <div style={{color: "#fb4c4c", display: 'flex', justifyContent: "center", alignItems: "center"}} ref={infoLabelTarget}
                    onMouseEnter={() => setShowInfoLabel(true)}
                    onMouseLeave={() => setShowInfoLabel(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    <Overlay target={infoLabelTarget.current} show={showInfoLabel} placement="top">
                        {(props) => <Tooltip id="overlay-example" {...props}>A tee time is required to complete this booking</Tooltip>}
                    </Overlay>

                    </div> :
                    <>
                        {!endTeeTime && <div className='d-flex justify-content-center '>
                            {displayTeeTime ? teeTime : ""}
                        </div>}

                        {endTeeTime && <>
                            <div className='d-flex justify-content-center align-items-center '>
                                {displayTeeTime ? teeTime : ""}
                            </div>

                            <div className='d-flex justify-content-center flex-column align-items-center'>
                                <div style={{display: 'flex', 'justifyContent': 'center'}}>
                                    <div style={{
                                        width: '1px',
                                        borderLeft: '2px gray solid',
                                        minHeight: '1em',
                                        flexShrink: 0
                                    }}></div>
                                </div>
                                {endTeeTime}
                            </div>
                        </>}
                    </>
            }
        </div>
    )
}

export default TeeTimeCell;