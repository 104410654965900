import React, {useEffect, useState} from "react";

import './DraftModeAutoPublishBanner.scss';
import {AutoPublishStatus} from "../../services/timesheet/TimesheetService";
import {CancelRounded} from "@material-ui/icons";

interface Props {
    status: AutoPublishStatus
}

const DraftModeAutoPublishBanner = ({status}: Props) => {

    const [show, setShow] = useState<boolean>()

    useEffect(() => {
        setShow(true)
    }, [status]);

    return (<>
        {show && <div className="autoPublishBanner">
            <div className="autoPublishBanner_content">

            <span className={"autoPublishBanner_text"}>
                You have {status.numberOfBookings} caddie slots in draft for {status.bookingDate}.
                If they are still in draft on {status.autoPublishDate}, then Handicaddie will automatically publish
                them.
            </span>
            <CancelRounded className={"autoPublishBanner_hide"} onClick={() => setShow(false)} />
            </div>
        </div>}
    </>)

}

export default DraftModeAutoPublishBanner;