import React from 'react';
import {Badge} from "react-bootstrap";
import {Course} from "../services/ApiDomain";

interface Props {
    course: Course
}

const CourseBadge = ({course}: Props) => {
    return (
        <Badge style={{
            backgroundColor: course.colourCode,
            color: "white",
            padding: '0.25em 0.75em',
            fontSize: '1rem'
        }}>
            {course.name.length > 10 ? course.name.substr(0, 10) + "..." : course.name}
        </Badge>
    )
}

export default CourseBadge;