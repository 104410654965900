import './publish_schedule_modal.scss'

import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";

import CaddieTableCell from "./CaddieTableCell";
import {TeeTimeResource} from "../../../../services/ApiDomain";
import moment from "moment";
import {TimesheetService} from "../../../../services/timesheet/TimesheetService";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../../services/Utils";

type PublishScheduleModalProps = {
    clubId?: string,
    selectedDate: Date,
    show: boolean
    close: () => void
    refreshTimesheet: () => void
    teeTimes: TeeTimeResource[]
}

const PublishScheduleModal = ({clubId, selectedDate, show, close, refreshTimesheet, teeTimes}: PublishScheduleModalProps) => {

    const [loading, setLoading] = useState(false);

    const { publishTimesheetByDate } = TimesheetService();

    const publish = async () => {
        if (!clubId) {
            return;
        }

        setLoading(true)
        try {
            await publishTimesheetByDate(clubId, selectedDate)
            refreshTimesheet();
            toast.success("Published draft bookings.")
            setLoading(false);
            close();
        } catch (e) {
            logErrorFromApi(e)
            setLoading(false);
        }
    }

    return (
        <Modal show={show} onHide={close} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Publish Draft Slots</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>You're about to finalise your caddie bookings for {moment(selectedDate).format('dddd Do MMMM')}. Selecting publish here will send out notifications to all the caddies below.</p>

                <div className="publishScheduleModal_tableContainer">
                    <table className="publishScheduleModal_table">
                        <thead>
                        <tr>
                            <th>Caddie</th>
                            <th>Tee Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {teeTimes.map((teeTime) => {
                            return teeTime.booking?.bookingSlots
                                .filter(bs => bs.caddieId && bs.status === 'DRAFT')
                                .map(caddie => {
                                    return <tr key={caddie.userId}>
                                        <td>
                                            <CaddieTableCell name={caddie.displayName || ""} userId={caddie.userId}/>
                                        </td>
                                        <td>
                                            {moment(teeTime.booking?.teeTime).format('HH:mm')}
                                        </td>
                                    </tr>
                                })
                        })}
                        </tbody>
                    </table>
                </div>


            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={close}>Close</Button>
                <Button disabled={loading} variant="primary" onClick={publish}>Publish</Button>
                {loading &&
                    <Spinner animation={"border"}
                        variant="primary"
                        style={{ marginLeft: '1em' }} />}
            </Modal.Footer>
        </Modal>
    )
}

export default PublishScheduleModal;