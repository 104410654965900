import React, {useEffect, useState} from "react";
import GolfClubMemberships from "../parts/memberships/GolfClubMemberships";
import {useStore} from "../services/state/useStore";
import {MembershipDomain} from "../services/ApiDomain";
import {useApi} from "../services/useApi";
import {logErrorFromApi} from "../services/Utils";

interface Props {

}

const Memberships = ({}: Props) => {

    const { currentUser } = useStore();
    const { getMemberships } = useApi();

    const [loading, setLoading] = useState<boolean>(false);
    const [golfClubMemberships, setGolfClubMemberships] = useState<MembershipDomain[]>([]);

    useEffect(() => {
        loadMemberships()
            .catch((err) => logErrorFromApi(err));
    }, [currentUser]);

    const loadMemberships = async () => {
        if (!currentUser) {
            return;
        }
        try {
            setLoading(true);
            let memberships = await getMemberships();
            setGolfClubMemberships(memberships.filter(membership => membership.type === 'GOLF_CLUB'));
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h1 className={"pageHeading"}>Memberships</h1>
            <GolfClubMemberships
                memberships={golfClubMemberships}
                loading={loading}
                refreshMemberships={() => loadMemberships()}
            />
        </>
    )

}

export default Memberships;