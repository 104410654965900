import React from "react";
import {NavigationBar} from "../components/navbar/NavigationBar";
import {Container} from "react-bootstrap";
import background from "../components/login/background-1.jpg";
import {ChoiceContent} from "../parts/caddiechoice/ChoiceContent";

interface Props {
}

export const CaddieChoice = ({}: Props) => {

    return <>
        <NavigationBar />
        <Container
            className={"contentContainer"}
            style={{
                backgroundImage: `url(${background})`,
                height: "calc(100vh - 76px)",
                marginTop: "0px",
            }}
            fluid={true}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        minWidth: "50%",
                        width: "auto",
                        height: "auto",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                >
                    <ChoiceContent />
                </div>
            </div>
        </Container>
    </>
}