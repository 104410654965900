import React, {useEffect, useState} from 'react';
import {ErrorRounded} from "@material-ui/icons";
import {Container, ProgressBar} from "react-bootstrap";

interface Props {

}

const PaymentUnsuccessful = ({}: Props) => {

    const SCREEN_TIMEOUT = 1500;
    const INTERVAL = 150;
    const INCREMENT = (100 / ( SCREEN_TIMEOUT / INTERVAL)) + 5;

    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            window.location.href = '/platform/memberships';
        }, SCREEN_TIMEOUT);

        setTimeout(() => {
            setProgress(progress + INCREMENT);
        }, INTERVAL);
    }, []);

    useEffect(() => {
        if (progress < 100) {
            setTimeout(() => {
                setProgress(progress + INCREMENT);
            }, INTERVAL);
        }
    }, [progress]);

    const updateProgressBar = async () => {
        setProgress(progress + 10);
        console.log(progress)
        if (progress < 100) {
            setTimeout(() => updateProgressBar(), INTERVAL);
        }
    }

    return (
        <Container>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5em' }}>
                <ErrorRounded
                    style={{fontSize: '15em', color: '#b91d1d'}}
                />
            </div>
            <h3 style={{ textAlign: 'center' }}>Payment unsuccessful.</h3>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <ProgressBar now={progress} variant="danger" style={{ width: '50%', justifySelf: 'center' }} />
            </div>
        </Container>
    )
}

export default PaymentUnsuccessful;