import {PayloadAction} from "@reduxjs/toolkit";
import {SchedulerState} from "./slice";
import {DeleteModalAction, Drawer, DrawerStatus} from "./domain";
import {ClubBooking, Course} from "../../../ApiDomain";

interface SelectTeeTimePayloadAction {
    teeTime?: string
    teeTimesInPool?: number
    isSqueezedOrNoTeeTimeBooking: boolean
    booking?: ClubBooking
    course?: Course
}

export const getReducers = {
    handleDateChange: (state: SchedulerState, action: PayloadAction<string>) => {
        state.caddies = []
        state.selectedDate = action.payload
        state.activeDrawer = Drawer.AVAILABLE_CADDIES
    },
    handleDrawerChange: (state: SchedulerState, action: PayloadAction<Drawer>) => {
        state.activeDrawer = action.payload
    },
    handleToggleDrawer: (state: SchedulerState) => {
        state.drawerStatus = state.drawerStatus === DrawerStatus.OPEN ?
            DrawerStatus.CLOSED :
            DrawerStatus.OPEN
    },
    handleOpenDrawer: (state: SchedulerState) => {
        state.drawerStatus = DrawerStatus.OPEN
    },
    handleAddClubToNoTeeTimes: (state: SchedulerState, action: PayloadAction<string>) => {
        state.areNoTeeTimesAllowed.push(action.payload)
    },
    handleAddClubToManualCourseAllowed: (state: SchedulerState, action: PayloadAction<string>) => {
        state.isManualCourseAllowed.push(action.payload)
    },
    handleAddClubToPlayersEnabled: (state: SchedulerState, action: PayloadAction<string>) => {
        state.isPlayersEnabled.push(action.payload)
    },
    handleNewSqueezedOrNoTeeTimeBooking: (state: SchedulerState) => {
        state.selectedTeeTime = {
            loadingBooking: false,
            isEditing: false,
            isSqueezedOrNoTeeTimeBooking: true,
            teeTime: ""
        }
        state.activeDrawer = Drawer.BOOKING
        state.drawerStatus = DrawerStatus.OPEN
    },
    handleToggleHideTeeTimes: (state: SchedulerState) => {
        state.hideEmptyTeeTimes = !state.hideEmptyTeeTimes
    },
    handleCourseIdFilterUpdate: (state: SchedulerState, action: PayloadAction<Course | undefined>) => {
        state.courseFilter = action.payload
    },
    handleSelectedTeeTime: (state: SchedulerState, action: PayloadAction<SelectTeeTimePayloadAction>) => {
        state.selectedTeeTime = {
            teeTime: action.payload.teeTime,
            teeTimesInPool: action.payload.teeTimesInPool,
            isSqueezedOrNoTeeTimeBooking: action.payload.isSqueezedOrNoTeeTimeBooking,
            booking: action.payload.booking,
            course: action.payload.course,
            isEditing: !!action.payload.booking,
            loadingBooking: false
        }
        state.activeDrawer = Drawer.BOOKING
        state.drawerStatus = DrawerStatus.OPEN
    },
    handleResetDrawer: (state: SchedulerState) => {
        state.selectedTeeTime = undefined
        state.drawerStatus = DrawerStatus.OPEN
        state.activeDrawer = Drawer.AVAILABLE_CADDIES
    },
    handleAddBookingLoading: (state: SchedulerState) => {
        state.addBookingLoading = true
    },
    handleAddBookingFinished: (state: SchedulerState) => {
        state.addBookingLoading = false
    },
    handleShowDeleteBookingModal: (state: SchedulerState, action: PayloadAction<DeleteModalAction>) => {
        state.showDeleteModal = action.payload.show
        state.deletingBookingId = action.payload.bookingId
    },
}