import React from 'react';
import Avatar from "../../../../components/Avatar";

interface Props {
    name: string
    userId?: string
}

const CaddieTableCell = ({name, userId}: Props) => {
    return (
        <div>
            <Avatar size='xs' user={userId ? userId : ""} />
            <span>{name}</span>
        </div>
    )
}

export default CaddieTableCell;