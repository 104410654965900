import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';
import {NewTourPassForm} from "./form/NewTourPassForm";
import {toast} from "react-toastify";
import {TourPassService} from "../../../../../services/tourpass/TourPassService";

type Props = {
    show: boolean
    tourId: string
    closeModal: () => void
}

export const AddNewTourPassModal = ({show, tourId, closeModal}: Props) => {

    const { createTourPass } = TourPassService();

    const [name, setName] = useState<string>('');
    const [start, setStart] = useState<string>('');
    const [end, setEnd] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [creating, setCreating] = useState<boolean>(false);

    useEffect(() => {
        setCanSave(allFieldsHaveInput());
    }, [name, start, end, price])

    const allFieldsHaveInput: () => boolean = () => {
        return name.length > 0 && start.length > 0 && end.length > 0 && price > 0
    }

    const save = async () => {
        if (!allFieldsHaveInput()) {
            toast.error('Not all information provided.');
            return;
        }
        setCreating(true);
        try {
            const tourPass = await createTourPass(tourId, {
                name: name,
                validFrom: start,
                validTo: end,
                fee: price * 100,
                tourId: tourId
            });
            if (tourPass) {
                setName('');
                setStart('');
                setEnd('');
                setPrice(0);
                setCanSave(false);
                closeModal();
            }
        } finally {
            setCreating(false);
        }

    }

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add tour pass</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <NewTourPassForm
                    updateName={setName}
                    updateStart={setStart}
                    updateEnd={setEnd}
                    updatePrice={setPrice}
                />
            </Modal.Body>

            <Modal.Footer>
                <div style={{display: 'flex'}}>
                    <div style={{marginLeft: 'auto'}}>
                        {creating && <Spinner animation="border" />}
                        <Button variant='outline-danger' onClick={closeModal}>Cancel</Button>
                        {'  '}
                        <Button
                            variant='primary'
                            disabled={!canSave || creating}
                            onClick={() => save()}
                        >Add</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
