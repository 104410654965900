import React from 'react';
import {CustomDropdown, Option} from "../CustomDropDown";
import {AppDispatch, RootState} from "../../../services/store/store";
import {useDispatch, useSelector} from "react-redux";
import {handleClubChange} from "../../../services/store/reducers/clubs/slice";

interface Props {
    classNames?: string
}

const ClubSelectorDropdown = ({classNames}: Props) => {

    const { allClubs, selectedClub } = useSelector((state: RootState) => state.clubs);
    const dispatch = useDispatch<AppDispatch>();

    // const [options, setOptions] = useState<Option[]>([])
    // const [label, setLabel] = useState<string>();
    //
    // useEffect(() => {
    //     if (caddieMasters?.length > 0) {
    //         let ops = caddieMasters.map(({ clubId, clubName }) => ({
    //             value: clubId,
    //             label: clubName,
    //         }));
    //         setOptions(ops)
    //
    //         let initial = ops[0];
    //         if (selectedClub) {
    //             let club = ops.find((item) => item.value === selectedClub);
    //             if (club) {
    //                 initial = club;
    //             }
    //         }
    //         setLabel(initial.label);
    //         setSelectedClub(initial.value);
    //     }
    // }, [currentUser]);

    return (
        <>
            {
                allClubs.length > 1 && selectedClub.club &&
                <CustomDropdown
                    options={allClubs.map<Option>(club => {
                        return {
                            value: club.id,
                            label: club.name,
                        };
                    })}
                    selectedValue={selectedClub.club.name}
                    onSelect={(value) => dispatch(handleClubChange(value))}
                    className={`border-b-dropdown ${classNames ? classNames : ""}`}/>
            }
        </>
    )
}

export default ClubSelectorDropdown;