import {Dropdown} from "react-bootstrap";

// Define a generic type with 'value' and 'label' properties
export interface Option {
    value: string;
    label: string;
}

interface CustomDropdownProps<T extends Option> {
    options: T[];
    selectedValue: string | undefined;
    onSelect: (value: string | undefined) => void;
    className?: string | undefined;
    variant?: string | undefined
    type?: string | undefined
}

export const CustomDropdown = <T extends Option>({ options, selectedValue, onSelect, className, variant, type }: CustomDropdownProps<T>) => {
    return (
        <Dropdown className={`${className}`} onSelect={(eventKey) => onSelect(eventKey as any)}>
            <Dropdown.Toggle
                className="d-inline-flex align-items-center justify-content-between "
                variant={variant}
                id="dropdown-basic">
                {selectedValue || type}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={13}
                    className="ml-3"
                    fill="currentColor"
                    viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {type && <Dropdown.Item key={type} eventKey={""}>
                    {`All`}
                </Dropdown.Item>}
                {options.map((option) => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                        {option.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};