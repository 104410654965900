import React, {useEffect, useState} from 'react';
import {useApi} from "../../../../../services/useApi";
import {Spinner} from "react-bootstrap";
import {UserAvailability} from "../../../../../services/ApiDomain";
import {logErrorFromApi} from "../../../../../services/Utils";
import SelectableCaddie from "./SelectableCaddie";
import moment from "moment/moment";
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";

interface Props {
    teeTime?: string
    selectedCaddies: (string | undefined)[]
    addCaddieToBooking: (userId: string, caddieId: string, name: string, noSmartphone: boolean) => void
    canAddCaddies: boolean
    searchTerm?: string
}

const AvailableCaddiesForBooking = ({teeTime, selectedCaddies, addCaddieToBooking, canAddCaddies, searchTerm}: Props) => {

    const {getAvailableCaddiesForBooking, getAvailableCaddiesForClubOnDate} = useApi();

    const [loading, setLoading] = useState<boolean>(false);
    const [caddies, setCaddies] = useState<UserAvailability[]>();
    const [searchResultCaddies, setSearchResultCaddies] = useState<UserAvailability[]>();

    const { selectedDate } = handicaddieSelector(state => state.scheduler)
    const { selectedClub } = handicaddieSelector(state => state.clubs)

    useEffect(() => {
        loadAvailCaddies()
    }, [selectedDate, teeTime])

    useEffect(() => {
        if (!caddies) {
            return;
        }

        if (!searchTerm) {
            setSearchResultCaddies(caddies)
            return;
        }

        let trimmedSearchTerm = searchTerm.trim().toLowerCase()
        setSearchResultCaddies(caddies.filter(c => c.user.displayName.toLowerCase().includes(trimmedSearchTerm)))
    }, [caddies, searchTerm])

    const loadAvailCaddies = async () => {
        try {
            if (!selectedClub.club) {
                return;
            }

            setLoading(true)
            let dateStr = moment(new Date(selectedDate).toDateString()).format('YYYY-MM-DD');
            if (teeTime) {
                dateStr = dateStr + "T" + teeTime
                let availCaddies = await getAvailableCaddiesForBooking(selectedClub.club.id, dateStr);
                setCaddies(availCaddies.resource)
            } else {
                let availCaddies = await getAvailableCaddiesForClubOnDate(selectedClub.club.id, new Date(selectedDate));
                setCaddies(availCaddies.resource)
            }
        } catch (e) {
            logErrorFromApi(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {(loading || !caddies) && <Spinner animation='border'/>}
            {!loading &&
                canAddCaddies &&
                searchResultCaddies &&
                searchResultCaddies.filter((c) => c.caddieId && !selectedCaddies.includes(c.caddieId))
                    .map((c) => {
                        return <SelectableCaddie
                            selected={false}
                            userId={c.user.id}
                            name={c.user.displayName}
                            noSmartphone={c.noSmartphoneCaddie}
                            onClick={() => {
                                if (!c.caddieId) {
                                    return;
                                }
                                addCaddieToBooking(c.user.id, c.caddieId, c.user.displayName, !!c.noSmartphoneCaddie)
                            }}
                        />
                    })}
            {!loading &&
                canAddCaddies &&
                searchResultCaddies &&
                searchResultCaddies.filter((c) => c.caddieId && !selectedCaddies.includes(c.caddieId)).length === 0 &&
                "No caddies are available."}
            {!canAddCaddies && "This booking is full."}
        </>
    )
}

export default AvailableCaddiesForBooking;