import React from 'react';
import {Button, Modal} from "react-bootstrap";

type AlertModalProps = {
    showAlertModal: boolean
    titleText: string
    bodyText: string
    buttonText: string
    handleAlertModalUpdate: () => void
    handleAlertModalClose: () => void
}

const AlertModal = ({showAlertModal, titleText, bodyText, buttonText, handleAlertModalUpdate, handleAlertModalClose}: AlertModalProps) => {

    return (
        <Modal show={showAlertModal} onHide={() => handleAlertModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{bodyText}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={() => handleAlertModalClose()}>Close</Button>
                <Button variant="primary" onClick={() => handleAlertModalUpdate()}>{buttonText}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertModal;