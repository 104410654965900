import React from "react";
import {TourShowcaseCard} from "../../TourShowcaseCard";
import {useNavigate} from "react-router-dom";
import {LET_CADDIE_APPLICATION} from "../../../../services/PageUtils";
import {Card} from "react-bootstrap";

import showcaseImage from "./LadiesEuropeanTourShowcaseImage.png";

interface Props {
}

export const LadiesEuropeanTourShowcaseCard = ({}: Props) => {

    const navigate = useNavigate();

    const navigateToApplicationForm = () => {
        navigate(LET_CADDIE_APPLICATION);
    }

    return <TourShowcaseCard
        image={showcaseImage}
        onClick={navigateToApplicationForm}
        pricePerSeason="49"
    >
        <>
            <Card.Title>Join the Ladies European Tour Caddie Association</Card.Title>
            <Card.Body>
                <p>
                    If you’re a caddie at a golf club, you’re only a few clicks away from beginning your journey in the professional ranks!
                    With the new Handicaddie Tour Premium subscription you will be able to set your availability for tour events and get hired by Ladies European Tour players.
                </p>
            </Card.Body>
        </>
    </TourShowcaseCard>
}