import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";

export interface TourPass {
    id?: string
    name: string
    validFrom: string
    validTo: string
    fee: number
    tourId: string
}

export const TourPassService = () => {

    const getAll: (tourId: string) => Promise<TourPass[] | void> = (tourId: string) => {
        return ApiClient.getInstance().get<TourPass[]>(`/tours/${tourId}/passes`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const create: (tourId: string, pass: TourPass) => Promise<TourPass | void> = (tourId: string, pass: TourPass) => {
        return ApiClient.getInstance().post<TourPass>(`/tours/${tourId}/passes`, pass)
            .then((resp) => {
                return resp;
            }).catch((err) => logErrorFromApi(err))
    }

    return {
        getTourPasses: getAll,
        createTourPass: create
    }
}

