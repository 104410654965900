import {ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {Alert, Button, Col, Container, Form, Row, Spinner,} from "react-bootstrap";
import "./Login.scss";
import background from "./background-1.jpg";
import {logError, logErrorFromApi} from "../../services/Utils";
import {useStore} from "../../services/state/useStore";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";
import {Club} from "../../services/ApiDomain";

interface Props {}

export default function ClubRegister({}: Props): ReactElement {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().required("Name is required")
    });

    const { currentUser, reloadUser } = useStore();
    const { createClub, createCaddie } = useApi();

    useEffect(() => {
        setLoading(false);
    }, []);

    const submitForm = async (values: any) => {
        if (!currentUser) {
            await reloadUser();
            if (!currentUser) {
                logError('Error loading your profile.', {});
                return;
            }
        }

        const register = async () => {
            return await createClub(values.name);
        }

        const registerAsCaddie = async (clubId: string) => {
            await createCaddie(currentUser.id, clubId);
            navigate("/");
        }

        setLoading(true);
        register()
            .then(async (club: Club) => {
                registerAsCaddie(club.id)
                    .catch((err) => logErrorFromApi(err));
            })
            .catch((err) => logErrorFromApi(err))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                    backgroundSize: "cover",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "50%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            initialValues={{
                                name: ""
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Name">
                                                <Form.Control
                                                    className="form-input"
                                                    type="string"
                                                    name="name"
                                                    placeholder="Golf Club Name"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.name &&
                                                    errors.name && (
                                                        <Alert
                                                            variant={"danger"}
                                                        >
                                                            {errors.name}
                                                        </Alert>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            {
                                                loading &&
                                                <Spinner animation={"border"}
                                                         variant="primary"
                                                         style={{marginRight: '1em'}} />
                                            }
                                            <Button type="submit" disabled={loading}>Go</Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        </>
    );
}
