import {useEffect, useState} from "react";
import Calendar from "react-calendar";
import {formatISO} from "date-fns";
import "./CalendarStyles.scss";
import "./AvailabilitySelection.scss";
import {Button, Col, Row} from "react-bootstrap";
import AvailabilityDates from "./AvailabilityDates";
import {toast} from "react-toastify";
import parseIsoDate from "yup/es/util/isodate";
import {logErrorFromApiWithDefaultMessage} from "../../../services/Utils";
import {useStore} from "../../../services/state/useStore";
import {useApi} from "../../../services/useApi";
import {CaddieAvailability} from "../../../services/ApiDomain";

interface Props {}

const AvailabilitySelection = ({} : Props) => {
    const { currentUser, caddies } = useStore();
    const { getCaddieAvailabilitiesForUser, updateCaddieAvailabilities } = useApi();

    const [dates, setDates] = useState<Date[]>([]);
    const [updating, setUpdating] = useState<boolean>(false);

    useEffect(() => {
        async function loadAvailabilities(id: string) {
            let availabilities = await getCaddieAvailabilitiesForUser(id);
            const selectedDates = new Set<number>();
            const scheduledDates = new Set<Date>();
            availabilities.forEach((availability: CaddieAvailability) => {
                availability.availabilities.forEach((date) => {
                    const parsedDate = new Date(parseIsoDate(date));
                    if (!selectedDates.has(parsedDate.getDate())) {
                        selectedDates.add(parsedDate.getDate());
                        scheduledDates.add(parsedDate);
                    }
                })
            });
            setDates(Array.from(scheduledDates));
        }

        if (!currentUser) {
            return;
        }

        loadAvailabilities(currentUser.id)
            .catch((err) => logErrorFromApiWithDefaultMessage(err, 'Error getting your availability.'));
    }, [currentUser]);

    const selectDates = (date: Date | Date[]) => {
        if (!Array.isArray(date)) {
            date.setHours(0, 0, 0, 0);
            if (isDateSelected(date)) {
                removeDate(date);
            } else {
                const updatedDates = [...dates, date];
                updatedDates.sort((dateOne: Date, dateTwo: Date) => dateOne.getTime() - dateTwo.getTime());
                setDates(updatedDates);
            }
        }
    };

    const removeDate = (dateToRemove: Date) => {
        setDates(
            dates.filter((date) => date.getDate() !== dateToRemove.getDate())
        );
    };

    const isDateSelected = (date: Date) => {
        for (let i = 0; i < dates.length; i++) {
            const selectedDate = dates[i];
            if (selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth()) {
                return true;
            }
        }
    }

    const updateCaddieSchedule = async () => {
        if (!caddies) {
            return;
        }

        const updateAvailabilitiesForCaddies = async () => {
            const datesAsStrings = dates.map((date: Date) => formatISO(date));
            for (const caddie of caddies) {
                if (!caddie.approved) {
                    continue;
                }
                await updateCaddieAvailabilities(caddie.id, datesAsStrings)
            }
        }

        setUpdating(true);
        updateAvailabilitiesForCaddies()
            .then(() => toast.success('Successfully updated your caddie availability'))
            .catch((err) => logErrorFromApiWithDefaultMessage(err, 'Error updating your availability'))
            .finally(() => setUpdating(false));

    }

    const getDateStyle = (dateInCalendar: any): string => {
        return `${isDateSelected(dateInCalendar) ? 'calendar-selected-date' : ''}`;
    };

    const fourWeeksInFuture = () => {
        let numWeeks = 4;
        let date = new Date();
        date.setDate(date.getDate() + numWeeks * 7);
        return date;
    }

    return (
        <div>
            <div className="availabilitySelection_intro">
                This page enables you to update your availability for
                the next 4 weeks.
            </div>
            <Row className="availabilitySelection_calendarContainer">
                <Calendar
                    onChange={selectDates}
                    minDate={new Date()}
                    maxDate={fourWeeksInFuture()}
                    showNeighboringMonth={false}
                    className="card_drop_shadow"
                    tileClassName={({ date }) =>
                        getDateStyle(date)
                    }
                />
            </Row>
            <Row>
                <Col>
                    <h3 className="pageSubHeading">Selected Dates</h3>
                        <AvailabilityDates
                            scheduledDates={dates}
                            removeDate={removeDate}
                            editable
                        />
                    <Button
                        variant="primary"
                        onClick={updateCaddieSchedule}
                        className="availabilitySelection_updateBtn"
                        disabled={updating} >
                        Update Availability
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default AvailabilitySelection;
