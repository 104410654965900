import React from 'react';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './app/App';
import {GlobalStateProvider} from "./services/state/GlobalStateProvider";
import {store} from "./services/store/store"
import {Provider} from "react-redux";
import {createRoot} from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<React.StrictMode>
    <BrowserRouter>
        <GlobalStateProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </GlobalStateProvider>
    </BrowserRouter>
</React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
