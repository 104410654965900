import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {MemberProfileInfo} from "./info/MemberProfileInfo";
import {Member} from "../../../../../services/member/MemberService";

import './MemberProfileContent.scss';
import MemberComments from "./comments/MemberComments";

interface Props {
    member: Member
}

export const MemberProfileContent = ({ member }: Props) => {

    return <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Info" className="profileContent_tab">
            <MemberProfileInfo member={member} />
        </Tab>
        <Tab eventKey="comments" title="Comments" className="profileContent_tab">
            <MemberComments member={member} />
        </Tab>
    </Tabs>

}