import {ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {Button, Col, Container, Form, Row, Spinner,} from "react-bootstrap";
import "./Login.scss";
import background from "./background-1.jpg";
import {getEmailFromToken, logError, logErrorFromApi} from "../../services/Utils";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";
import {useStore} from "../../services/state/useStore";
import {toast} from "react-toastify";
import {CADDIE_CHOICE, LOADING} from "../../services/PageUtils";
import axios from "axios";

interface Props {}

export default function CreateProfile({}: Props): ReactElement {
    const navigate = useNavigate();
    const { token, currentUser, reloadUser } = useStore();
    const { createUser, uploadAvatar } = useApi();

    const [profileCreating, setProfileCreating] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        avatar: yup.mixed()
    });

    useEffect(() => setProfileCreating(false), []);

    useEffect(() => {
        if (token && currentUser) {
            navigate(LOADING);
        }
    }, [currentUser])

    const submitForm = async (values: any) => {
        if (profileCreating) {
            return;
        }

        setProfileCreating(true);
        let email = getEmailFromToken(token)

        if (!email) {
            toast.error('Please log out and try again.');
            return;
        }

        const user = await createUser(
            values.firstName,
            values.lastName,
            email
        ).catch((err) => {
            logErrorFromApi(err);
            setProfileCreating(false);
            return;
        })

        if (!user) {
            setProfileCreating(false);
            return;
        }

        if (user && values.avatar) {
            uploadAvatar(user.id)
                .then(r => {
                    // @ts-ignore
                    axios.put(r, values.avatar, {
                        headers: {'Content-Type': 'multipart/form-data'},
                    })
                }).catch((err) => logError("Error uploading your profile image", err))
        }

        setProfileCreating(false);
        reloadUser();
        navigate(CADDIE_CHOICE);
    };

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                    backgroundSize: "cover",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "30%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                avatar: undefined
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Form.Label
                                        style={{
                                            fontSize: "1.5rem",
                                            fontWeight: "bolder",
                                        }}
                                    >
                                        Create Profile
                                    </Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.FirstName">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    onBlur={handleBlur}
                                                    value={
                                                        values.firstName
                                                    }
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.LastName">
                                                <Form.Control
                                                    required
                                                    className="form-input"
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                    onBlur={handleBlur}
                                                    value={
                                                        values.lastName
                                                    }
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="detailsForm.Avatar">
                                                <Form.Control
                                                    className="form-input"
                                                    type="file"
                                                    name="avatar"
                                                    placeholder="Avatar"
                                                    onBlur={handleBlur}
                                                    onChange={(event: any) => {
                                                        setFieldValue("avatar", event.currentTarget.files[0]);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            {
                                                profileCreating &&
                                                <Spinner animation={"border"}
                                                         variant="primary"
                                                         style={{marginRight: '1em'}} />
                                            }
                                            <Button type="submit" disabled={profileCreating}>Go</Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        </>
    );
}
