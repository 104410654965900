import {ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import {Button, Col, Container, Form, Row,} from "react-bootstrap";
import "./Login.scss";
import background from "./background-1.jpg";
import {logError} from "../../services/Utils";
import {useStore} from "../../services/state/useStore";
import {NavigationBar} from "../navbar/NavigationBar";
import {useApi} from "../../services/useApi";
import {Club} from "../../services/ApiDomain";
import {PLATFORM_HOME} from "../../services/PageUtils";

interface Props {}

export default function CreateProfile({}: Props): ReactElement {
    const navigate = useNavigate();
    const [clubs, setClubs] = useState<Club[]>([]);

    const { currentUser, caddies, caddieMasters, reloadUser } = useStore();
    const { createCaddie, getListOfClubs } = useApi();

    const submitForm = async (values: any) => {
        if (!currentUser) {
            await reloadUser();
            if (!currentUser) {
                logError('Error loading your profile.', {});
                return;
            }
        }

        await createCaddie(currentUser.id, values.club);
        navigate(PLATFORM_HOME);
    };

    const goToRegisterClub = async () => {
        navigate("/club-register");
    };

    useEffect(() => {
        async function getClubListing() {
            const clubsResponse = await getListOfClubs();
            setClubs(clubsResponse.filter(club => club.name !== 'Handicaddie Golf Club'));
        }

        getClubListing();
    }, []);

    useEffect(() => {
        if (caddies == undefined || caddieMasters == undefined) {
            return;
        }

        if (caddies.length > 0 || caddieMasters.length > 0) {
            navigate('/')
        }
    }, [caddies, caddieMasters])

    return (
        <>
            <NavigationBar />
            <Container
                className={"contentContainer"}
                style={{
                    backgroundImage: `url(${background})`,
                    height: "calc(100vh - 76px)",
                    marginTop: "0px",
                    backgroundSize: "cover",
                }}
                fluid={true}
            >
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "30%",
                            width: "auto",
                            height: "auto",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <Formik
                            onSubmit={submitForm}
                            initialValues={{
                                club: clubs[0]?.id,
                            }}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form
                                    style={{
                                        marginTop: "10px",
                                        padding: "2rem",
                                    }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <Form.Label
                                            style={{
                                                fontSize: "1.5rem",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Club Selection
                                        </Form.Label>
                                        <Form.Label
                                            style={{
                                                fontSize: "1.2rem",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Select the golf club you want to
                                            caddie for
                                        </Form.Label>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Label>
                                                    Select a club
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="club"
                                                    onBlur={handleBlur}
                                                    value={values.club}
                                                    onChange={handleChange}
                                                >
                                                    {clubs.map((club: Club) => (
                                                        <option value={club.id}>{club.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p style={{color: '#4caf50', textDecoration: 'underline'}}
                                               onClick={() => goToRegisterClub()}>Don't see your club? Click here.</p>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Button type="submit">Go</Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        </>
    );
}
