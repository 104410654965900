import React, {useEffect, useState} from "react";
import {Button, Card, Form, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {MEMBERSHIPS} from "../../../../../services/PageUtils";
import {useApi} from "../../../../../services/useApi";
import {TourCaddieApplicationAnswer} from "../../../../../services/ApiDomain";
import {logErrorFromApi} from "../../../../../services/Utils";

interface Props {

}

const LadiesEuropeanTourApplicationForm = ({}: Props) => {

    const { getTours, tourCaddieApplication } = useApi();
    const navigation = useNavigate();

    useEffect(() => {
        async function findLet() {
            let tours = await getTours();
            let found = false;
            for (const tour of tours) {
                if (tour.name === 'Ladies European Tour') {
                    setTourId(tour.id);
                    found = true;
                }
            }
            if (!found) {
                tourIdError();
            }
        }

        findLet();
    }, []);

    const [tourId, setTourId] = useState<string>("");
    const [currentCaddie, setCurrentCaddie] = useState<boolean>();
    const [currentMember, setCurrentMember] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const defaultLetCaddieTimeAnswer = "Less than 1 Year"
    const [letCaddieTime, setLetCaddieTime] = useState<string>(defaultLetCaddieTimeAnswer);

    const defaultCaddieRoundsAnswer = "I've never caddied at a golf club before"
    const [caddiedRounds, setCaddiedRounds] = useState<string>(defaultCaddieRoundsAnswer);

    const defaultCaddieEventAnswer = "I've never caddied at a golf event before"
    const [caddiedEvents, setCaddiedEvents] = useState<string>(defaultCaddieEventAnswer);

    const [caddiedAt, setCaddiedAt] = useState<string>("");
    const [relevantInformation, setRelevantInformation] = useState<string>("");
    const [contactConsent, setContactConsent] = useState<boolean>(false);

    const questionOne = "Do you currently caddie on the Ladies European Tour?";
    const questionTwoForCaddie = "Are you an existing member of the Ladies European Tour Caddie Association?";
    const questionThreeForCaddie = "How long have you caddied on the Ladies European Tour?";

    const questionTwoForNewCaddie = "How many rounds have you caddied at a golf club?"
    const questionThreeForNewCaddie = "How many rounds have you caddied at a competitive golf event?"
    const questionFourForNewCaddie = "Please list the golf courses where you have been a caddie."
    const questionFiveForNewCaddie = "Please provide any other relevant information to be considered in your application."

    const tourIdError = () => {
        navigation(MEMBERSHIPS);
        toast.error("Something is wrong with the application form right now, please try again later.")
    }

    const submit = () => {
        if (tourId === undefined || tourId === "") {
            tourIdError();
            return;
        }
        setSubmitting(true);
        try {
            let application: TourCaddieApplicationAnswer[] = []
            application.push({
                index: -1,
                question: "contact_consent",
                answer: String(contactConsent)
            });
            application.push({
                index: 1,
                question: questionOne,
                answer: String(currentCaddie)
            });

            if (currentCaddie) {
                application.push({
                    index: 2,
                    question: questionTwoForCaddie,
                    answer: String(currentMember)
                });
                application.push({
                    index: 3,
                    question: questionThreeForCaddie,
                    answer: String(letCaddieTime)
                });
            } else {
                if (relevantInformation.length > 500 || caddiedAt.length > 500) {
                    toast.error("You've entered to much text into one of the text boxes.");
                    return;
                }

                application.push({
                    index: 2,
                    question: questionTwoForNewCaddie,
                    answer: caddiedRounds
                });
                application.push({
                    index: 3,
                    question: questionThreeForNewCaddie,
                    answer: caddiedEvents
                });
                application.push({
                    index: 4,
                    question: questionFourForNewCaddie,
                    answer: caddiedAt
                });
                application.push({
                    index: 5,
                    question: questionFiveForNewCaddie,
                    answer: relevantInformation
                });
            }

            tourCaddieApplication(tourId, application)
                .then(() => {
                    toast.success('Your application has been submitted successfully.');
                    navigation(MEMBERSHIPS);
                })
                .catch((err) => logErrorFromApi(err));
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h6>{questionOne}</h6>

                    <Button variant={currentCaddie === true ? "info" : "outline-secondary"}
                            onClick={() => setCurrentCaddie(true)}
                            style={{
                        fontSize: '1em',
                        width: '4em',
                        marginRight: '1em'
                    }}>
                        Yes
                    </Button>
                    <Button variant={currentCaddie === false ? "info" : "outline-secondary"}
                            onClick={() => setCurrentCaddie(false)}
                            style={{
                        fontSize: '1em',
                        width: '4em',
                    }}>
                        No
                    </Button>
                </Card.Body>
            </Card>

            {currentCaddie !== undefined &&
            <Card style={{marginTop: '2em', marginBottom: '10em'}}>
                <Card.Body>
                    <Form onSubmit={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        submit();
                    }}>
                        {currentCaddie &&
                        <>
                            <Form.Group className="mb-3" controlId="formCurrentMember">
                                <Form.Label>{questionTwoForCaddie}</Form.Label>
                                <Form.Check
                                    type="radio"
                                    name="currentMember"
                                    id="currentMemberYes"
                                    label="Yes"
                                    disabled={!currentCaddie}
                                    onChange={(event) => setCurrentMember(event.target.checked)}
                                />
                                <Form.Check
                                    type="radio"
                                    name="currentMember"
                                    id="currentMemberNo"
                                    label="No"
                                    defaultChecked={true}
                                    disabled={!currentCaddie}
                                    onChange={(event) => setCurrentMember(!event.target.checked)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formTimeOnLET">
                                <Form.Label>{questionThreeForCaddie}</Form.Label>
                                <Form.Control
                                    as="select"
                                    disabled={!currentCaddie}
                                    defaultValue={defaultLetCaddieTimeAnswer}
                                    onChange={(event) => setLetCaddieTime(event.target.value)}
                                >
                                    <option>Less than 1 Year</option>
                                    <option>1-5 Years</option>
                                    <option>5-10 Years</option>
                                    <option>10 Years+</option>
                                </Form.Control>
                            </Form.Group>
                        </>}


                        {!currentCaddie &&
                            <>
                                <Form.Group controlId="formLocalGolfClubExperience">
                                    <Form.Label>{questionTwoForNewCaddie}</Form.Label>
                                    <Form.Control as="select"
                                                  disabled={currentCaddie}
                                                  defaultValue={defaultCaddieRoundsAnswer}
                                                  onChange={(event) => setCaddiedRounds(event.target.value)}
                                    >
                                        <option>{defaultCaddieRoundsAnswer}</option>
                                        <option>Less than 10 Rounds</option>
                                        <option>10-50 Rounds</option>
                                        <option>50+ Rounds</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formCompetitionGolfExperience">
                                    <Form.Label>{questionThreeForNewCaddie}</Form.Label>
                                    <Form.Control as="select"
                                                  disabled={currentCaddie}
                                                  defaultValue={defaultCaddieEventAnswer}
                                                  onChange={(event) => setCaddiedEvents(event.target.value)}
                                    >
                                        <option>{defaultCaddieEventAnswer}</option>
                                        <option>Less than 5 Events</option>
                                        <option>5-10 Events</option>
                                        <option>10-50 Events</option>
                                        <option>50+ Events</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formCaddiedAt">
                                    <Form.Label>{questionFourForNewCaddie}</Form.Label>
                                    <Form.Text id="formCaddiedAt" style={{
                                        color: caddiedAt.length > 500 ? 'red' : '#9e9e9e'
                                    }}>
                                        {caddiedAt.length}/500
                                    </Form.Text>
                                    <Form.Control as="textarea"
                                                  disabled={currentCaddie}
                                                  onChange={(event) => setCaddiedAt(event.target.value)}
                                                  rows={3} />
                                </Form.Group>

                                <Form.Group controlId="formRelevantInformation">
                                    <Form.Label>{questionFiveForNewCaddie}</Form.Label>
                                    <Form.Text id="formRelevantInformationInfo" style={{
                                        color: relevantInformation.length > 500 ? 'red' : '#9e9e9e'
                                    }}>
                                        {relevantInformation.length}/500
                                    </Form.Text>
                                    <Form.Control as="textarea"
                                                  disabled={currentCaddie}
                                                  onChange={(event) => setRelevantInformation(event.target.value)}
                                                  rows={3} />
                                </Form.Group>
                            </>
                        }
                        <Form.Check
                            type={"checkbox"}
                            id={"contactable"}
                            label={"I agree that a Ladies European Tour Caddie Association representative can contact me through the contact methods on my handicaddie profile in order to progress my application."}
                            style={{marginBottom: '1em'}}
                            onChange={(event) => setContactConsent(event.target.checked)}
                        />
                        {/*<Form.Check*/}
                        {/*    type={"checkbox"}*/}
                        {/*    id={"tandcs"}*/}
                        {/*    label={"I agree to the terms and conditions."}*/}
                        {/*    style={{marginBottom: '1em'}}*/}
                        {/*/>*/}

                        <Button variant="primary" type="submit" disabled={submitting}>
                        Submit
                        </Button>
                        {submitting && <Spinner animation={"border"} />}
                    </Form>
                </Card.Body>
            </Card>
            }

        </>
    )

}

export default LadiesEuropeanTourApplicationForm;