import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {ProfileInfo} from "./info/ProfileInfo";
import {ProfileBookings} from "./bookings/ProfileBookings";

import './ProfileContent.scss';
import {ProfileAvailability} from "./availability/ProfileAvailability";
import CaddieComments from "./comments/CaddieComments";

interface Props {
    email?: string
    phoneNumber?: string
    bio?: string
    isCaddieMasterForUser: boolean
    caddieId?: string
    clubId?: string
    clubName?: string
}

export const ProfileContent = ({email, phoneNumber, bio, isCaddieMasterForUser, caddieId, clubId, clubName}: Props) => {

    return <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Info" className="profileContent_tab">
            <ProfileInfo email={email} phoneNumber={phoneNumber} bio={bio} />
        </Tab>
        {isCaddieMasterForUser && caddieId && clubId && clubName &&
            <Tab eventKey="bookings" title="Bookings" className="profileContent_tab">
                <ProfileBookings caddieId={caddieId} clubId={clubId} clubName={clubName} />
            </Tab>
        }
        {isCaddieMasterForUser && caddieId && clubId && clubName &&
            <Tab eventKey="availability" title="Availability" className="profileContent_tab">
                <ProfileAvailability caddieId={caddieId} clubId={clubId} />
            </Tab>
        }
        {isCaddieMasterForUser && caddieId && clubId && clubName &&
            <Tab eventKey="comments" title="Comments" className="profileContent_tab">
                <CaddieComments caddieId={caddieId} />
            </Tab>
        }
    </Tabs>

}