import React from 'react';
import moment from "moment";

interface Props {
    date: Date
    highlighted: boolean
    onClick: (d: Date) => void
}

const SchedulerHeaderDate = ({date, highlighted, onClick}: Props) => {

    const m = moment(date);

    return (
        <div style={{
            backgroundColor: highlighted ? '#ccf6cc' : 'white',
            width: '20%'
        }} onClick={() => onClick(date)}>
            <h5 className={highlighted ? "datesContainer_highlighted" : ""}>{m.format('ddd')}</h5>
            <p className={highlighted ? "datesContainer_highlighted" : ""}>{m.format('Do')}</p>
        </div>
    )
}

export default SchedulerHeaderDate;