import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import Section from "../../../../components/section/Section";
import DragAndDropArea from "../../../../components/draganddroparea/DragAndDropArea";
import PendingDocument from "./PendingDocument";
import {DocumentService} from "../../../../services/documents/DocumentService";
import axios from "axios";
import {toast} from "react-toastify";
import {delay} from "../../../../services/Utils";
import {Event, EventService} from "../../../../services/events/EventService";

interface Props {
    tourId: string
    refreshDocumentsTable: () => void
}

export interface PendingUploadDocument {
    file: File
    name: string
    scope: string
    scopeId: string
    progress?: number
}

const UploadDocuments = ({tourId, refreshDocumentsTable}: Props) => {

    const [pendingDocuments, setPendingDocuments] = useState<PendingUploadDocument[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const [tourEvents, setTourEvents] = useState<Event[]>([]);

    const { createDocument, updateDocumentAsAvailable } = DocumentService();
    const { getTourEventsForCurrentSeason } = EventService();

    useEffect(() => {
        loadEvents();
    }, [tourId]);

    const loadEvents = async () => {
        let events = await getTourEventsForCurrentSeason(tourId);
        if (events) {
            setTourEvents(events.resource);
        }
    }

    const removeFile = (file: File) => {
        setPendingDocuments(
            pendingDocuments.filter((existingDoc) => existingDoc.file.name !== file.name)
        )
    }

    const updateFileName = (file: File, newName: string) => {
        setPendingDocuments(
            pendingDocuments.map(doc => {
                if (doc.file.name === file.name) {
                    doc.name = newName;
                }
                return doc;
            })
        )
    }

    const updateFileScope = (file: File, newScope: string) => {
        setPendingDocuments(
            pendingDocuments.map(doc => {
                if (doc.file.name === file.name) {
                    doc.scope = newScope === tourId ? 'TOUR' : 'EVENT';
                    doc.scopeId = newScope;
                }
                return doc;
            })
        )
    }

    const addFiles = (files: File[]) => {
        const newPendingDocuments: PendingUploadDocument[] = files.map((file) => {
            return {
                file: file,
                name: file.name,
                scope: 'TOUR',
                scopeId: tourId
            }
        });

        setPendingDocuments([
            ...pendingDocuments,
            ...newPendingDocuments
        ]);
    }

    const uploadFiles = async () => {
        setUploading(true);
        try {
            for (let doc of pendingDocuments) {
                try {
                    const uploadDoc = await createDocument(
                        doc.name,
                        doc.file.type,
                        doc.scope,
                        doc.scopeId);
                    if (uploadDoc) {
                        await axios.put(uploadDoc.uploadUrl, doc.file, {
                            headers: {'Content-Type': 'multipart/form-data'},
                            onUploadProgress: progressEvent => {
                                if (progressEvent.total) {
                                    updateUploadProgress(
                                        doc,
                                        Math.round((progressEvent.loaded * 100) / progressEvent.total))
                                }
                            }
                        });
                        await updateDocumentAsAvailable(uploadDoc.id);
                        toast.success(`File ${doc.name} uploaded.`)
                    }
                } catch (e) {
                    toast.error('Error uploading document.');
                }
            }
        } finally {
            delay(1500).then(() => setUploading(false));
            refreshDocumentsTable();
        }
    }

    const updateUploadProgress = (doc: PendingUploadDocument, progress: number) => {
        doc.progress = progress;
        setPendingDocuments([
            ...pendingDocuments.filter((existing) => existing.file.name !== doc.file.name),
            doc
        ]);

        if (progress === 100) {
            delay(1000).then(() => setPendingDocuments([
                ...pendingDocuments.filter((existing) => existing.progress !== 100)
            ]));
        }
    }

    return (
        <Section title="Upload new Documents">
            <Form.Text id="UploadDocumentsHelp" style={{ marginBottom: '2em' }}>
                Drag and drop or click the button below to select new files to upload.
            </Form.Text>
            <DragAndDropArea files={pendingDocuments.map((doc) => doc.file)} addFiles={addFiles} />
            {pendingDocuments.map((doc) =>
                <PendingDocument
                    key={doc.file.name}
                    file={doc.file}
                    name={doc.name}
                    tourId={tourId}
                    uploading={uploading}
                    updateFileName={updateFileName}
                    updateFileScope={updateFileScope}
                    removeFile={removeFile}
                    progress={doc.progress}
                    events={tourEvents}
                />)}
            {
                pendingDocuments && pendingDocuments.length > 0 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2em' }}>
                    <Button
                        onClick={uploadFiles}
                        disabled={!pendingDocuments || pendingDocuments.length === 0}>
                        Upload documents
                    </Button>
                </div>
            }

        </Section>
    )
}

export default UploadDocuments;