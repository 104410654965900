import {NavLink} from "react-bootstrap";
import {NavLink as Link} from "react-router-dom";
import {
    GroupOutlined,
    HomeOutlined,
    InsertInvitationOutlined,
    LocalActivity,
    LocalActivityOutlined
} from "@material-ui/icons";
import {NavigationBar} from "./NavigationBar";
import {buildUrl, CADDIES, MEMBERS, PLATFORM_HOME, SCHEDULER} from "../../services/PageUtils";

import './NavBar.scss';
import {handicaddieSelector} from "../../services/store/asyncThunk";

interface Props {
    logOut: () => void
    isLocked: boolean
}

const PlatformNavBar = ({logOut, isLocked}: Props) => {

    const { isMembersEnabled } = handicaddieSelector(state => state.clubs.selectedClub)

    return (
        <NavigationBar logOut={logOut}>
            <NavLink as={Link} to={buildUrl(PLATFORM_HOME)} disabled={isLocked} end>
                <HomeOutlined className="navBar_icon" />
                <div>Dashboard</div>
            </NavLink>
            <NavLink as={Link} to={buildUrl(SCHEDULER)} disabled={isLocked}>
                <InsertInvitationOutlined className="navBar_icon" />
                <div>Scheduler</div>
            </NavLink>
            <NavLink as={Link} to={buildUrl(CADDIES)} disabled={isLocked}>
                <GroupOutlined className="navBar_icon" />
                <div>Caddies</div>
            </NavLink>
            {isMembersEnabled && <NavLink as={Link} to={buildUrl(MEMBERS)} disabled={isLocked}>
                <LocalActivityOutlined className="navBar_icon" />
                <div>Members</div>
            </NavLink>}
        </NavigationBar>
    )
}

export default PlatformNavBar;