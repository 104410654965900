import {CaddieMaster, CaddieResource, TourCaddieResource, TourMaster, User} from "./ApiDomain";

export const LOADING = '/loading';
export const CREATE_PROFILE = '/create';
export const CADDIE_CHOICE = '/choice';
export const CADDIE_WEB_NOTICE = '/notice';
export const PLATFORM_HOME = '/';
export const MEMBERSHIPS = '/platform/memberships';
export const CADDIE_ON_TOUR = '/platform/tours';
export const LET_CADDIE_APPLICATION = '/tours/let/application';
export const TOUR_PASSES = '/tours/:tourId/passes';
export const TOUR_MASTER_APP = '/tours/:id/tourmaster';
export const TOUR_MASTER_DASHBOARD = '/tours/:id/tourmaster/dashboard';
export const TOUR_MASTER_CADDIES = '/tourmaster/caddies';
export const TOUR_MASTER_COMMUNICATION = '/tourmaster/communication';
export const TOUR_MASTER_DOCUMENTS = '/tourmaster/documents';
export const TOUR_MASTER_BILLING = '/tours/billing';
export const BOOKINGS = '/bookings';
export const AVAILABILITY = '/availability';
export const CADDIES = '/caddies';
export const MEMBERS = '/members';
export const SCHEDULER = '/scheduler';
export const MY_PROFILE = '/myprofile';
export const EDIT_MY_PROFILE = '/myprofile/edit';
export const CADDIE_PROFILE = '/club/:clubId/caddie/:caddieId';
export const MEMBER_PROFILE = '/club/:clubId/member/:memberId';
export const USER_PROFILE = '/user/:userId';
export const PAYMENT_SUCCESSFUL = '/payment_successful';
export const PAYMENT_UNSUCCESSFUL = '/payment_unsuccessful';
export const DASHBOARD = '/dashboard';
export const CADDIE_PROGRAM_REPORT = '/caddieprogram/report';
export const SETTINGS = '/settings';

export const redirectPage = (token?: string,
                             userLoaded?: boolean,
                             caddiesLoaded?: boolean,
                             tourCaddiesLoaded?: boolean,
                             caddieMastersLoaded?: boolean,
                             tourMastersLoaded?: boolean,
                             currentUser?: User,
                             caddies?: CaddieResource[],
                             tourCaddies?: TourCaddieResource[],
                             caddieMasters?: CaddieMaster[],
                             tourMasters?: TourMaster[]) => {
    if (notLoaded(userLoaded, caddiesLoaded, caddieMastersLoaded)) {
        return;
    }

    if (hasNotCreatedUserProfile(token, userLoaded, currentUser)) {
        return CREATE_PROFILE;
    } else if (hasNotMadeCaddieChoice(token,
        tourCaddiesLoaded,
        caddiesLoaded,
        tourMastersLoaded,
        caddieMastersLoaded,
        tourCaddies,
        caddies,
        tourMasters,
        caddieMasters)) {
        return CADDIE_CHOICE;
    } else if (isNotCaddieMasterOrTourMaster(token,
        tourMastersLoaded,
        caddieMastersLoaded,
        tourMasters,
        caddieMasters)) {
        return CADDIE_WEB_NOTICE;
    }

    return undefined;
}

const hasNotCreatedUserProfile = (token?: string, userLoaded?: boolean, currentUser?: User) => {
    return token && userLoaded && !currentUser;
}

const hasNotMadeCaddieChoice = (token?: string,
                                tourCaddiesLoaded?: boolean,
                                caddiesLoaded?: boolean,
                                tourMastersLoaded?: boolean,
                                caddieMastersLoaded?: boolean,
                                tourCaddies?: TourCaddieResource[],
                                caddies?: CaddieResource[],
                                tourMasters?: TourMaster[],
                                caddieMasters?: CaddieMaster[]) => {
    return token &&
        tourCaddiesLoaded &&
        caddiesLoaded &&
        tourMastersLoaded &&
        caddieMastersLoaded &&
        tourCaddies &&
        caddies &&
        tourMasters &&
        caddieMasters &&
        tourCaddies.length <= 0 &&
        caddies.length <= 0 &&
        tourMasters.length <= 0 &&
        caddieMasters.length <= 0;
}

const isNotCaddieMasterOrTourMaster = (token?: string,
                                       tourMastersLoaded?: boolean,
                                       caddieMastersLoaded?: boolean,
                                       tourMasters?: TourMaster[],
                                       caddieMasters?: CaddieMaster[]) => {
    return token &&
        tourMastersLoaded &&
        caddieMastersLoaded &&
        tourMasters &&
        caddieMasters &&
        tourMasters.length <= 0 &&
        caddieMasters.length <= 0;
}

export const notLoaded = (userLoaded?: boolean,
                          caddiesLoaded?: boolean,
                          caddieMastersLoaded?: boolean) => {
    return userLoaded === false ||
        caddiesLoaded === false ||
        caddieMastersLoaded === false;
}

export const buildUrl = (url: string): string => {
    return "/platform" + url
}