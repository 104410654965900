import React from 'react';
import {Card, CardDeck} from "react-bootstrap";
import {PendingCaddieCard} from "./cards/PendingCaddieCard";
import {UserCaddie} from "../../../services/ApiDomain";

type Props = {
    caddies: UserCaddie[]
    refreshCaddies: () => void
}

export const PendingCaddieFeed = ({caddies, refreshCaddies}: Props) => {

    return (
        <CardDeck>
            {caddies && caddies.map((caddie) => {
                return <PendingCaddieCard
                    clubId={caddie.clubId}
                    clubName={caddie.clubName}
                    id={caddie.caddieId}
                    userId={caddie.userId}
                    name={caddie.displayName}
                    refreshCaddies={refreshCaddies} />
            })}
            {(!caddies || caddies.length === 0) && <Card><Card.Body>No pending caddie requests.</Card.Body></Card>}
        </CardDeck>
    );
}
