import React from 'react';
import {CheckCircleRounded, RemoveCircleRounded} from "@material-ui/icons";
import Avatar from "../../../../components/Avatar";

interface Props {
    name: string,
    userId: string
    currentMember: boolean
    onClick: () => void
}

const CaddieTableRow = ({name, userId, currentMember, onClick}: Props) => {

    return (
        <tr onClick={onClick} style={{ cursor: 'pointer' }}>
            <td className="caddieTable_colOne">
                <Avatar size="s" user={userId} style={{ marginRight: '1em' }} />
                {name}
            </td>
            {currentMember && <td className="caddieTable_season"><CheckCircleRounded style={{color: '#1db954'}}/> Paid</td>}
            {!currentMember && <td className="caddieTable_season"><RemoveCircleRounded style={{color: '#b91d1d'}}/> Not paid.</td>}
        </tr>
    )
}

export default CaddieTableRow;