import React from "react";
import {NavigationBar} from "../components/navbar/NavigationBar";
import {Col, Container, Image, Row} from "react-bootstrap";
import background from "../components/login/background-1.jpg";
import appStore from "../images/DownloadOnAppStore.svg";
import playStore from "../images/DownloadOnGooglePlay.png";
import splashImage from "../images/golfClubCaddie.png";
import {handicaddieGreen} from "../services/Utils";

interface Props {
}

export const CaddieAppNotice = ({}: Props) => {

    return <>
        <NavigationBar />
        <Container
            className={"contentContainer"}
            style={{
                backgroundImage: `url(${background})`,
                height: "calc(100vh - 76px)",
                marginTop: "0px",
            }}
            fluid={true}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        minWidth: "50%",
                        width: "auto",
                        height: "auto",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                >
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
                        <Image src={splashImage} style={{height: 250}}/>
                    </div>

                    <div style={{textAlign: "center"}}>
                        <h1 style={{color: handicaddieGreen}}>
                            Hi there!
                        </h1>
                        <p>We're bringing you new and great features on our Caddie Companion app.</p>
                    </div>

                    <Row>
                        <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <a href="https://apps.apple.com/us/app/id1621131417">
                                <Image src={appStore} style={{ height: 60 }}/>
                            </a>
                        </Col>
                        <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.handicaddie.caddie">
                                <Image src={playStore} style={{ height: 60, cursor: 'pointer' }} />
                            </a>
                        </Col>
                    </Row>


                    <div style={{textAlign: "center", marginTop: '1em'}}>
                        <i>
                            <p>If you're a caddie master,</p>
                            <p>We're still approving your account. Get in touch if you think this should already of happened.</p>
                        </i>
                    </div>
                </div>
            </div>
        </Container>
    </>
}