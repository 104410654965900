import React from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";

type ClubBookingFormProps = {
    title?: string
    updateTitle: (x: string) => void
    numberOfCaddies?: number
    updateNumberOfCaddies: (x: number) => void
    minCaddies: number
    notes?: string
    updateNotes: (x: string) => void
    submit: () => void
    actionsEnabled: boolean
    closeBookingModal: () => void
}

/** ONLY USED TO EDIT BOOKINGS ON MOBILE **/
const ClubBookingForm = ({
                             title, updateTitle,
                             numberOfCaddies, updateNumberOfCaddies,
                             notes, updateNotes,
                             minCaddies,
                             submit,
                             actionsEnabled,
                             closeBookingModal}: ClubBookingFormProps) => {
    return (
        <Form>
            <Form.Group as={Row} controlId="formHorizontalTitle">
                <Form.Label column sm={2}>
                    Title
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        type="text"
                        placeholder="Booking Title"
                        value={title}
                        onChange={(e) => updateTitle(e.target.value)}
                    />
                </Col>
            </Form.Group>

            <fieldset>
                <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={2}>
                        Caddies
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Check
                            type="radio"
                            label="1"
                            disabled={minCaddies > 1}
                            name="formHorizontalRadios"
                            id="formHorizontalRadios1"
                            checked={numberOfCaddies === 1}
                            onChange={(e) =>
                                updateNumberOfCaddies(1)
                            }
                        />
                        <Form.Check
                            type="radio"
                            label="2"
                            disabled={minCaddies > 2}
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                            checked={numberOfCaddies === 2}
                            onChange={() => updateNumberOfCaddies(2)}
                        />
                        <Form.Check
                            type="radio"
                            label="3"
                            disabled={minCaddies > 3}
                            name="formHorizontalRadios"
                            id="formHorizontalRadios3"
                            checked={numberOfCaddies === 3}
                            onChange={() => updateNumberOfCaddies(3)}
                        />
                        <Form.Check
                            type="radio"
                            label="4"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios4"
                            disabled={minCaddies > 4}
                            checked={numberOfCaddies === 4}
                            onChange={() => updateNumberOfCaddies(4)}
                        />
                    </Col>
                </Form.Group>
            </fieldset>

            <Form.Group as={Row} controlId="formHorizontalNotes">
                <Form.Label column sm={2}>
                    Notes
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Booking Notes"
                        value={notes}
                        onChange={(e) => updateNotes(e.target.value)}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                    <Button onClick={submit} disabled={!actionsEnabled}>Save</Button> {' '}
                    <Button type="button" variant="outline-danger" onClick={closeBookingModal} disabled={!actionsEnabled}>Cancel</Button>
                </Col>
            </Form.Group>
        </Form>
    )
}

export default ClubBookingForm;