import React, {useEffect, useState} from "react";
import {Col, Row} from 'react-bootstrap';
import moment from "moment";
import {useApi} from "../../../services/useApi";
import {AnalyticsResource, StatisticsAnalyticsResource} from "../../../services/ApiDomain";
import './Analytics.scss';
import Statistic from "../components/Statistic";
import MostCaddieRoundsTable from "./MostCaddieRoundsTable";
import {UpcomingBookings} from "./UpcomingBookings";
import {useSelector} from "react-redux";
import {RootState} from "../../../services/store/store";

interface Props { }

export const Analytics = ({ }: Props) => {

  const [numberOfSlots, setNumberOfSlots] = useState<AnalyticsResource>();
  const [numberOfMissedSlots, setNumberOfMissedSlots] = useState<AnalyticsResource>();
  const [slotToCaddieRatio, setSlotToCaddieRatio] = useState<StatisticsAnalyticsResource>();
  const [caddieRounds, setCaddieRounds] = useState<AnalyticsResource>();

  const { getAnalytics } = useApi();
  const { club } = useSelector((state: RootState) => state.clubs.selectedClub);

  useEffect(() => {
    if (!club) {
      return;
    }

    loadAnalytics(club.id);
  }, [club])

  const loadAnalytics = async (clubId: string | undefined) => {
    if (!clubId) {
      return;
    }
    const now = moment();
    const nowStr = now.format("YYYY-MM-DD");
    const calendarYearToDate = now.year() + "-12-31";
    const calendarYearFromDate = (now.year() - 1) + "-12-31";

    const numberOfSlots = await getAnalytics(clubId,
        "SINGLE_STATISTICS",
        "NUMBER_OF_SLOTS",
        calendarYearFromDate,
        calendarYearToDate);
    setNumberOfSlots(numberOfSlots);
    const numberOfMissedSlots = await getAnalytics(clubId,
        "SINGLE_STATISTICS",
        "NUMBER_OF_MISSED_SLOTS",
        calendarYearFromDate,
        calendarYearToDate);
    setNumberOfMissedSlots(numberOfMissedSlots);

    const slotToCaddieRatio = await getAnalytics(clubId,
        "SINGLE_STATISTICS",
        "SLOT_TO_CADDIE_RATIO",
        nowStr,
        moment().add(7, 'days').format('YYYY-MM-DD'));
    if (slotToCaddieRatio.payload) {
      let statResource = slotToCaddieRatio as StatisticsAnalyticsResource;
      setSlotToCaddieRatio(statResource);
    }

    const caddieRounds = await getAnalytics(clubId,
        "TABLE",
        "MOST_CADDIE_ROUNDS",
        moment().subtract(30, 'days').format('YYYY-MM-DD'),
        nowStr);
    setCaddieRounds(caddieRounds);
  }

  return (
    <div>
      <div className="caddie-programme-title">
        <h3 className="yourCaddieProgramHeading">
          Your Caddie Programme
        </h3>
      </div>

      <Row className="statistics-row-margin">
        <Statistic
            infoText={"The number of slots you have created on the scheduler."}
            payload={numberOfSlots?.payload}
            className="statistics-column-left-padding" />
        <Statistic
            infoText={"The number of open slots you have on the scheduler. Each missed slot is player who didn't get a caddie. Lower number is better!"}
            payload={numberOfMissedSlots?.payload} />
        <Statistic
            infoText={"Gives an estimate on the number of caddies available per slot. If below 1 you have more slots than caddie availability."}
            payload={slotToCaddieRatio?.payload}
            className="statistics-column-right-padding" />
      </Row>

      <Row className="statistics-row-margin">
        <Col md={4} className="statistics-column-left-padding">
          <MostCaddieRoundsTable caddieRounds={caddieRounds} />
        </Col>
        <Col md={8} className="statistics-column-right-padding">
          <UpcomingBookings />
        </Col>
      </Row>
    </div>
  )
}