import axios, {AxiosResponse} from 'axios';

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

let handleError = (error: any) => {};

export class ApiClient {

    constructor(errorCallback: (error: any) => void, baseURL?: string) {
        axios.defaults.baseURL = baseURL ? baseURL : 'http://localhost:8080/api/1';
        handleError = errorCallback;
    }

    public static getInstance() {
        const api = axios.create({
            withCredentials: true,
            headers: {
                'Authorization': localStorage.getItem('token'),
                'X-DEVICE': 'web',
                'X-VERSION': process.env.REACT_APP_VERSION
            }
        });
        api.interceptors.response.use(
            this._handleResponse,
            handleError
        )
        return api;
    }

    private static _handleResponse = ({ data }: AxiosResponse) => data;

}

export default ApiClient;