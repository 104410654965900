import React from 'react';
import {Col, Row} from "react-bootstrap";

interface Props {

}

const TourPassesHeader = ({}: Props) => {
    return (
        <Row>
            <Col xs={12} lg={12}>
                <p>You need to purchase a pass in order to complete your membership.</p>
            </Col>
        </Row>
    )
}

export default TourPassesHeader;