import React, {useEffect, useState} from 'react';
import {TourPassesTable} from "./table/TourPassesTable";
import {Button} from "react-bootstrap";
import {AddNewTourPassModal} from "./modal/AddNewTourPassModal";
import {TourPass, TourPassService} from "../../../../services/tourpass/TourPassService";
import {logErrorFromApi} from "../../../../services/Utils";

type Props = {
    tourId: string
};

export const TourPasses = ({tourId}: Props) => {

    const { getTourPasses } = TourPassService();

    const [tourPasses, setTourPasses] = useState<TourPass[]>();
    const [showNewSeasonModal, setShowNewSeasonModal] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const passes = await getTourPasses(tourId);
            if (passes) {
                setTourPasses(passes);
            }
        } catch (e) {
            logErrorFromApi(e)
        }
    }

    const closeModal = () => {
        setTourPasses(undefined);
        setShowNewSeasonModal(false);
        loadData();
    }

    return (
        <>
            <h3 className="pageSubHeading" style={{ minHeight: 0 }}>Tour Passes</h3>
            <p style={{ paddingBottom: '1em' }}>These are the passes your caddies will see as an option to pay.</p>
            <Button onClick={() => setShowNewSeasonModal(true)}>Add new pass</Button>
            <TourPassesTable tourId={tourId} tourPasses={tourPasses} />
            <AddNewTourPassModal tourId={tourId} show={showNewSeasonModal} closeModal={closeModal} />
        </>

    )
}
