import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";
import {PagedResource} from "../ApiDomain";

export interface Document {
    id: string
    name: string
    fileType: string
    scopeType: string
    scopeId: string
    isAvailable: boolean
    uploaded: string
}

export interface PagedDocuments extends PagedResource {
    resource: Document[]
}

export interface UploadDocumentResponse {
    id: string
    uploadUrl: string
}

export const DocumentService = () => {

    const create: (name: string, fileType: string, scopeType: string, scopeId: string) => Promise<UploadDocumentResponse | void> =
        (name: string, fileType: string, scopeType: string, scopeId: string) => {
            return ApiClient.getInstance().post<UploadDocumentResponse>(`/documents`, {
                name,
                fileType,
                scopeType,
                scopeId
            })
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }

    const update: (document: Document) => Promise<Document | void> = (document: Document) => {
            return ApiClient.getInstance().put<Document>(`/documents/${document.id}`, document)
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }

    const deleteDocument = (docId: string) => {
            return ApiClient.getInstance().delete<Document>(`/documents/${docId}`)
                .then((resp) => resp)
                .catch(logErrorFromApi);
        }

    const updateAsAvailable: (id: string) => Promise<Document | void> = (id: string) => {
            return ApiClient.getInstance().put<Document>(`/documents/${id}`, {
                available: true
            })
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }

    const getAll: (tourId: string) => Promise<PagedDocuments | void> = (tourId: string) => {
            return ApiClient.getInstance().get<PagedDocuments>(`/documents?tourId=${tourId}`)
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }

    const getContent = (docId: string): Promise<string | void> => {
            return ApiClient.getInstance().get<string>(`/documents/${docId}/content`)
                .then((resp) => resp)
                .catch((err) => logErrorFromApi(err));
        }

    return {
        createDocument: create,
        updateDocument: update,
        deleteDocument,
        updateDocumentAsAvailable: updateAsAvailable,
        getDocuments: getAll,
        getDocumentContent: getContent
    }
}

