import React, {useState} from 'react';
import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {useApi} from "../../../../services/useApi";
import * as yup from "yup";
import {logErrorFromApi} from "../../../../services/Utils";

interface Props {
    show: boolean
    close: () => void
    onSuccess: () => void
}

const AddNewCaddie = ({show, close, onSuccess}: Props) => {

    const { createClubCaddie } = useApi();

    const [profileCreating, setProfileCreating] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required"),
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
    });

    const submitForm = async (values: any) => {
        if (profileCreating) {
            return;
        }

        const createNewUser = async () => {
            return await createClubCaddie(
                values.email,
                values.firstName,
                values.lastName,
                '',
                false
            );
        }

        await createNewUser()
            .catch(
                (err) => {
                    logErrorFromApi(err);
                    setProfileCreating(false);
                }
            )
        setProfileCreating(false);
        onSuccess();
    };

    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header>Add new Caddie</Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={submitForm}
                        initialValues={{
                            email: "",
                            firstName: "",
                            lastName: ""
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              values,
                              touched,
                              isValid,
                              errors,
                          }) => (
                            <Form
                                style={{
                                    padding: "2rem",
                                    paddingTop: 0
                                }}
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <br />
                                <p style={{ fontSize: '0.85em' }}>We'll send your caddie an invite email so they can create an account and add their availability.</p>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.Email">
                                            <Form.Control
                                                required
                                                className="form-input"
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                onBlur={handleBlur}
                                                value={
                                                    values.email
                                                }
                                                onChange={handleChange}
                                            />
                                            {touched.email &&
                                            errors.email && (
                                                <Alert
                                                    variant={"danger"}
                                                >
                                                    {errors.email}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.FirstName">
                                            <Form.Control
                                                required
                                                className="form-input"
                                                type="text"
                                                name="firstName"
                                                placeholder="First Name"
                                                onBlur={handleBlur}
                                                value={
                                                    values.firstName
                                                }
                                                onChange={handleChange}
                                            />
                                            {touched.firstName &&
                                            errors.firstName && (
                                                <Alert
                                                    variant={"danger"}
                                                >
                                                    {errors.firstName}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="detailsForm.LastName">
                                            <Form.Control
                                                required
                                                className="form-input"
                                                type="text"
                                                name="lastName"
                                                placeholder="Last Name"
                                                onBlur={handleBlur}
                                                value={
                                                    values.lastName
                                                }
                                                onChange={handleChange}
                                            />
                                            {touched.lastName &&
                                            errors.lastName && (
                                                <Alert
                                                    variant={"danger"}
                                                >
                                                    {errors.lastName}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {
                                            profileCreating &&
                                            <Spinner animation={"border"}
                                                     variant="primary"
                                                     style={{marginRight: '1em'}} />
                                        }
                                        <Button type="cancel" variant='outline-danger' disabled={profileCreating} style={{ marginRight: '0.25em' }} onClick={close}>Cancel</Button>
                                        <Button type="submit" disabled={profileCreating}>Add</Button>
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewCaddie;