import React from "react";

import ProfileInfoTable from "../infotable/ProfileInfoTable";

import './UserBioTable.scss'

interface Props {
    bio?: string
}

const UserBioTable = ({bio}: Props) => {

    return (
        <ProfileInfoTable
            header={<td>
                    <h3>BIO</h3>
                </td>}
            body={<>
                {bio && <tr>
                    <td className="userBioTable_Text">{bio}</td>
                </tr>
                }
            </>}
        />
    )

}

export default UserBioTable;