import React, {useState} from 'react';
import {Form, Table} from 'react-bootstrap';
import {TableBody} from "@material-ui/core";

type Props = {
    updateName: (x: string) => void
    updateStart: (x: string) => void
    updateEnd: (x: string) => void
    updatePrice: (x: number) => void
}

export const NewTourPassForm = ({updateName, updateStart, updateEnd, updatePrice}: Props) => {

    const [membershipFee, setMembershipFee] = useState<string>("0");

    const updateMembershipFee = (price: string) => {
        setMembershipFee(price);
        updatePrice(isNumber(price) ? parseFloat(price) : 0);
    }

    const isNumber: (x: string) => boolean = (input: string) => {
        return Number.isFinite(parseFloat(input));
    }

    return (
        <Form>
            <h3 className="pageSubHeading">Details</h3>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    onChange={(e) => updateName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Start</Form.Label>
                <Form.Control
                    type="date"
                    onChange={(e) => updateStart(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>End</Form.Label>
                <Form.Control
                    type="date"
                    onChange={(e) => updateEnd(e.target.value)} />
            </Form.Group>
            <h3 className="pageSubHeading" style={{paddingTop: '1em'}}>Price</h3>
            <Form.Group>
                <Form.Label>Fee</Form.Label>
                <Form.Control
                    type="text"
                    onChange={(e) => updateMembershipFee(e.target.value)}/>
            </Form.Group>
            <br />
            {(membershipFee !== '0' && isNumber(membershipFee)) &&
                <Table>
                    <TableBody>
                        <tr>
                            <td>
                                You Receive
                            </td>
                            <td>£{membershipFee}</td>
                        </tr>
                        <tr>
                            <td>Handicaddie Admin Fee</td>
                            <td>+ £10</td>
                        </tr>
                        <tr>
                            <td>Caddie will pay</td>
                            <td>£{parseFloat(membershipFee) + 10}</td>
                        </tr>
                    </TableBody>
                </Table>
            }
        </Form>
    );
}
