import React from 'react';
import {Table} from "react-bootstrap";

import './TourPassesTable.scss';
import {TourPass} from "../../../../../services/tourpass/TourPassService";

type Props = {
    tourId: string
    tourPasses: TourPass[] | undefined
};

export const TourPassesTable = ({tourId, tourPasses}: Props) => {

    return (
            <Table className="tourMembershipFeesTable_table">
                <thead className="tourMembershipFeesTable_headingContainer">
                <td className="tourMembershipFeesTable_colOne">
                    <h3 className="tourMembershipFeesTable_heading">Name</h3>
                </td>
                <td>
                    <h3 className="tourMembershipFeesTable_heading">From</h3>
                </td>
                <td>
                    <h3 className="tourMembershipFeesTable_heading">To</h3>
                </td>
                <td>
                    <h3 className="tourMembershipFeesTable_heading">Price</h3>
                </td>
                </thead>
                <tbody className="tourMembershipFeesTable_bodyContainer">
                {!tourPasses &&
                <tr>
                    <td className="tourMembershipFeesTable_colOne">Loading...</td>
                    <td/>
                    <td/>
                    <td/>
                </tr>
                }
                {tourPasses && tourPasses.length === 0 && <tr>
                    <td className="tourMembershipFeesTable_colOne">No passes added</td>
                    <td/>
                    <td/>
                    <td/>
                </tr>}
                {tourPasses && tourPasses.length > 0 &&
                    <>
                        {tourPasses.map((tourPass) => {
                            return <tr>
                                <td className="tourMembershipFeesTable_colOne">{tourPass.name}</td>
                                <td>{tourPass.validFrom}</td>
                                <td>{tourPass.validTo}</td>
                                <td>£{tourPass.fee / 100}</td>
                            </tr>
                        })}
                    </>
                }
                </tbody>
            </Table>
    )
}
