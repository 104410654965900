import {useCallback, useState} from "react";

interface DialogState {
    open: boolean;
    data?: any;
}

interface DialogActions {
    handleOpen: (data: any) => void;
    handleClose: () => void;
}

interface DialogHook extends DialogState, DialogActions {
    data?: any;
}

export function useDialog(): DialogHook {
    const [state, setState] = useState<DialogState>({
        open: false,
        data: undefined,
    });

    const handleOpen = useCallback((data: any) => {
        setState({
            open: true,
            data,
        });
    }, []);

    const handleClose = useCallback(() => {
        setState({
            open: false,
        });
    }, []);

    return {
        ...state,
        handleOpen,
        handleClose,
    };
}
