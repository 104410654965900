import ApiClient from "../../ApiClient";
import moment from "moment/moment";

export interface AutoPublishStatus {
    bookingDate: string
    autoPublishDate: string
    numberOfBookings: number
}

export const TimesheetService = () => {

    const publishByDate = (clubId: string, date: Date) => {
        let apiUrl = `clubs/${clubId}/timesheet`;
        let m = moment(date);
        let dateStr = m.format('YYYY-MM-DD');
        return ApiClient.getInstance().post<void>(apiUrl, {
            date: dateStr
        });
    }

    const publishByBooking = (clubId: string, bookingId: string) => {
        let apiUrl = `clubs/${clubId}/timesheet`;
        return ApiClient.getInstance().post<void>(apiUrl, {
            bookingId: bookingId
        });
    }


    const publishByBookingAndCaddie = (clubId: string, bookingId: string, caddieId: string) => {
        let apiUrl = `clubs/${clubId}/timesheet`;
        return ApiClient.getInstance().post<void>(apiUrl, {
            bookingId: bookingId,
            caddieId: caddieId
        });
    }

    const nextAutoPublish = (clubId?: string): Promise<AutoPublishStatus | void> | undefined => {
        if (!clubId) {
            return;
        }
        return ApiClient.getInstance().get<AutoPublishStatus>(`clubs/${clubId}/auto-publish-status`)
            .then((resp) => resp)
    }

    return {
        publishTimesheetByDate: publishByDate,
        publishTimesheetByBooking: publishByBooking,
        publishTimesheetByBookingAndCaddie: publishByBookingAndCaddie,
        nextTimesheetAutoPublish:  nextAutoPublish
    }
}

