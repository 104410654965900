import {useEffect, useState} from "react";
import {format} from "date-fns";
import "./CalendarStyles.scss";
import "./AvailabilitySelection.scss";
import {Col, Row} from "react-bootstrap";

interface Props {
    scheduledDates?: Date[];
    removeDate?: (date: Date) => void;
    editable?: boolean;
}

const AvailabilityDates = ({ scheduledDates, removeDate = () => {}, editable = false }: Props) => {

    const [dates, setDates] = useState<Date[]>([]);

    useEffect(() => {
        if (!scheduledDates) {
            return;
        }
        setDates(scheduledDates);
    }, [scheduledDates]);

    return (
        <Row>
            <Col>
                {dates.map((date: Date) => {
                    return (
                        <span className={'available-date'}>
                            <div className="date">
                                {format(date, "LLL do, u")}
                            </div>
                            {editable && (
                                <div
                                    onClick={() => removeDate(date)}
                                    className="clear-button">
                                    <span>X</span>
                                </div>)}
                        </span>
                    );
                })}
                {dates && dates.length === 0 && <p>You haven't set any upcoming availability.</p>}
            </Col>
        </Row>
    );
};

export default AvailabilityDates;
