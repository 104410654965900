import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import EmojiPicker from 'emoji-picker-react';

type EmojiPickerModalProps = {
    showEmojiPickerModal: boolean
    selectedEmojiValue: string | undefined
    handleEmojiPickerModalUpdate: (emoji: string | undefined) => void
    handleEmojiPickerModalClose: () => void
}

const EmojiPickerModal = ({showEmojiPickerModal, selectedEmojiValue, handleEmojiPickerModalUpdate, handleEmojiPickerModalClose}: EmojiPickerModalProps) => {
    
    const [selectedEmoji, setSelectedEmoji] = useState<string>();

    const closeModal = () => {
        setSelectedEmoji(undefined);
        handleEmojiPickerModalClose();
    }
    
    return (
        <Modal show={showEmojiPickerModal} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Select Emoji</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                    <div className="selected-emoji">
                        <span>
                            {selectedEmoji || selectedEmojiValue ? `Selected Emoji: ${selectedEmoji || selectedEmojiValue}` :
                            "Please select an emoji."}
                        </span>
                    </div>
                    <EmojiPicker className="width-100" onEmojiClick={(emoji) => setSelectedEmoji(emoji?.emoji)} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={() => closeModal()}>Close</Button>
                <Button variant="primary" onClick={() => handleEmojiPickerModalUpdate(selectedEmoji)}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmojiPickerModal;